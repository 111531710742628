import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "react-pdf/dist/esm/pdf.worker.entry";

import "./PdfViewer.css";

class PdfViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPdfDocument: props.src,
      currentPdfPage: 1,
      pdfNumberOfPages: 1,
    };

    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; //`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.pdfPageWidth = window.isMobileDevice()
      ? window.innerWidth - 40
      : Math.min(window.innerWidth - 40, 800);
  }

  onPdfDocumentLoadSuccess = (args) => {
    this.setState({
      pdfNumberOfPages: args.numPages,
    });
  };

  onCurrentPdfPageChange = (offset) => {
    const currentPdfPage = this.state.currentPdfPage + offset;
    if (currentPdfPage <= 0 || currentPdfPage > this.state.pdfNumberOfPages)
      return;

    this.setState({
      currentPdfPage: currentPdfPage,
    });
  };

  render() {
    return (
      <div className={"" + this.props.className} style={{ height: "100%" }}>
        <div className="pdfDocument" style={{ width: this.pdfPageWidth }}>
          <Document
            file={this.state.showPdfDocument}
            onLoadSuccess={this.onPdfDocumentLoadSuccess}
          >
            <Page
              pageNumber={this.state.currentPdfPage}
              renderTextLayer={false}
              width={this.pdfPageWidth}
            />
          </Document>
        </div>
        <div className="pdfPages">
          <img
            loading="lazy"
            className="pdfArrowIcon"
            src="img/icon_left.svg"
            onClick={() => this.onCurrentPdfPageChange(-1)}
          />
          <div>
            {this.state.currentPdfPage}/{this.state.pdfNumberOfPages}
          </div>
          <img
            loading="lazy"
            className="pdfArrowIcon"
            src="img/icon_right.svg"
            onClick={() => this.onCurrentPdfPageChange(1)}
          />
        </div>
      </div>
    );
  }
}

export default PdfViewer;
