import React from "react";
import ReactDOM from "react-dom";
import WeekView from "./WeekView/WeekView";
import MainView from "./MainView";

export default class TrainingExtension {
  renderMainView(mode) {
    if (window.myid == null) {
      return;
    }
    if (window.DataStorage.trainTypes == null) {
      return;
    }
    window.$("#trainReactDiv").show();
    window.$("#trainTableDiv, #trainRiderView, #notat-rider-column").html("");

    //console.log("mode: " + mode);

    ReactDOM.render(
      <MainView curdate={new Date()} mode={mode} />,
      document.getElementById("trainReactDiv")
    );
  }
}
