import React, { Component } from "react";
import './HorseCell.css';

class HorseCell extends Component {

    onHorseClick = () => {
        window.Managers.self.isOneGo = false;
        window.Managers.self.editHorseById(this.props.horse.id);
    }

    getBottomText = () => {
        let showUnderCode = parseInt(window.GlobalData.getItem(window.GlobalData.NAME_STABLE));
        switch (showUnderCode) {
            case 1: //box or stable number
                {
                    if (window.inUseStables == 'Y') {
                        if (this.props.horse.BoxName != null && this.props.horse.BoxName != "") {
                            let box = window.DataStorage.boxesAndStables.find(s => s.IDBox == this.props.horse.BoxName);
                            if (box != null)
                                return box.BoxName;
                        }
                    } else {
                        if (this.props.horse.StableId != null) {
                            const stable = window.DataStorage.boxesAndStables.find(s => s.IdStable == this.props.horse.StableId);
                            if (stable != null) {
                                return stable.StableName;
                            }
                        }
                    }
                }
                break;
            case 2: //stable and box
                {
                    if (this.props.horse.BoxName != null && this.props.horse.BoxName != "") {
                        let box = window.DataStorage.boxesAndStables.find(s => s.IDBox == this.props.horse.BoxName);
                        if (box != null) {
                            return box.StableName + " - " + box.BoxName;
                        }
                    }
                }
                break;
            case 3: //owner
                {
                    if (this.props.horse.PaperOwner != null && window.Managers.ownersPaper != null) {
                        let ownerNames = [];
                        for (let i = 0; i < this.props.horse.PaperOwner.length; i++) {
                            let o = this.props.horse.PaperOwner[i];
                            if (window.Managers.ownersPaper[o] != null) {
                                ownerNames.push(window.Managers.ownersPaper[o].Name);
                            }
                        }
                        return ownerNames.join(',');
                    }
                }
                break;
            case 4: //stable and owner
                {
                    let text = '';
                    if (this.props.horse.BoxName != null && this.props.horse.BoxName != "") {
                        let box = window.DataStorage.boxesAndStables.find(s => s.IDBox == this.props.horse.BoxName);
                        if (box != null) {
                            return box.StableName;
                        }
                    }

                    if (this.props.horse.PaperOwner != null && window.Managers.ownersPaper != null) {
                        let ownerNames = [];
                        for (let i = 0; i < this.props.horse.PaperOwner.length; i++) {
                            let o = this.props.horse.PaperOwner[i];
                            if (window.Managers.ownersPaper[o] != null) {
                                ownerNames.push(window.Managers.ownersPaper[o].Name);
                            }
                        }
                        if (ownerNames.length != 0) {
                            if (text != '')
                                text += "-";
                            text += ownerNames.join(',');
                        }
                    }

                    return text;
                }
                break;
            case 5: //horse type
                {
                    if (window.DataStorage.horseTypes != null && this.props.horse.HorseType != null) {
                        let hType = window.DataStorage.horseTypes.find(t => t.ID == this.props.horse.HorseType);
                        if (hType != null) {
                            return hType.Name;
                        }
                    }
                }
                break;
        }
        return " ";
    }

    render() {
        const { horse } = this.props;
        const showImage = window.GlobalData.getItem(window.GlobalData.SHOW_HORSELOGO) == 1;
        const showNotes = window.GlobalData.getItem(window.GlobalData.SHOW_NOTAT_HORSE) == 1;
        const showStar = window.GlobalData.getItem(window.GlobalData.SHOW_NOTAT_HORSE) == '*';

        const horseLogo = horse.LogoFile != null ? horse.LogoFile.split('.jpg')[0] + '-thumb.jpg' : '';
        const logoImage = horse.LogoFile != null ? `url('${window.DataGate.baseurl + horseLogo}')` : `url('img/horselogo.png')`;
        const bottomText = this.getBottomText();

        let horseName = horse.name;
        if (showStar && horse.notes) {
            horseName = '*' + horseName;
        }

        return (
            <div style={{ cursor: "pointer", paddingLeft: "10px" }} onClick={this.onHorseClick} >
                {showImage &&
                    <div className="horseIcon" style={{ backgroundImage: logoImage }}>
                    </div>
                }
                <div className="horseNameContainer">
                    <div className="horseNotes">{showNotes ? horse.notes : " "}</div>
                    <div className="horseName">{horseName}</div>
                    <div className="horseBottomText">{bottomText}</div>
                </div>
            </div>
        );
    }
}

export default HorseCell;