import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./SharePage.css";
import SharePage from "./SharePage";

class CreateRiderView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      phone: "",
      image: null,
    };
  }

  onCancelClick = () => {
    window.showPage("#sharingPage");
    window.managers.extention.showSharingPage();
  };

  onInputChange = (e, propName) => {
    this.setState({
      [propName]: e.target.value,
    });
  };

  onImageClick = () => {
    window.getPhoto((imageData) => {
      this.setState({
        image: imageData,
      });
    });
  };

  onOkClick = () => {
    const { email, password, confirmPassword, name, phone, image } = this.state;
    if (email == null || email == "" || password == null || password == "") {
      window.navigator.notification.alert("Email and password are required");
      return;
    }

    if (password != confirmPassword) {
      window.navigator.notification.alert(
        "Password and confirm password should be the same"
      );
      return;
    }

    window.Spinner.show();
    let pswd = window.hex_md5(password);
    window.DataGate.register(
      email,
      pswd,
      null,
      name,
      image,
      "",
      "",
      "",
      "",
      phone,
      window.lang.getLangName(),
      true,
      (code, data) => {
        console.log("register code: " + code, data);
        if (code == window.DataGate.CODE_OK) {
          const realMyId = window.myid;
          window.myid = data.userId;

          let signUp = new window.SignUp();
          signUp.gtypeVal = 0;
          signUp.createDefaultTypes();
          signUp.createDefaultHorseTypes();
          signUp.fillInitialValues();
          window.Spinner.show();
          window.DefaultValue.CreateDefaultStables(() => {
            window.Spinner.show();
            setTimeout(() => {
              window.myid = realMyId;
              window.Spinner.hide();

              //share with email
              var sharePage = new SharePage();
              window.showPage("#sharePage");
              sharePage.showSharePage(data.userId, email, null);
            }, 2000);
          });
        } else if (code == window.DataGate.CODE_EMAIL_EXISTS) {
          window.navigator.notification.alert(window.lang.alertLoginExistsNew);
          window.Spinner.hide();
        } else {
          window.navigator.notification.alert(window.lang.alertLoginNotValid);
          window.Spinner.hide();
        }
      }
    );
  };

  render() {
    const { email, password, confirmPassword, name, phone, image } = this.state;
    return (
      <div
        className="pageScrollable"
        style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
      >
        <div className="pageHeader ui-header">
          <img
            id="sharingBackButton"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onCancelClick}
          />
          <span className="ppTitle">{window.lang.createNewRider}</span>
          <img
            id="sharingOkButton"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onOkClick}
          />
        </div>
        <div
          className="ppContent"
          style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto" }}
        >
          <div>Email</div>
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              value={email}
              autoComplete="chrome-off"
              onChange={(e) => this.onInputChange(e, "email")}
            />
          </div>
          <div>{window.lang.password}</div>
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              type="password"
              value={password}
              autoComplete="chrome-off"
              onChange={(e) => this.onInputChange(e, "password")}
            />
          </div>
          <div>{window.lang.confirmPassword}</div>
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              type="password"
              value={confirmPassword}
              autoComplete="chrome-off"
              onChange={(e) => this.onInputChange(e, "confirmPassword")}
            />
          </div>
          <div>{window.lang.name}</div>
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              value={name}
              autoComplete="chrome-off"
              onChange={(e) => this.onInputChange(e, "name")}
            />
          </div>
          <div>{window.lang.phone}</div>
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              value={phone}
              onChange={(e) => this.onInputChange(e, "phone")}
            />
          </div>
          {image ? (
            <img
              className="crImage"
              src={"data:image/jpeg;base64," + image}
              onClick={this.onImageClick}
            />
          ) : (
            <button
              className="ui-btn ui-shadow ui-corner-all"
              onClick={this.onImageClick}
            >
              {window.lang.image}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default class CreateRiderPage {
  showPage() {
    window.showPage("#createRiderPage");
    ReactDOM.render(
      <CreateRiderView />,
      document.getElementById("createRiderPageReact")
    );
  }
}
