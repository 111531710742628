import React, { Component } from "react";
import HorseGallery from "../.././Multimedia/HorseGallery";

class EquipmentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      equipment: "",
    };
  }

  componentDidMount() {
    window.$("#bottomMenu").hide();
    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  loadData = () => {
    window.db.getNoteByHorse(this.props.horseId, (data) => {
      this.setState({
        equipment: data[0].Equipment,
      });
    });
  };

  onEquipmentChange = (e) => {
    this.setState({
      equipment: e.target.value,
    });
  };

  saveCurrentText = (callback) => {
    const equipmentStr =
      this.state.equipment != null ? this.state.equipment : "";
    window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    ).Equipment = equipmentStr;
    window.db.updateEquipmentByHorse(this.props.horseId, equipmentStr);
    if (callback) callback();
  };

  onSaveClick = () => {
    this.saveCurrentText(() => {
      window.training.refresh();
      window.Managers.extention.showHorsePage(this.props.horseId);
      window.$("#bottomMenu").show();
    });
  };

  render() {
    const { equipment } = this.state;
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onSaveClick}
          />
          <span className="npTitle">{window.lang.equipment}</span>
          <span
            id="equipmentHorseInfo"
            style={{
              position: "absolute",
              right: "15%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {horse.name}
          </span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <div className="npContent">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <textarea
              id="addHorseEquipmentText"
              className="ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
              style={{ width: "100%" }}
              rows="50"
              value={equipment}
              onChange={this.onEquipmentChange}
            ></textarea>
          </div>
        </div>
        <HorseGallery
          showUploadButton={true}
          type={"Equipment"}
          horse={horse}
          title={"Equipment"}
        />
      </div>
    );
  }
}

export default EquipmentPage;
