import React, { Component } from "react";
import "./PricePage.css";
import LogHorsePrice from "./LogHorsePricePage.js";

class PricePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: "",
      notes: null,
      selectedNoteIndex: 0,
      isAddShow: false,
      addNoteTitle: "",
      inputData: {},
      inputDataChanged: false,
      inputDataReadOnly: false,
      selectedInput: false,
      selectedPrice: false,
      selectedCostPrice: false,
      priceData: "",
      showPriceData: false,
      horseTypePrice: 0,
      vatArr: [],
    };
  }
  componentDidMount() {
    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.time != prevProps.time) {
      this.loadData();
    }
    window.TopMenu.Self.setVisible(false);
  }

  loadData() {
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    if (horse.Price != null) {
      this.setState({
        price: horse.Price,
      });
    }
    window.Spinner.show();
    window.db.getPriceNotesByHorse(this.props.horseId, (data) => {
      window.db.getHorsePricesData(this.props.horseId, (priceData) => {
        window.db.getHorseTypePricesData(horse.userid, (horseTypeData) => {
          console.log(priceData);
          window.Spinner.hide();
          let vatArr = [
            {
              value: "Købt erhverv uden brugtmoms",
              label: "Købt erhverv uden brugtmoms",
            },
            {
              value: "Købt erhverv brugtvognsordning",
              label: "Købt erhverv brugtvognsordning",
            },
            {
              value: "Købt privat(ikke cvr nr)",
              label: "Købt privat(ikke cvr nr)",
            },
          ];
          let index = horseTypeData.findIndex(
            (i) => i.HorseTypeId == horse.HorseType
          );
          if (priceData.length) {
            let priceForPotentialDate = 0;
            let costPriceActual =
              priceData[0].PurchasePrice + priceData[0].CostPriceAccumulated;
            if (index != -1) {
              let parseDay = this.parseDateStartDay(
                priceData[0].PotentialDate
              ).formatDesh();
              let monthDifference =
                new Date(parseDay).getMonth() -
                new Date().getMonth() +
                12 *
                  (new Date(parseDay).getFullYear() - new Date().getFullYear());
              if (monthDifference < 0) monthDifference = 0;
              priceForPotentialDate =
                costPriceActual + monthDifference * horseTypeData[index].Price;
              var showPriceData = true;
              var horseTypePrice = horseTypeData[index].Price;
            } else {
              priceForPotentialDate = costPriceActual;
              var showPriceData = false;
              var horseTypePrice = 0;
            }
            var price =
              "Salgspris: " +
              priceData[0].SellingPrice.toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n" +
              "Moms: " +
              priceData[0].VAT +
              "\n" +
              "Vurdering aktuelt: " +
              priceData[0].CurrentAssessment.toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n" +
              "Kostpris aktuel: " +
              costPriceActual
                .toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n" +
              "Potentiel vurdering: " +
              priceData[0].PotentialAssessment.toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n" +
              "\n" +
              priceData[0].Notes;
            var costPriceData =
              "Indkøbspris: " +
              priceData[0].PurchasePrice.toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n\n" +
              "Kostpris aktuel: " +
              costPriceActual
                .toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n" +
              "Vurdering aktuelt: " +
              priceData[0].CurrentAssessment.toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n" +
              "Vurderingsdato: " +
              priceData[0].ValuationDate +
              "\n" +
              "Kostpris fremskreven: " +
              priceForPotentialDate
                .toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n" +
              "Potentiel vurdering: " +
              priceData[0].PotentialAssessment.toString()
                .replace(".", ",")
                .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".") +
              "\n" +
              "Potentiel dato: " +
              priceData[0].PotentialDate;
            this.setState({
              notes: data,
              inputData: {
                HorseId: this.props.horseId,
                SellingPrice: priceData[0].SellingPrice,
                PurchasePrice: priceData[0].PurchasePrice,
                VAT: priceData[0].VAT,
                CurrentAssessment: priceData[0].CurrentAssessment,
                ValuationDate: this.parseDateStartDay(
                  priceData[0].ValuationDate
                ).formatDesh(),
                Remark: priceData[0].Remark,
                PotentialAssessment: priceData[0].PotentialAssessment,
                PotentialDate: this.parseDateStartDay(
                  priceData[0].PotentialDate
                ).formatDesh(),
                CostPriceActual: priceData[0].CostPriceActual,
                CostPriceAccumulated: priceData[0].CostPriceAccumulated,
              },
              inputDataChanged: false,
              inputDataReadOnly: true,
              priceData: price,
              costPriceData: costPriceData,
              showPriceData: showPriceData,
              horseTypePrice: horseTypePrice,
              vatArr: vatArr,
            });
          } else {
            if (index != -1) var showPriceData = true;
            else var showPriceData = false;

            let inputData = {
              HorseId: this.props.horseId,
              SellingPrice: "",
              PurchasePrice: "",
              VAT: "",
              CurrentAssessment: "",
              ValuationDate: "",
              Remark: "",
              PotentialAssessment: "",
              PotentialDate: "",
              CostPriceActual: "",
              CostPriceAccumulated: "",
            };
            var price =
              "Salgspris: \n Moms: \n" +
              "Vurdering aktuelt: \n Kostpris aktuel: \n" +
              "Potentiel vurdering: \n";
            var costPriceData =
              "Indkøbspris: \n\n Kostpris aktuel: \n" +
              "Vurdering aktuelt: \n Vurderingsdato: \n" +
              "Kostpris fremskreven: \n Potentiel vurdering: \n" +
              "Potentiel dato: \n";
            this.setState({
              notes: data,
              inputData: inputData,
              inputDataChanged: false,
              inputDataReadOnly: false,
              priceData: price,
              costPriceData: costPriceData,
              showPriceData: showPriceData,
              horseTypePrice: index != -1 ? horseTypeData[index].Price : 0,
              vatArr: vatArr,
            });
          }
        });
      });
    });
  }

  parseDateStartDay(dateStr) {
    if (dateStr == null || dateStr == "") return new Date("1900-01-01");

    let arr = dateStr.split("-");
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }

  onPriceChange = (e) => {
    this.setState({
      price: e.target.value,
    });
  };

  onNoteTextChange = (e) => {
    let notes = [...this.state.notes];
    notes[this.state.selectedNoteIndex].TextNote = e.target.value;
    notes[this.state.selectedNoteIndex].isChanged = true;
    this.setState({
      notes: notes,
    });
  };

  selectNote = (noteIndex) => {
    this.setState({
      selectedNoteIndex: noteIndex,
      selectedInput: false,
      selectedPrice: false,
      selectedCostPrice: false,
    });
    if (this.state.showPriceData) this.saveInputData();
  };

  saveCurrentText = (callback) => {
    window.db.updatePriceByHorse(this.props.horseId, this.state.price, () => {
      let horse = window.DataStorage.getAllHorses().find(
        (h) => h.id == this.props.horseId
      );
      horse.Price = this.state.price;
    });
    const note = this.state.notes[this.state.selectedNoteIndex];
    if (note) {
      if (note.isChanged) {
        window.Spinner.show();
        window.db.updateNoteHorse(note.Id, note.TextNote, () => {
          window.Spinner.hide();
          if (callback) callback();
        });
      } else {
        if (callback) callback();
      }
    }
    if (callback) callback();
  };

  deleteNote = (note) => {
    navigator.notification.confirm(
      "Do you want delete note?",
      (buttonIndex) => {
        if (buttonIndex == 1) {
          window.Spinner.show();
          window.db.deleteNote(note.Id, () => {
            window.Spinner.hide();
            this.setState({
              notes: this.state.notes.filter((n) => n.Id != note.Id),
              selectedNoteIndex: 0,
            });
          });
        }
      }
    );
  };

  addNewNotesStart = () => {
    this.setState({
      isAddShow: true,
      addNoteTitle: "",
    });
  };

  onAddNoteTitleChange = (e) => {
    this.setState({
      addNoteTitle: e.target.value,
    });
  };

  addNewNotesFinish = () => {
    const noteNew = {
      Id: Math.uuid(),
      HeaderNote: this.state.addNoteTitle,
      IdHorse: this.props.horseId,
      IsPrice: 1,
      OrderBy: Math.round(new Date().getTime() / 1000),
      TextNote: "",
    };
    window.Spinner.show();
    window.db.insertNote(
      noteNew.Id,
      noteNew.IdHorse,
      noteNew.HeaderNote,
      noteNew.IsPrice,
      noteNew.OrderBy,
      () => {
        window.Spinner.hide();
        this.setState({
          isAddShow: false,
          notes: [...this.state.notes, noteNew],
          selectedNoteIndex: this.state.notes.length,
        });
      }
    );
  };

  onSaveClick = () => {
    if (this.state.showPriceData) this.saveInputData();
    this.saveCurrentText(() => {
      window.Managers.extention.showHorsePage(this.props.horseId);
    });
  };

  createDefaultNotes = () => {
    const firstDefaultNote = {
      Id: Math.uuid(),
      HeaderNote: window.lang.priceFirstDefaultNote,
      IdHorse: this.props.horseId,
      IsPrice: 1,
      OrderBy: Math.round(new Date().getTime() / 1000),
      TextNote: "",
    };
    window.db.insertNote(
      firstDefaultNote.Id,
      firstDefaultNote.IdHorse,
      firstDefaultNote.HeaderNote,
      firstDefaultNote.IsPrice,
      firstDefaultNote.OrderBy,
      () => {
        this.setState({
          notes: [...this.state.notes, firstDefaultNote],
        });
      }
    );
    const secondDefaultNote = {
      Id: Math.uuid(),
      HeaderNote: window.lang.priceSecondDefaultNote,
      IdHorse: this.props.horseId,
      IsPrice: 1,
      OrderBy: Math.round(new Date().getTime() / 1000 + 1),
      TextNote: "",
    };
    window.db.insertNote(
      secondDefaultNote.Id,
      secondDefaultNote.IdHorse,
      secondDefaultNote.HeaderNote,
      secondDefaultNote.IsPrice,
      secondDefaultNote.OrderBy,
      () => {
        this.setState({
          notes: [...this.state.notes, secondDefaultNote],
        });
      }
    );
  };

  onInputButtonClick = () => {
    this.setState({
      selectedInput: true,
      selectedNoteIndex: -1,
      selectedPrice: false,
      selectedCostPrice: false,
    });
  };

  onInputDataChange = (name, value) => {
    let inputData = this.state.inputData;
    inputData[name] = value;
    this.setState({
      inputData: inputData,
      inputDataChanged: true,
    });
  };

  saveInputData = () => {
    if (!this.state.inputDataChanged) return;

    let inputData = JSON.parse(JSON.stringify(this.state.inputData));
    inputData.ValuationDate = !isNaN(inputData.ValuationDate)
      ? new Date().formatDeshStartDay()
      : new Date(inputData.ValuationDate).formatDeshStartDay();
    inputData.PotentialDate = !isNaN(inputData.PotentialDate)
      ? new Date().formatDeshStartDay()
      : new Date(inputData.PotentialDate).formatDeshStartDay();
    inputData.SellingPrice = inputData.SellingPrice
      ? parseFloat(inputData.SellingPrice.toString().replace(",", "."))
      : 0;
    inputData.PurchasePrice = inputData.PurchasePrice
      ? parseFloat(inputData.PurchasePrice.toString().replace(",", "."))
      : 0;
    inputData.CurrentAssessment = inputData.CurrentAssessment
      ? parseFloat(inputData.CurrentAssessment.toString().replace(",", "."))
      : 0;
    inputData.PotentialAssessment = inputData.PotentialAssessment
      ? parseFloat(inputData.PotentialAssessment.toString().replace(",", "."))
      : 0;
    inputData.CostPriceActual = inputData.CostPriceActual
      ? parseFloat(inputData.CostPriceActual.toString().replace(",", "."))
      : parseFloat(inputData.PurchasePrice.toString().replace(",", ".")); // + parseInt(this.state.horseTypePrice);
    inputData.VAT = inputData.VAT ? inputData.VAT : this.state.vatArr[0].label;
    inputData.CostPriceAccumulated = inputData.CostPriceAccumulated
      ? parseFloat(inputData.CostPriceAccumulated.toString().replace(",", "."))
      : 0;
    window.DataGate.insertOrUpdateHorseInputData(inputData, window.myid, () => {
      this.loadData();
    });
  };

  onPriceButtonClick = () => {
    this.setState({
      selectedInput: false,
      selectedNoteIndex: -1,
      selectedPrice: true,
      selectedCostPrice: false,
    });
    if (this.state.showPriceData) this.saveInputData();
  };

  onCostPriceButtonClick = () => {
    this.setState({
      selectedInput: false,
      selectedNoteIndex: -1,
      selectedPrice: false,
      selectedCostPrice: true,
    });
    if (this.state.showPriceData) this.saveInputData();
  };

  onLogButtonClick = () => {
    window.showPage("#logHorsePricePage");
    let logHorsePrice = new LogHorsePrice();
    logHorsePrice.showLogHorsePrice(this.props.horseId);
  };

  onDeleteButtonClick = () => {
    window.DataGate.deleteHorseInputData(this.props.horseId, () => {
      this.loadData();
      window.showPage("#addHorsePage");
      window.Managers.extention.showHorsePrice(this.props.horseId);
    });
  };

  render() {
    const { horseId } = this.props;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );
    const {
      notes,
      selectedNoteIndex,
      isAddShow,
      inputData,
      selectedInput,
      selectedPrice,
      priceData,
      selectedCostPrice,
      costPriceData,
      showPriceData,
      vatArr,
    } = this.state;
    let noteText = "";
    if (notes) {
      if (notes.length) {
        if (notes[0].TextNote) noteText = notes[0].TextNote;
        else noteText = "";
      } else {
        this.createDefaultNotes();
      }
      if (selectedNoteIndex != 0 && selectedNoteIndex != -1) {
        if (notes[selectedNoteIndex].TextNote)
          noteText = notes[selectedNoteIndex].TextNote;
        else noteText = "";
      }
    }

    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onSaveClick}
          />
          <span className="ppTitle">{horse.name}</span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <div className="ppContent">
          <table className="ppTable">
            <tbody>
              <tr>
                <td className="ppLabel">
                  <label>{window.lang.price}:</label>
                </td>
                <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                  <input
                    type="text"
                    value={this.state.price}
                    onChange={this.onPriceChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            {notes ? (
              notes.map((note, noteIndex) => (
                <button
                  key={note.Id}
                  className="ui-btn ui-corner-all ppButton"
                  style={
                    selectedNoteIndex == noteIndex
                      ? { backgroundColor: "lightgrey" }
                      : {}
                  }
                  onClick={() => this.selectNote(noteIndex)}
                >
                  <span>{note.HeaderNote}</span>
                  <img
                    loading="lazy"
                    className="npDeleteIcon"
                    src="img/delete-black.png"
                    onClick={() => this.deleteNote(note)}
                  />
                </button>
              ))
            ) : (
              <></>
            )}
            {!isAddShow ? (
              <img
                loading="lazy"
                src="img/add.png"
                className="ppAddIcon"
                onClick={this.addNewNotesStart}
              />
            ) : (
              <>
                <input
                  className="r-input"
                  style={{ width: "150px" }}
                  value={this.state.addNoteTitle}
                  onChange={this.onAddNoteTitleChange}
                />
                <img
                  loading="lazy"
                  src="img/icon_okn.png"
                  className="npAddIcon"
                  onClick={this.addNewNotesFinish}
                />
              </>
            )}
            {showPriceData ? (
              <button
                key={Math.uuid()}
                className="ui-btn ui-corner-all ppButton"
                style={selectedPrice ? { backgroundColor: "lightgrey" } : {}}
                onClick={this.onPriceButtonClick}
              >
                <span>Price</span>
              </button>
            ) : (
              <></>
            )}
            {showPriceData ? (
              <button
                key={Math.uuid()}
                className="ui-btn ui-corner-all ppButton"
                style={
                  selectedCostPrice ? { backgroundColor: "lightgrey" } : {}
                }
                onClick={this.onCostPriceButtonClick}
              >
                <span>Cost price</span>
              </button>
            ) : (
              <></>
            )}
            {showPriceData ? (
              <button
                key={Math.uuid()}
                className="ui-btn ui-corner-all ppButton"
                style={selectedInput ? { backgroundColor: "lightgrey" } : {}}
                onClick={this.onInputButtonClick}
              >
                <span>Input</span>
              </button>
            ) : (
              <></>
            )}
          </div>
          {selectedInput ? (
            <table id="inputDataTable">
              <tbody>
                <tr>
                  <td>Salgspris</td>
                </tr>
                <tr>
                  <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                    <input
                      type="text"
                      value={inputData.SellingPrice.toString()
                        .replace(".", ",")
                        .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".")}
                      onChange={(e) => {
                        this.onInputDataChange(
                          "SellingPrice",
                          e.target.value.toString().replaceAll(/\./g, "")
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Indkøbspris</td>
                </tr>
                <tr>
                  <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                    {inputData.PurchasePrice && this.state.inputDataReadOnly ? (
                      <input
                        value={inputData.PurchasePrice.toString()
                          .replace(".", ",")
                          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".")}
                        readOnly
                      />
                    ) : (
                      <input
                        type="text"
                        value={inputData.PurchasePrice.toString()
                          .replace(".", ",")
                          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".")}
                        onChange={(e) => {
                          this.onInputDataChange(
                            "PurchasePrice",
                            e.target.value.toString().replaceAll(/\./g, "")
                          );
                        }}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Accumulered kostpris</td>
                </tr>
                <tr>
                  <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                    {inputData.CostPriceAccumulated &&
                    this.state.inputDataReadOnly ? (
                      <input
                        value={inputData.CostPriceAccumulated.toString()
                          .replace(".", ",")
                          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".")}
                        readOnly
                      />
                    ) : (
                      <input
                        type="text"
                        value={inputData.CostPriceAccumulated.toString()
                          .replace(".", ",")
                          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".")}
                        onChange={(e) => {
                          this.onInputDataChange(
                            "CostPriceAccumulated",
                            e.target.value.toString().replaceAll(/\./g, "")
                          );
                        }}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Moms</td>
                </tr>
                <tr>
                  <td>
                    {inputData.VAT ? (
                      vatArr.length ? (
                        <select
                          style={{ width: "-webkit-fill-available" }}
                          value={inputData.VAT}
                          readOnly
                        >
                          {vatArr.map((j) => (
                            <option key={j.value} value={j.value}>
                              {j.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <></>
                      )
                    ) : vatArr.length ? (
                      <select
                        style={{ width: "-webkit-fill-available" }}
                        value={inputData.VAT}
                        onChange={(e) => {
                          this.onInputDataChange("VAT", e.target.value);
                        }}
                      >
                        {vatArr.map((j) => (
                          <option key={j.value} value={j.value}>
                            {j.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Vurdering aktuelt</td>
                </tr>
                <tr>
                  <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                    <input
                      type="text"
                      value={inputData.CurrentAssessment.toString()
                        .replace(".", ",")
                        .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".")}
                      onChange={(e) => {
                        this.onInputDataChange(
                          "CurrentAssessment",
                          e.target.value.toString().replaceAll(/\./g, "")
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Vurderingsdato</td>
                </tr>
                <tr>
                  <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                    <input
                      type="date"
                      value={inputData.ValuationDate}
                      onChange={(e) => {
                        this.onInputDataChange("ValuationDate", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Bemærkning</td>
                </tr>
                <tr>
                  <td>
                    <textarea
                      rows="3"
                      style={{ width: "-webkit-fill-available" }}
                      value={inputData.Remark}
                      onChange={(e) => {
                        this.onInputDataChange("Remark", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Potentiel vurdering</td>
                </tr>
                <tr>
                  <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                    <input
                      type="text"
                      value={inputData.PotentialAssessment.toString()
                        .replace(".", ",")
                        .replace(/\B(?=(?:\d{3})+(?!\d))/g, ".")}
                      onChange={(e) => {
                        this.onInputDataChange(
                          "PotentialAssessment",
                          e.target.value.toString().replaceAll(/\./g, "")
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Potentiel dato</td>
                </tr>
                <tr>
                  <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                    <input
                      type="date"
                      value={inputData.PotentialDate}
                      onChange={(e) => {
                        this.onInputDataChange("PotentialDate", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ float: "right" }}>
                    <button
                      className="ui-btn ui-corner-all ppButton"
                      onClick={this.onDeleteButtonClick}
                    >
                      <span>Delete</span>
                    </button>
                  </td>
                  <td style={{ float: "right" }}>
                    <button
                      className="ui-btn ui-corner-all ppButton"
                      onClick={this.onLogButtonClick}
                    >
                      <span>Log</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : selectedPrice ? (
            <textarea className="ppTextarea" value={priceData} readOnly />
          ) : selectedCostPrice ? (
            <textarea className="ppTextarea" value={costPriceData} readOnly />
          ) : notes ? (
            notes.length ? (
              <textarea
                className="ppTextarea"
                value={noteText}
                onChange={this.onNoteTextChange}
                onBlur={() => this.saveCurrentText()}
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
export default PricePage;
