import { Capacitor } from "@capacitor/core";
import React, { Component } from "react";

export default class SelectTimePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeValue: props.value,
      isCustomTime: false,
    };
  }

  componentDidMount = () => {
    let times = window.DataStorage.allUserTimeOfDay;
    if (times.findIndex((i) => i.ShortName == this.state.timeValue) == -1)
      this.setState({
        isCustomTime: true,
      });
  };

  onTimeSelect = (e) => {
    this.setState({
      timeValue: e.target.value,
    });
  };

  onCustomTimeChanged = (e) => {
    this.setState({
      isCustomTime: e.target.checked,
    });
  };

  onOkClick = () => {
    this.props.onTimeChange(this.state.timeValue);
  };
  getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");

    return hours + ":" + minutes;
  }
  onSetCurrentTimeClick = () => {
    this.props.onTimeChange(this.getCurrentTime());
  };

  render() {
    const times = window.DataStorage.allUserTimeOfDay;
    return (
      <div className="timePopup">
        <div style={{ textAlign: "center" }}>
          {!this.state.isCustomTime ? (
            <select
              data-role="none"
              value={this.state.timeValue}
              onChange={this.onTimeSelect}
            >
              {times.map((t) => (
                <option key={Math.uuid()} value={t.ShortName}>
                  {t.Name}
                </option>
              ))}
            </select>
          ) : (
            <div className="timePopup--input-holder">
              <input
                type="time"
                value={this.state.timeValue}
                onChange={this.onTimeSelect}
              />
              {Capacitor.getPlatform() == "ios" &&
                this.state.timeValue != "" && (
                  <div
                    className="timePopup--ios-reset"
                    onClick={() => {
                      this.setState({
                        timeValue: "",
                      });
                    }}
                  >
                    <span className="timePopup--ios-reset--span">x</span>
                  </div>
                )}
            </div>
          )}
        </div>
        <div style={{ display: "flex", marginTop: "5px" }}>
          <input
            type="checkbox"
            checked={this.state.isCustomTime}
            onChange={this.onCustomTimeChanged}
          />
          <label style={{ margin: 3 }}>Select custom</label>
        </div>
        <button
          className="ui-btn ui-shadow ui-corner-all"
          onClick={this.onSetCurrentTimeClick}
        >
          {window.lang.setCurrentTime}
        </button>
        <div style={{ display: "flex" }}>
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={this.onOkClick}
          >
            {window.lang.ok}
          </button>
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={this.props.onHide}
          >
            {window.lang.cancel}
          </button>
        </div>
      </div>
    );
  }
}
