import React, { Component } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "./VaccinationPopup.css";

class VaccinationPopupWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      horseArr: [],
      currentHorsesIds: [],
      vaccinationTypes: [],
      date: "",
      nextVaccinationDate: "",
      currentVaccinationType: "",
      currentVaccinationId: "",
      currentHorseUserid: "",
      showHorseList: "",
      selectionType: this.props.options.selectionType,
    };
  }

  componentDidUpdate = () => {
    if (this.state.selectionType != "many") this.scrollToSelectedItems();
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    let horseArr = window.DataStorage.getAllHorses();
    horseArr = horseArr.sort((h1, h2) =>
      h1.name.toLowerCase() > h2.name.toLowerCase() ? 1 : -1
    );
    let vaccinationTypes = window.VaccinationPage.types;
    let currentHorseId = "";
    let currentVaccinationType = "";
    let currentVaccinationId = "";

    if (!this.props.options.horseId) currentHorseId = horseArr[0].id;
    else currentHorseId = this.props.options.horseId;

    window.db.getHorse(currentHorseId, (horse) => {
      if (this.props.item) {
        currentVaccinationType = this.props.item.vtype;
        currentVaccinationId = this.props.item.id;
      } else {
        currentVaccinationType = this.props.options.vtype;
      }

      let date = new Date(JSON.parse(JSON.stringify(this.props.options.date)));
      let nextVaccinationDate = this.props.options.date;
      nextVaccinationDate.setMonth(
        nextVaccinationDate.getMonth() + currentVaccinationType.MaxMonth
      );
      nextVaccinationDate = nextVaccinationDate.addDays(
        currentVaccinationType.MaxDay
      );
      let showHorseList = this.props.options.showHorseList;
      this.setState({
        horseArr: horseArr,
        currentHorsesIds: [currentHorseId],
        vaccinationTypes: vaccinationTypes,
        date: date,
        currentVaccinationType: currentVaccinationType,
        nextVaccinationDate: nextVaccinationDate.formatDesh(),
        currentVaccinationId: currentVaccinationId,
        currentHorseUserid: horse.userid,
        showHorseList: showHorseList,
      });
    });
  };

  scrollToSelectedItems = () => {
    let selectedCollection = document.getElementsByClassName(
      "ui-btn selectedListItem"
    );
    let selectedArr = [].slice.call(selectedCollection);
    if (selectedArr.length) selectedArr.map((i) => i.scrollIntoView());
  };

  hide = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("vaccinationPopupReact")
    );
  };

  onCancelClick = () => {
    this.hide();
  };

  onHorseClick = (e) => {
    if (e.currentTarget.dataset.idf) {
      var horseid = e.currentTarget.dataset.idf;
      if (this.state.selectionType == "many") {
        let currentHorsesIds = [];

        if (this.state.selectionType == "many") {
          currentHorsesIds = [...this.state.currentHorsesIds];
        }
        if (currentHorsesIds.indexOf(horseid) === -1) {
          currentHorsesIds.push(horseid);
        } else {
          currentHorsesIds = currentHorsesIds.filter((i) => i !== horseid);
        }
        this.setState({
          currentHorsesIds: currentHorsesIds,
        });
        return;
      }
      window.db.getVaccinationLatest(horseid, (rows) => {
        if (rows.length != 0) {
          var lastItem = rows.item(0);
          var nextTypeIndex =
            window.VaccinationPage.types[lastItem["vtype"] - 1].NextIndex;
        }
        let currentHorsesIds = [];

        if (this.state.selectionType == "many") {
          currentHorsesIds = [...this.state.currentHorsesIds];
        }
        if (currentHorsesIds.indexOf(horseid) === -1) {
          currentHorsesIds.push(horseid);
        } else {
          currentHorsesIds = currentHorsesIds.filter((i) => i !== horseid);
        }
        this.setState({
          currentHorsesIds: currentHorsesIds,
          currentVaccinationType: window.VaccinationPage.types[nextTypeIndex],
        });
      });
    }
  };

  handleChange = (date) => {
    let nextVaccinationDate = new Date(JSON.parse(JSON.stringify(date)));
    nextVaccinationDate.setMonth(
      nextVaccinationDate.getMonth() +
        this.state.currentVaccinationType.MaxMonth
    );
    nextVaccinationDate = nextVaccinationDate.addDays(
      this.state.currentVaccinationType.MaxDay
    );
    this.setState({
      date: date,
      nextVaccinationDate: nextVaccinationDate.formatDesh(),
    });
  };

  onVaccinationTypeChange = (e) => {
    let nextVaccinationDate = new Date(
      JSON.parse(JSON.stringify(this.state.date))
    );
    nextVaccinationDate.setMonth(
      nextVaccinationDate.getMonth() +
        this.state.vaccinationTypes[e.currentTarget.value].MaxMonth
    );
    nextVaccinationDate = nextVaccinationDate.addDays(
      this.state.vaccinationTypes[e.currentTarget.value].MaxDay
    );
    this.setState({
      currentVaccinationType:
        this.state.vaccinationTypes[e.currentTarget.value],
      nextVaccinationDate: nextVaccinationDate.formatDesh(),
    });
  };

  onOkClick = (e) => {
    window.db.getVeterinarianTrainingTypeByUser(
      this.state.currentHorseUserid,
      (vTrainingTypes) => {
        let completed = 0;
        const totalHorses = this.state.currentHorsesIds.length;

        // Process each horse
        this.state.currentHorsesIds.forEach((horseId) => {
          var Id = this.state.currentVaccinationId
            ? this.state.currentVaccinationId
            : Math.uuid();
          var item = {
            Id: Id,
            HorseId: horseId,
            VType: parseInt(window.$("#vacAddTypeReact :selected").val()) + 1,
            VDate: this.state.date.formatDesh(),
            U: "u",
            SyncTime: -1,
          };

          // Insert or update vaccination
          window.db.insertOrUpdateVaccination(item);

          // Create training record
          var tr = {};
          tr["Id"] = Id;
          tr["HorseId"] = horseId;
          tr["StartTime"] = this.state.date.formatDesh();
          tr["Status"] = "";
          tr["U"] = "U";
          tr["Remark"] = "";
          tr["Color"] = "red";
          tr["Trainer"] = window.myid;
          tr["SyncTime"] = -1;
          tr["IsCanCopy"] = "0";
          tr["Duration"] = "1";
          tr["Type"] = vTrainingTypes[0].id;
          tr["TimeOfDay"] = "";
          tr["HasMultimedia"] = 0;
          tr["Evaluation"] = 0;
          tr["ReminderType"] = 4;
          tr["FarrierType"] = undefined;
          tr["PaddockPeriod"] = null;
          tr["VeterinarianType"] = "1";
          tr["VeterinarianVacType"] = parseInt(
            window.$("#vacAddTypeReact :selected").val()
          );

          // Insert or update training
          window.DataGate.insertOrUpdateTrainings(tr, (code, data) => {
            // Check if operation was successful
            if (code == window.DataGate.CODE_OK) {
              completed++;
              // Refresh training only once
              if (completed === totalHorses) {
                window.training.refresh({ showSpinner: false });
                window.Spinner.hide();

                // Only call the final callback once all operations are done
                if (this.props.callback) {
                  this.props.callback();
                }
              }
            }
          });
        });
        this.hide();
      }
    );
  };

  renderHorseListSingle = (currentElement, index) => {
    const { horseArr, currentHorsesIds } = this.state;
    const isFirstChild = index === 0;
    const isLastChild = index === horseArr.length - 1;
    const isSelected = currentHorsesIds.includes(currentElement.id);
    const listItemClass = isSelected
      ? "selectedListItem"
      : "unselectedListItem";
    const childClass = isFirstChild
      ? "ui-first-child"
      : isLastChild
      ? "ui-last-child"
      : "";

    return (
      <li
        key={Math.uuid()}
        data-icon="false"
        data-idf={currentElement.id}
        className={childClass}
        id="typeHorse"
        data-name={currentElement.name}
        type=""
        onClick={this.onHorseClick.bind(this)}
      >
        <a className={`ui-btn ${listItemClass}`} href="#">
          {currentElement.name}
        </a>
      </li>
    );
  };

  renderHorseListMany = (currentElement, index) => {
    //currentHorsesIds
  };

  render() {
    const {
      horseArr,
      vaccinationTypes,
      date,
      currentVaccinationType,
      nextVaccinationDate,
      showHorseList,
    } = this.state;
    return (
      <div>
        <div
          id="vaccinationEditWindowReact"
          style={
            showHorseList
              ? { width: "500px", maxWidth: "90vw" }
              : { width: "260px", maxWidth: "90vw" }
          }
          className="ui-popup ui-page-theme-a"
        >
          <a
            href="#"
            className="ui-btn ui-corner-all ui-shadow ui-btn-a ui-icon-delete ui-btn-icon-notext ui-btn-right"
            onClick={this.onCancelClick}
          ></a>
          <table className="vaccinationEditWindowReactTable">
            <tbody>
              {showHorseList ? (
                <tr id="taskTextLine">
                  <td align="center">{window.lang.horse}</td>
                </tr>
              ) : (
                <></>
              )}
              <tr id="taskListsLine">
                {showHorseList ? (
                  <td>
                    <div id="searchPickPlace" className="listCont">
                      <ul
                        data-role="listview"
                        data-inset="true"
                        className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
                        id="dates3"
                        data-autodividers="true"
                      >
                        {horseArr.length
                          ? horseArr.map((currentElement, index) => {
                              return this.renderHorseListSingle(
                                currentElement,
                                index
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </td>
                ) : (
                  <></>
                )}
                <td>
                  {vaccinationTypes.length ? (
                    <select
                      id="vacAddTypeReact"
                      value={vaccinationTypes.indexOf(currentVaccinationType)}
                      onChange={this.onVaccinationTypeChange}
                    >
                      {vaccinationTypes.map((currentElement, index) => (
                        <option key={index} value={index}>
                          {currentElement.Name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <></>
                  )}
                  <table style={{ width: "100%", height: "80px" }}>
                    <tbody>
                      <tr>
                        <td style={{ valign: "middle" }}>
                          <div
                            id="dateTraining"
                            className="dateVaccinationDivReact"
                          >
                            <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                              <DatePicker
                                customInput={<InputForDesktop />}
                                dateFormat="yyyy-MM-dd"
                                selected={date}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table style={{ width: "100%", height: "50px" }}>
                    <tbody>
                      <tr style={{ textAlign: "center" }}>
                        <td>
                          <label> {window.lang.nextVaccinationShort}</label>
                        </td>
                        <td>
                          <label> {nextVaccinationDate} </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ valign: "middle" }}>
                          <button
                            className="ui-btn ui-shadow ui-corner-all"
                            style={{ width: "100%" }}
                            onClick={this.onOkClick}
                          >
                            {window.lang.ok}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const InputForDesktop = (props) => {
  return (
    <input
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      style={{ backgroundColor: "#292A3C", color: "#FFFFFF" }}
    />
  );
};

export default class VaccinationPopup {
  showVaccinationPopup(options, item, callback) {
    if (options == null) {
      options = {};
    }
    if (options.date == null) {
      options.date = new Date();
    }
    if (options.showHorseList == null) {
      options.showHorseList = true;
    }
    ReactDOM.render(
      <VaccinationPopupWindow
        options={options}
        item={item}
        callback={callback}
      />,
      document.getElementById("vaccinationPopupReact")
    );
  }
}
