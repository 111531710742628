import React from "react";
import ReactDOM from "react-dom";
import FarrierView from "./FarrierView/FarrierView";
import VaccinationView from "./VaccinationView/VaccinationView";
import TeethView from "./TeethView/TeethView";
import XrayView from "./XrayView/XrayView";
import DewormingView from "./DewormingView/DewormingView";
import HerpesVaccinationView from "./VaccinationView/HerpesVaccinationView";
import AllHorseDataWithPrice from "../TrainingViews/AllHorseWithPriceView/AllHorseDataWithPrice";

export default class HomePageExtention {
  renderFarrier() {
    ReactDOM.render(
      <FarrierView curdate={new Date()} />,
      document.getElementById("homeReactDiv")
    );
  }

  renderVaccination() {
    ReactDOM.render(
      <VaccinationView />,
      document.getElementById("homeReactDiv")
    );
  }

  renderHerpesVaccination() {
    ReactDOM.render(
      <HerpesVaccinationView />,
      document.getElementById("homeReactDiv")
    );
  }

  renderTeeth() {
    ReactDOM.render(<TeethView />, document.getElementById("homeReactDiv"));
  }
  //   @todo
  //   $("#sellDataDiv").hide();
  renderSalesData() {
    ReactDOM.render(
      <AllHorseDataWithPrice />,
      document.getElementById("homeReactDiv")
    );
  }

  renderXray() {
    ReactDOM.render(<XrayView />, document.getElementById("homeReactDiv"));
  }

  renderDeworming() {
    ReactDOM.render(<DewormingView />, document.getElementById("homeReactDiv"));
  }
}
