import React, { Component } from "react";
import DataGateActions from "../../DataGateActions";
import './FeedView.css';
import HorseCell from "../HorseCell/HorseCell";

class FeedView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feeds: [],
            headers: window.DataStorage.feedHeaders,
            showDeletePopup: false
        };
    }

    componentDidMount() {
        window.$("#addTaskImg").click(this.addHeader);
        window.$("#trashImg").click(this.deleteHeaderClick);

        DataGateActions.getFeedData(this.onDataLoad);
    }

    componentWillUnmount() {
        window.$("#addTaskImg").unbind("click", this.addHeader);
        window.$("#trashImg").unbind("click", this.deleteHeaderClick);
    }

    addHeader = () => {
        this.setState({
            headers: [...this.state.headers, "[Enter name]"]
        });
    }

    deleteHeaderClick = () => {
        this.setState({
            showDeletePopup: true
        });
    }

    deleteHeader = (header) => {
        if (header != null) {
            let feedsForDelete = this.state.feeds.filter(f => f.TimeHeader == header);
            if (feedsForDelete.length != 0) {
                let feedIds = "(" + feedsForDelete.map(f => "'" + f.Id + "'").join(",") + ")";
                window.db.deleteFeeds(feedIds, () => {
                });
            }

            let headersNew = this.state.headers.filter(h => h != header);
            this.setState({
                feeds: this.state.feeds.filter(f => f.TimeHeader != header),
                headers: headersNew,
                showDeletePopup: false
            });
            this.saveHeadersLocal(headersNew);
        } else {
            this.setState({
                showDeletePopup: false
            });
        }
    }

    saveHeadersLocal = (headers) => {
        window.GlobalData.setItem(window.GlobalData.FEED_HEADERS, JSON.stringify(headers));
    }

    onHeaderBlur = (e) => {
        if (e.target.defaultValue != e.target.value) {
            let feeds = this.state.feeds.filter(f => f.TimeHeader == e.target.defaultValue);
            feeds.forEach((f) => {
                f.TimeHeader = e.target.value;
            });
            if (feeds.length != 0) {
                let feedIds = "(" + feeds.map(f => "'" + f.Id + "'").join(",") + ")";
                window.db.updateFeedHeader(e.target.value, feedIds, () => {
                });
            }

            let allfeeds = [...this.state.feeds.filter(f => f.TimeHeader != e.target.defaultValue), ...feeds];
            let headersNew = [...this.state.headers.filter(h => h != e.target.defaultValue), e.target.value].sort();
            this.setState({
                headers: headersNew,
                feed: allfeeds
            });
            this.saveHeadersLocal(headersNew);
        }
    }

    onFeedBlur = (e, feed, horseId, feedHeader) => {
        if (e.target.defaultValue != e.target.value) {
            if (feed == null) {
                feed = {
                    Id: window.Math.uuid(),
                    HorseId: horseId,
                    TimeHeader: feedHeader,
                    U: 'u'
                };
            }
            feed.Feed = e.target.value;
            window.db.insertOrUpdateFeed(feed);
        }
    }

    onDataLoad = (feedData) => {
        let headers = feedData.map(f => f.TimeHeader);
        headers = [...this.state.headers, ...headers];
        headers = Array.from(new Set(headers)).sort();
        this.setState({
            feeds: feedData,
            headers: headers
        });
    }

    render() {
        const { filteredHorses } = this.props;
        const { feeds, headers, showDeletePopup } = this.state;
        const widthFirstColumn = 200;

        return (
            <div>
                <table className={!window.isMobileDevice() ? "tableWeekView" : "tableWeekView tableWeekViewMobile"}>
                    <thead>
                        <tr>
                            <td style={{ width: widthFirstColumn }}>
                                {window.lang.horse}
                            </td>
                            {headers.map((header) => (
                                <td key={header}>
                                    <textarea className="feedHeader" defaultValue={header} onBlur={this.onHeaderBlur} />
                                </td>
                            ))}
                        </tr>
                    </thead>
                </table>
                <div className={!window.isMobileDevice() ? "scrollableFeed" : "scrollableMobileFeed"}>
                    <table className={!window.isMobileDevice() ? "tableWeekView" : "tableWeekView tableWeekViewMobile"}>
                        <tbody>
                            {filteredHorses.map((horse) => (
                                <tr key={horse.id}>
                                    <td style={{ width: widthFirstColumn, textAlign: "left" }}>
                                        <HorseCell horse={horse} />
                                    </td>
                                    {headers.map((header) => {
                                        let feed = this.state.feeds.find(f => f.TimeHeader == header && f.HorseId == horse.id);
                                        return (<td key={`${header}&${horse.id}`}>
                                            <textarea
                                                className="feedinput"
                                                defaultValue={feed ? feed.Feed : ""}
                                                onBlur={(e) => this.onFeedBlur(e, feed, horse.id, header)}
                                            />
                                        </td>);
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {showDeletePopup &&
                    <div className="feedDeleteHeaderDiv">
                        <div className="feedDeleteTitle">Delete column:</div>
                        {headers.map(header => (
                            <div key={`del-${header}`} className="feedDeleteItem" onClick={() => this.deleteHeader(header)}>{header}</div>
                        ))}
                        <div className="feedDeleteBottom" onClick={() => this.deleteHeader(null)}>Cancel</div>
                    </div>
                }
            </div>
        );
    }
}

export default FeedView;