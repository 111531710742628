import React, { useEffect, useState } from "react";
import "./WalkerAssignmentView.css";

import { TrainingCell } from "../TrainingViews/TrainingCell/TrainingDay";

export default function WalkerAssignmentView({
  walkerAreas,
  filteredHorses,
  filteredTrainings,
  date,
}) {
  console.log("walkerAreas", walkerAreas);

  const capitalizeAllWords = (str) =>
    str
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(" ");

  const getHorse = (id) => filteredHorses.find((horse) => horse.id == id);
  const isWalker = (name) => {
    if (!name) return false;
    name = name.toLowerCase();

    return name == "walker";
  };
  const returnCellContent = (walker_area_id, walker_id, time_id) => {
    let current_trainings = filteredTrainings.filter((training) => {
      if (!isWalker(training.name)) return false;

      if (
        training.WalkerAreaId != walker_area_id ||
        training.WalkerId != walker_id ||
        training.WalkerAreaTimePeriodId != time_id
      )
        return false;

      return true;
    });

    let nrOfTasks = 1;
    for (let walker_area of walkerAreas) {
      if (walker_area.id == walker_area_id) {
        for (let walker of walker_area.walkers) {
          if (walker.id == walker_id) {
            nrOfTasks = walker.maxNumberHorses;
          }
        }
      }
    }

    while (current_trainings.length < nrOfTasks) {
      current_trainings.push(null);
    }

    return (
      <td
        key={walker_area_id + "-" + walker_id + "-" + time_id}
        className="horseName"
        // draggable
        // onDragStart={(e) =>
        //   handleDragStart(e, walker_area_id, walker_id, time_id)
        // }
        // onDrop={(e) => handleDrop(e, walker_area_id, walker_id, time_id)}
        // onDragOver={handleDragOver}
      >
        {current_trainings.map((current_training, index) => {
          if (current_training == null) {
            return (
              <TrainingCell
                multipleOnSameColumn={index != current_trainings.length - 1}
                key={
                  walker_area_id + "-" + "-" + time_id + walker_id + "-" + index
                }
                training={null}
                horse={filteredHorses[0] || undefined}
                date={date}
                holdEnabled={false}
                walkerAssignInfo={{ walker_area_id, walker_id, time_id }}
                fromWalkerAssignmentView={true}
                draggable={true}
              />
            );
          }
          return (
            <TrainingCell
              multipleOnSameColumn={index != current_trainings.length - 1}
              key={current_training?.id}
              training={current_training}
              horse={getHorse(current_training?.horseid)}
              walkerAssignInfo={{ walker_area_id, walker_id, time_id }}
              date={date}
              showHorseName={true}
              holdEnabled={false}
              setTimeOnFirstHold={true}
              fromWalkerAssignmentView={true}
              draggable={true}
            />
          );
        })}
      </td>
    );
  };

  const renderWalkerAreaTable = (walkerArea) => {
    if (walkerArea.walkers.length == 0) {
      return <span>No walkers available </span>;
    }
    if (walkerArea.timePeriods.length == 0) {
      return <span>No time periods available</span>;
    }

    return (
      <table className="riderTableR riderTableR Field riderTableR ">
        <thead>
          <tr>
            <td>{capitalizeAllWords(window.lang.walker)}</td>
            {walkerArea.timePeriods.map((timePeriod) => (
              <td key={timePeriod.id}>{timePeriod.period}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {walkerArea.walkers.map((walker) => (
            <tr key={walkerArea.id + walker.id}>
              {/* 1  + timePeriods.length td's */}
              <td className="horseName">{walker.name}</td>
              {walkerArea.timePeriods.map((timePeriod) => {
                return returnCellContent(
                  walkerArea.id,
                  walker.id,
                  timePeriod.id
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <div className="field-area-scrollable">
      {walkerAreas.map((walkerArea, index) => (
        <section
          key={walkerArea.id}
          className="field-area-section"
          style={{
            border: 0,
            marginBottom: index == walkerAreas.length - 1 ? "50px" : 0,
          }}
        >
          <h3>
            {window.lang.walkerArea}:
            <span className="field-area-section--title">{walkerArea.name}</span>
          </h3>

          <div className="field-area-section--data-holder">
            {renderWalkerAreaTable(walkerArea)}
          </div>
        </section>
      ))}
    </div>
  );
}
