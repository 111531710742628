import React, { Component } from "react";
import DataGateActions from "../../DataGateActions";
import "./FarrierView.css";
import ToggleView from "../../Controls/ToggleView/ToggleView";
import TrainingPopup from "../../TrainingPopup/TrainingPopup";

class FarrierView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };

    this.reminderNames = [
      window.lang.reminder_No,
      window.lang.reminder_SameDay,
      window.lang.reminder_1_dayBefore,
      window.lang.reminder_2_dayBefore,
      window.lang.reminder_3_dayBefore,
      window.lang.reminder_4_dayBefore,
      window.lang.reminder_5_dayBefore,
      window.lang.reminder_6_dayBefore,
      window.lang.reminder_7_dayBefore,
    ];
  }

  componentDidMount() {
    this.loadData();
    window.training.onTrainingAddChangeCallback = () => {
      this.loadData();
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.curdate !== prevProps.curdate) {
      this.loadData();
    }
  }

  loadData() {
    DataGateActions.getFarrierData((data) => {
      if (data != null) {
        this.setState({
          data: data,
        });
      }
    });
  }

  filterData() {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    //fiter by type
    const horseType = JSON.parse(
      window.GlobalData.getItem(window.GlobalData.SHOW_IN_TRAINING)
    );
    if (horseType == "1" || horseType == "101") {
      //show all
    } else if (horseType == "2") {
      //in training
      horseArr = horseArr.filter((h) => h.istraining == "1");
    } else if (horseType == "3") {
      //own in training
      horseArr = horseArr.filter(
        (h) => h.istraining == "1" && h.userid == window.myid
      );
    } else {
      let horseTypeIds = horseType.map((i) => {
        let horseTypeArr = i.split(",");
        return horseTypeArr;
      });
      horseTypeIds = horseTypeIds.flat();
      horseArr = horseArr.filter(
        (h) => horseTypeIds.indexOf(h.HorseType) != -1
      );
    }

    let horseIds = horseArr.map((h) => h.id);
    let data = this.state.data.filter((t) => horseIds.indexOf(t.HorseId) != -1);
    return data;
  }

  onEditClick = (item) => {
    window.db.getTrainers(item.HorseId, (data) => {
      let horse = window.DataStorage.getAllHorses().find(
        (h) => h.id == item.HorseId
      );
      let trainingTypes = window.DataStorage.trainTypes.filter(
        (i) => i.owner == horse.userid
      );
      let currentTraining = trainingTypes.filter((i) => i.id == item.Type);
      let currentTrainer = data.filter((i) => i.id == item.Trainer);
      let options = {
        horseId: item.HorseId,
        trainingType: item.Type,
        date: Date.getNewDate(item.StartTime),
        trainerId: currentTrainer[0].id,
        trainerName: currentTrainer[0].name,
      };

      let newItem = {
        color: item.Color,
        duration: item.Duration,
        Evaluation: item.Evaluation,
        FarrierType: item.FarrierType,
        FarrierTypeName: item.FarrierTypeName,
        HasMultimedia: item.HasMultimedia,
        HorseId: item.HorseId,
        HorseName: item.HorseName,
        id: item.Id,
        isCanCopy: item.IsCanCopy,
        PaddockPeriod: item.PaddockPeriod,
        remark: item.Remark,
        ReminderType: item.ReminderType,
        StartTime: item.StartTime,
        status: item.Status,
        SyncTime: item.SyncTime,
        TimeOfDay: item.TimeOfDay,
        trainer: item.Trainer,
        Type: item.Type,
        U: item.U,
        VeterinarianType: item.VeterinarianType,
        VeterinarianVacType: item.VeterinarianVacType,
        name: currentTraining[0].name,
        shortname: currentTraining[0].shortname,
        trainername: currentTrainer[0].name,
      };

      let trainingPopup = new TrainingPopup();
      trainingPopup.showPopup(options, newItem, () => {
        window.HomePage.latestIconClick = "homeIconFarrier";
        window.HomePage.prototype.onFarrierMenuClick();
      });
    });
  };

  onAddClick = (isDone) => {
    let horse = window.DataStorage.getAllHorses()[0];
    let trainingTypes = window.DataStorage.trainTypes.filter(
      (i) => i.owner == horse.userid
    );
    let farTraining = trainingTypes.filter((i) => i.IsFarrier == true);
    let date = new Date();
    if (!isDone) {
      let weeks = window.GlobalData.getItem(
        window.GlobalData.SETTINGS_FARRIER_WEEKS
      );
      if (weeks < 7) {
        date.setDate(
          date.getDate() + ((parseInt(weeks) + 1 + 7 - date.getDay()) % 7)
        );
      } else if (weeks > 7) {
        date = new Date().addDays(7 * (parseInt(weeks) - 4));
      }
    }
    let currentTrainerId = horse.trainer;
    if (!currentTrainerId)
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == window.myid
      );
    else
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == currentTrainerId
      );
    let options = {
      trainingType: farTraining[0].id,
      date: date,
      isDone: isDone,
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    let trainingPopup = new TrainingPopup();
    trainingPopup.showPopup(options, null, () => {
      window.HomePage.latestIconClick = "homeIconFarrier";
      window.HomePage.prototype.onFarrierMenuClick();
    });
  };

  onDeleteClick = (item) => {
    let data = this.state.data.filter((t) => t.Id != item.Id);
    this.setState({
      data: data,
    });

    DataGateActions.deleteTraining(item.Id);
    window.DataStorage.deleteTrainingById(item.Id);
    window.training.refreshLayout();
    window.HomePage.latestIconClick = "homeIconFarrier";
    window.HomePage.prototype.onFarrierMenuClick();
  };

  onAddForHorseClick = (horseId) => {
    let horse = window.DataStorage.getAllHorses().filter(
      (i) => i.id == horseId
    );
    let trainingTypes = window.DataStorage.trainTypes.filter(
      (i) => i.owner == horse[0].userid
    );
    let farTraining = trainingTypes.filter((i) => i.IsFarrier == true);
    let date = new Date();
    let farrWeek = window.GlobalData.getItem(
      window.GlobalData.SETTINGS_FARRIER_WEEKS + "&" + horseId
    );
    if (!farrWeek)
      farrWeek = window.GlobalData.getItem(
        window.GlobalData.SETTINGS_FARRIER_WEEKS
      );
    if (farrWeek < 7) {
      date.setDate(
        date.getDate() + ((parseInt(farrWeek) + 1 + 7 - date.getDay()) % 7)
      );
    } else if (farrWeek > 7) {
      date = new Date().addDays(7 * (parseInt(farrWeek) - 4));
    }
    let currentTrainerId = horse.trainer;
    if (!currentTrainerId)
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == window.myid
      );
    else
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == currentTrainerId
      );
    let options = {
      trainingType: farTraining[0].id,
      date: date,
      isDone: false,
      horseId: horse[0].id,
      farrWeek: farrWeek,
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    let trainingPopup = new TrainingPopup();
    trainingPopup.showPopup(options, null, () => {
      window.HomePage.latestIconClick = "homeIconFarrier";
      window.HomePage.prototype.onFarrierMenuClick();
    });
  };

  onDoneToggle = (item) => {
    item.Color = item.Color == "green" ? "red" : "green";
    let data = [...this.state.data];
    let index = data.findIndex((t) => t.Id == item.Id);
    data[index] = item;
    this.setState({
      data: data,
    });

    window.DataGate.insertOrUpdateTrainings(item, function (code, data) {
      if (code == window.DataGate.CODE_OK) {
        window.training.refresh({ showSpinner: false });
        if (item.Color == "green") {
          let horse = window.DataStorage.getAllHorses().filter(
            (i) => i.id == item.HorseId
          );
          let trainingTypes = window.DataStorage.trainTypes.filter(
            (i) => i.owner == horse[0].userid
          );
          let farTraining = trainingTypes.filter((i) => i.IsFarrier == true);
          let date = new Date();
          let weeks = window.GlobalData.getItem(
            window.GlobalData.SETTINGS_FARRIER_WEEKS
          );
          if (weeks < 7) {
            date.setDate(
              date.getDate() + ((parseInt(weeks) + 1 + 7 - date.getDay()) % 7)
            );
          } else if (weeks > 7) {
            date = new Date().addDays(7 * (parseInt(weeks) - 4));
          }
          let currentTrainerId = horse.trainer;
          if (!currentTrainerId)
            var currentTrainer = window.DataStorage.allUsers.find(
              (i) => i.Id == window.myid
            );
          else
            var currentTrainer = window.DataStorage.allUsers.find(
              (i) => i.Id == currentTrainerId
            );
          let options = {
            trainingType: farTraining[0].id,
            date: date,
            horseId: horse[0].id,
            trainerId: currentTrainer.Id,
            trainerName: currentTrainer.Name,
          };
          console.log(item);
          console.log(options);
          // let trainingPopup = new TrainingPopup();
          // trainingPopup.showPopup(options, null, () => {
          //   window.HomePage.latestIconClick = "homeIconFarrier";
          //   window.HomePage.prototype.onFarrierMenuClick();
          // });
        }
      }
    });
  };

  getDaysLeft = (dateStr) => {
    return parseInt((new Date(dateStr) - new Date()) / (1000 * 60 * 60 * 24));
  };

  getTasksNotDone(data) {
    let arr = data.filter((t) => t.Color != "green");
    let tasksNotDone = [];
    arr.forEach((item) => {
      if (!tasksNotDone.find((t) => t.HorseId == item.HorseId)) {
        tasksNotDone.push(item);
      }
    });
    return tasksNotDone;
  }

  getTasksDone(data, tasksNotDone) {
    let arr = data
      .filter((t) => t.Color == "green")
      .sort((t1, t2) => {
        if (t1.StartTime < t2.StartTime) return 1;
        if (t1.StartTime > t2.StartTime) return -1;
        return 0;
      });
    let tasksDone = [];
    arr.forEach((item) => {
      if (
        !tasksDone.find((t) => t.HorseId == item.HorseId) &&
        !tasksNotDone.find((t) => t.HorseId == item.HorseId)
      ) {
        tasksDone.push(item);
      }
    });
    tasksDone = tasksDone.sort((t1, t2) => {
      if (t1.StartTime > t2.StartTime) return 1;
      if (t1.StartTime < t2.StartTime) return -1;
      return 0;
    });
    return tasksDone;
  }

  renderTable = (data, isDone) => {
    return (
      <>
        <tr>
          <th>{window.lang.trained}</th>
          <th>{isDone ? window.lang.shoeDate : window.lang.planedFarrier}</th>
          <th>{window.lang.horse}</th>
          <th>Type</th>
          <th>Reg. nr</th>
          <th>Chipid</th>
          <th>{window.lang.shoeDaysSinceLast}</th>
          <th>{window.lang.shoeType}</th>
          <th>Notification</th>
          <th>{window.lang.remark}</th>
          <th></th>
          {isDone ? <th></th> : <></>}
        </tr>
        {data.map((item) => (
          <tr key={item.Id + isDone}>
            <td>
              <ToggleView
                checked={isDone}
                onClick={() => this.onDoneToggle(item)}
              />
            </td>
            <td>{item.StartTime}</td>
            <td>{item.HorseName}</td>
            <td>{item.HorseType ? item.HorseType : "None"}</td>
            <td>{item.RFBid}</td>
            <td>{item.Chipid}</td>
            <td>{isDone ? -1 * this.getDaysLeft(item.StartTime) : ""}</td>
            <td>{item.FarrierTypeName}</td>
            <td>{this.reminderNames[item.ReminderType]}</td>
            <td>{item.Remark}</td>
            <td>
              <img
                loading="lazy"
                className="actionIcon"
                src="img/edit_icon.png"
                onClick={() => this.onEditClick(item)}
              />
              <img
                loading="lazy"
                className="actionIcon"
                src="img/trashcan.png"
                onClick={() => this.onDeleteClick(item)}
              />
            </td>
            {isDone ? (
              <td>
                <button
                  className="ui-btn ui-shadow ui-corner-all"
                  onClick={() => this.onAddForHorseClick(item.HorseId)}
                >
                  {window.lang.planFarrier}
                </button>
              </td>
            ) : (
              <></>
            )}
          </tr>
        ))}
      </>
    );
  };

  renderRemainingHorsesTable = (data) => {
    let horses = window.DataStorage.getAllHorses();
    let horsesArr = horses.filter(
      (e) => data.findIndex((i) => e.id == i.HorseId) === -1
    );
    horsesArr = horsesArr.sort((t1, t2) => {
      if (t1.name > t2.name) return 1;
      if (t1.name < t2.name) return -1;
      return 0;
    });
    return (
      <>
        <tr>
          <th>{window.lang.horse}</th>
          <th></th>
        </tr>
        {horsesArr.length ? (
          horsesArr.map((i) => (
            <tr key={Math.uuid()}>
              <td>{i.name}</td>
              <td>
                <button
                  className="ui-btn ui-shadow ui-corner-all"
                  onClick={() => this.onAddForHorseClick(i.id)}
                >
                  {window.lang.planFarrier}
                </button>
              </td>
            </tr>
          ))
        ) : (
          <></>
        )}
      </>
    );
  };

  onMakePdfClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let tasksNotDoneReportArr = [];
    tasksNotDoneReportArr.push([
      window.lang.shoeDate,
      window.lang.horse,
      "Type",
      "Reg. nr",
      "Chipid",
      window.lang.shoeDaysSinceLast,
      window.lang.shoeType,
      "Notification",
      window.lang.remark,
    ]);
    let tasksNotDone = this.getTasksNotDone(this.filterData());
    tasksNotDone.map((item) =>
      tasksNotDoneReportArr.push([
        item.StartTime,
        item.HorseName,
        item.HorseType ? item.HorseType : "None",
        item.RFBid,
        item.Chipid,
        "",
        item.FarrierTypeName,
        this.reminderNames[item.ReminderType],
        item.Remark,
      ])
    );
    reportDataArr.push({
      header: "Tasks not done",
      data: tasksNotDoneReportArr,
    });

    let tasksDoneReportArr = [];
    tasksDoneReportArr.push([
      window.lang.shoeDate,
      window.lang.horse,
      "Type",
      "Reg. nr",
      "Chipid",
      window.lang.shoeDaysSinceLast,
      window.lang.shoeType,
      "Notification",
      window.lang.remark,
    ]);
    let tasksDone = this.getTasksDone(this.filterData(), tasksNotDone);
    tasksDone.map((item) =>
      tasksDoneReportArr.push([
        item.StartTime,
        item.HorseName,
        item.HorseType ? item.HorseType : "None",
        item.RFBid,
        item.Chipid,
        -1 * this.getDaysLeft(item.StartTime),
        item.FarrierTypeName,
        this.reminderNames[item.ReminderType],
        item.Remark,
      ])
    );
    reportDataArr.push({
      header: "Tasks done",
      data: tasksDoneReportArr,
    });

    window.DataGate.makePdf(
      reportDataArr,
      "Farrier report",
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        a.download = "Report.pdf";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  onMakeXlsxClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let tasksNotDoneReportArr = [];
    tasksNotDoneReportArr.push([
      window.lang.shoeDate,
      window.lang.horse,
      "Type",
      "Reg. nr",
      "Chipid",
      window.lang.shoeDaysSinceLast,
      window.lang.shoeType,
      "Notification",
      window.lang.remark,
    ]);
    let tasksNotDone = this.getTasksNotDone(this.filterData());
    tasksNotDone.map((item) =>
      tasksNotDoneReportArr.push([
        item.StartTime,
        item.HorseName,
        item.HorseType ? item.HorseType : "None",
        item.RFBid,
        item.Chipid,
        "",
        item.FarrierTypeName,
        this.reminderNames[item.ReminderType],
        item.Remark,
      ])
    );
    reportDataArr.push({
      header: "Tasks not done",
      data: tasksNotDoneReportArr,
    });

    let tasksDoneReportArr = [];
    tasksDoneReportArr.push([
      window.lang.shoeDate,
      window.lang.horse,
      "Type",
      "Reg. nr",
      "Chipid",
      window.lang.shoeDaysSinceLast,
      window.lang.shoeType,
      "Notification",
      window.lang.remark,
    ]);
    let tasksDone = this.getTasksDone(this.filterData(), tasksNotDone);
    tasksDone.map((item) =>
      tasksDoneReportArr.push([
        item.StartTime,
        item.HorseName,
        item.HorseType ? item.HorseType : "None",
        item.RFBid,
        item.Chipid,
        -1 * this.getDaysLeft(item.StartTime),
        item.FarrierTypeName,
        this.reminderNames[item.ReminderType],
        item.Remark,
      ])
    );
    reportDataArr.push({
      header: "Tasks done",
      data: tasksDoneReportArr,
    });
    window.DataGate.makeXlsx(
      reportDataArr,
      "Farrier report",
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Report.xlsx";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  render() {
    const data = this.filterData();
    const tasksNotDone = this.getTasksNotDone(data);
    const tasksDone = this.getTasksDone(data, tasksNotDone);
    //console.log(data);

    return (
      <div id="farrierViewDiv">
        <div className="topRow">
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={() => this.onAddClick(true)}
          >
            {window.lang.shoeNewFarrier}
          </button>
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={() => this.onAddClick(false)}
          >
            {window.lang.planFarrier}
          </button>
          <img
            loading="lazy"
            id="makePDFButton0"
            className="reportFarrButton"
            src="img/pdf_download.png"
            onClick={this.onMakePdfClick}
          />
          <img
            loading="lazy"
            id="makeXlsxButton0"
            className="reportFarrButton"
            src="img/excel_download.png"
            onClick={this.onMakeXlsxClick}
          />
        </div>
        <table className="farrierTable">
          <tbody>
            {this.renderTable(tasksNotDone, false)}
            {this.renderTable(tasksDone, true)}
          </tbody>
        </table>
        <h3 style={{ marginLeft: "20px" }}>
          {window.lang.remainingHorsesFarr}
        </h3>
        <table
          className="remainingHorsesFarrierTable"
          style={{ borderCollapse: "collapse" }}
        >
          <tbody>{this.renderRemainingHorsesTable(data)}</tbody>
        </table>
      </div>
    );
  }
}

export default FarrierView;
