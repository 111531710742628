import React, { Component } from "react";
import VaccinationPopup from "../../Home/VaccinationView/VaccinationPopup";
import "./VaccinationPage.css";
import VaccinationNextPopup from "./VaccinationNextPopup";
import HorseGallery from "../.././Multimedia/HorseGallery";

class VaccinationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vaccinationData: [],
    };
  }

  componentDidMount() {
    window.$("#bottomMenu").hide();
    window.TopMenu.Self.setVisible(false);
    this.loadData();
    new window.dropboxData(this.props.horseId, "Vaccination");
  }

  loadData = () => {
    window.db.getVaccination(this.props.horseId, (data) => {
      let vaccinationData = [];
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let type = window.VaccinationPage.types[item["vtype"] - 1];
        let minMonths = type.MinMonth;
        let maxMonths = type.MaxMonth;
        let minDays = type.MinDay;
        let maxDays = type.MaxDay;
        let lastDate = Date.getNewDate(item["vdate"]);
        let minDate = new Date(lastDate);
        let maxDate = new Date(lastDate);

        minDate.setMonth(minDate.getMonth() + minMonths);
        minDate = minDate.addDays(minDays);
        maxDate.setMonth(maxDate.getMonth() + maxMonths);
        maxDate = maxDate.addDays(maxDays);
        vaccinationData.push({
          id: data[i].id,
          vdate: lastDate.formatDesh(),
          nextVaccination: maxDate.formatDesh(),
          type: type.Name,
          horseid: data[i].horseid,
          vtype: type,
        });
      }
      this.setState({
        vaccinationData: vaccinationData,
      });
    });
  };

  onEditClick = (item) => {
    let options = {
      horseId: this.props.horseId,
      date: new Date(item.vdate),
      showHorseList: false,
    };
    let vaccinationPopup = new VaccinationPopup();
    vaccinationPopup.showVaccinationPopup(options, item, () => {
      this.loadData();
    });
  };

  onDeleteClick = (item) => {
    window.db.setVaccinationUpdate(item.id, "d");
    window.DataStorage.deleteTrainingById(item.id);
    window.training.refreshLayout();

    window.DataGate.deleteTrainings(
      item.id,
      "d",
      item.horseid,
      item.vdate,
      (code) => {
        if (code == window.DataGate.CODE_OK) {
          window.training.refresh({ showSpinner: false });
          this.loadData();
        }
      }
    );
  };

  onNewVaccinationButtonClick = () => {
    window.db.getVaccinationLatest(this.props.horseId, (rows) => {
      let vtype = null;
      if (rows.length != 0) {
        var lastItem = rows.item(0);
        var nextTypeIndex =
          window.VaccinationPage.types[lastItem["vtype"] - 1].NextIndex;
        vtype = window.VaccinationPage.types[nextTypeIndex];
      } else {
        vtype = window.VaccinationPage.types[0];
      }
      let options = {
        horseId: this.props.horseId,
        date: new Date(),
        vtype: vtype,
      };
      let vaccinationPopup = new VaccinationPopup();
      vaccinationPopup.showVaccinationPopup(options, null, () => {
        this.loadData();
      });
    });
  };

  onAllVaccinationClick = () => {
    let vaccinationNextPopup = new VaccinationNextPopup();
    vaccinationNextPopup.showVaccinationNextPopup();
  };

  onSaveClick = () => {
    window.Managers.extention.showHorsePage(this.props.horseId);
    window.$("#bottomMenu").show();
  };

  render() {
    const { vaccinationData } = this.state;
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );

    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onSaveClick}
          />
          <span className="npTitle">{window.lang.vaccination}</span>
          <span
            id="vacHorseInfo"
            style={{
              position: "absolute",
              right: "15%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {horse.name}
          </span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <div id="vaccinationTable">
          <table className="VaccTable">
            <tbody>
              <tr>
                <th>Type</th>
                <th>{window.lang.date}</th>
                <th>{window.lang.nextVaccinationShort}</th>
                <th></th>
                <th></th>
              </tr>
              {vaccinationData.length ? (
                vaccinationData.map((currentElement, index) => (
                  <tr key={Math.uuid()}>
                    <td>{currentElement.type}</td>
                    <td>{currentElement.vdate}</td>
                    <td>
                      {index == vaccinationData.length - 1
                        ? currentElement.nextVaccination
                        : ""}
                    </td>
                    <td>
                      <img
                        loading="lazy"
                        className="actionIcon"
                        src="img/edit_icon.png"
                        onClick={() => this.onEditClick(currentElement)}
                      />
                    </td>
                    <td>
                      <img
                        loading="lazy"
                        className="actionIcon"
                        src="img/trashcan.png"
                        onClick={() => this.onDeleteClick(currentElement)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <button
                  className="ui-btn ui-shadow ui-corner-all"
                  style={{ width: "initial" }}
                  onClick={this.onNewVaccinationButtonClick}
                >
                  {window.lang.newVaccination}
                </button>
              </td>
              <td style={{ verticalAlign: "top" }}>
                <button
                  id="vacNextVaccinationButton"
                  className="ui-btn ui-shadow ui-corner-all"
                  style={{ width: "100%" }}
                  onClick={this.onAllVaccinationClick}
                >
                  {window.lang.allVacinations}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <HorseGallery
          showUploadButton={true}
          type={"Vaccination"}
          horse={horse}
          title={"Vaccination"}
        />
      </div>
    );
  }
}

export default VaccinationPage;
