import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./DewormingTypes.css";

class DewormingTypesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dewormingTypes: [],
      newType: "",
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.db.getUserDewormingType(window.myid, (data) => {
      this.setState({
        dewormingTypes: data,
      });
    });
  };

  onBackClick = () => {
    window.showPage("#settingsPage");
    window.$("#settingsPage").css("min-height", "95%");
  };

  deleteDewormingType = (id) => {
    window.Spinner.show();
    window.db.deleteUserDewormingType(id, () => {
      this.loadData();
      window.Spinner.hide();
    });
  };

  onNewTypeInputChange = (e) => {
    this.setState({
      newType: e.target.value,
    });
  };

  onAddNewTypeClick = () => {
    if (this.state.newType == "") {
      window
        .$("#emptyDewormingTypeName")
        .text("Deworming type title is required");
    } else {
      window.Spinner.show();
      window.db.createUserDewormingType(
        Math.uuid(),
        window.myid,
        this.state.newType,
        () => {
          this.state.newType = "";
          this.loadData();
          window.Spinner.hide();
        }
      );
    }
  };

  render() {
    const { dewormingTypes, newType } = this.state;
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onBackClick}
          />
          <span className="npTitle">Deworming types</span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onBackClick}
          />
        </div>
        <div className="ppContent">
          {
            <table className="dewormingTypesTable">
              <tbody>
                <tr style={{ fontWeight: "bold" }}>
                  <td>Name</td>
                  <td>Delete</td>
                </tr>
                {dewormingTypes.map((item, itemIndex) => (
                  <tr key={item.Id}>
                    <td style={{ width: "70%" }}>{item.Name}</td>
                    <td>
                      <button
                        className="ui-btn ui-corner-all npButton"
                        style={{ margin: "auto" }}
                        onClick={() => {
                          this.deleteDewormingType(item.Id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
          <table style={{ width: "80%" }}>
            <tbody>
              <tr>
                <td
                  className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"
                  style={{ width: "70%" }}
                >
                  <input
                    type="text"
                    value={newType}
                    onChange={this.onNewTypeInputChange}
                  />
                </td>
                <td style={{ width: "30%" }}>
                  <button
                    className="ui-btn ui-corner-all npButton"
                    onClick={this.onAddNewTypeClick}
                  >
                    Add Type
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    id="emptyDewormingTypeName"
                    style={{ color: "red" }}
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default class DewormingTypes {
  showDewormingTypes() {
    ReactDOM.render(
      <DewormingTypesPage />,
      document.getElementById("showDewormingTypestReact")
    );
  }
}
