import React, { Component, useLayoutEffect } from "react";
import "./DewormingView.css";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { useEffect } from "react";
import ToggleView from "../../Controls/ToggleView/ToggleView";

export default class DewormingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dewormingData: [],
      horsesWithoutDeworming: [],
      entryOnEdit: -1,
      horseToCreateDewormingFor: null,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props != prevProps) {
      this.loadData();
    }
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.Spinner.show();
    window.db.getDewormingDataForUser(window.myid, (data) => {
      console.log(data);
      let dewormingData = [];
      let dewormingDataWithNullNextDeworming = [];
      if (data.length) {
        let deworming = [];
        data.filter((item) => {
          let curr = deworming.find((i) => item.horseid == i.horseid);
          if (!curr) {
            deworming.push(item);
            return true;
          } else {
            return false;
          }
        });

        for (let i = 0; i < deworming.length; i++) {
          let nextDewormingDate = "";

          if (deworming[i].NextDewormingPeriod != null) {
            switch (deworming[i].NextDewormingPeriod) {
              case "0":
                var date = new Date(
                  JSON.parse(JSON.stringify(deworming[i].starttime))
                );
                date = new Date(date.setMonth(date.getMonth() + 1));
                nextDewormingDate = date.formatDesh();
                break;
              case "1":
                var date = new Date(
                  JSON.parse(JSON.stringify(deworming[i].starttime))
                );
                date = new Date(date.setMonth(date.getMonth() + 3));
                nextDewormingDate = date.formatDesh();
                break;
              case "2":
                var date = new Date(
                  JSON.parse(JSON.stringify(deworming[i].starttime))
                );
                date = new Date(date.setMonth(date.getMonth() + 6));
                nextDewormingDate = date.formatDesh();
                break;
              case "3":
                var date = new Date(
                  JSON.parse(JSON.stringify(deworming[i].starttime))
                );
                date = new Date(date.setMonth(date.getMonth() + 12));
                nextDewormingDate = date.formatDesh();
                break;
            }
            dewormingData.push({
              id: deworming[i].Id,
              horseId: deworming[i].horseid,
              horse: deworming[i].Name,
              RFBid: deworming[i].RFBid,
              bornIn: deworming[i].BirthYear,
              remarks: deworming[i].Remark,
              starttime: deworming[i].starttime,
              nextDewormingDate: nextDewormingDate,
              dewormingTypeName: deworming[i].DewormingTypeName,
              dewormingStage: deworming[i].DewormingStage,
              isDone: deworming[i].Color == "green",
            });
          }
        }
        dewormingData = dewormingData.sort(function (h1, h2) {
          return h1.nextDewormingDate < h2.nextDewormingDate ? -1 : 1;
        });

        dewormingData.push(...dewormingDataWithNullNextDeworming);
        let horseArr = window.DataStorage.getAllHorses();
        horseArr = horseArr
          .filter(
            (horse) =>
              !dewormingData.some(
                (dewormData) => dewormData.horseId === horse.id
              )
          )
          .sort((h1, h2) =>
            h1.name.toLowerCase() > h2.name.toLowerCase() ? 1 : -1
          );
        this.setState(
          {
            dewormingData: dewormingData,
            horsesWithoutDeworming: horseArr,
          },
          window.Spinner.hide()
        );
      } else {
        window.Spinner.hide();
      }
    });
  };

  deleteDeworming = (item) => {
    window.DataStorage.deleteTrainingById(item.id);
    window.training.refreshLayout();
    window.Spinner.show();
    window.DataGate.deleteTrainings(
      item.id,
      "d",
      item.horseId,
      item.starttime,
      (code) => {
        window.Spinner.hide();
        if (code == window.DataGate.CODE_OK) {
          window.training.refresh({ showSpinner: false });
          this.loadData();
        }
      }
    );
  };

  onNewDewromingForHorseButtonClick = (horseId) => {
    this.setState({ horseToCreateDewormingFor: { horseId: horseId } });
  };

  getDewormingStageText = (stage) => {
    if (!stage) return window.lang.none;

    return window.lang.dewormingStages[stage - 1];
  };
  onMakePdfClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let reportData = [];
    let reportDataFinished = [];
    reportData.push([
      window.lang.date,
      window.lang.nextDeworming,
      window.lang.horse,
      "Reg. nr",
      window.lang.bornIn,
      "Deworming type",
      window.lang.remark,
      "Deworming Stage",
    ]);
    reportDataFinished.push([
      window.lang.date,
      window.lang.nextDeworming,
      window.lang.horse,
      "Reg. nr",
      window.lang.bornIn,
      "Deworming type",
      window.lang.remark,
      "Deworming Stage",
    ]);

    this.state.dewormingData
      .filter((i) => !i.isDone)
      .forEach((i) => {
        reportData.push([
          i.starttime,
          i.nextDewormingDate,
          i.horse,
          i.RFBid,
          i.bornIn,
          i.dewormingTypeName,
          i.remarks,
          this.getDewormingStageText(i.dewormingStage),
        ]);
      });
    this.state.dewormingData
      .filter((i) => i.isDone)
      .forEach((i) => {
        reportDataFinished.push([
          i.starttime,
          i.nextDewormingDate,
          i.horse,
          i.RFBid,
          i.bornIn,
          i.dewormingTypeName,
          i.remarks,
          this.getDewormingStageText(i.dewormingStage),
        ]);
      });

    reportDataArr.push({
      header: "Tasks  done",
      data: reportDataFinished,
    });
    reportDataArr.push({
      header: "Tasks not done",
      data: reportData,
    });

    window.DataGate.makePdf(
      reportDataArr,
      "Deworming report",
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        a.download = "Report.pdf";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  onMakeXlsxClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let reportData = [];
    let reportDataFinished = [];
    reportData.push([
      window.lang.date,
      window.lang.nextDeworming,
      window.lang.horse,
      "Reg. nr",
      window.lang.bornIn,
      "Deworming type",
      window.lang.remark,
      "Deworming Stage",
    ]);
    reportDataFinished.push([
      window.lang.date,
      window.lang.nextDeworming,
      window.lang.horse,
      "Reg. nr",
      window.lang.bornIn,
      "Deworming type",
      window.lang.remark,
      "Deworming Stage",
    ]);

    this.state.dewormingData
      .filter((i) => !i.isDone)
      .forEach((i) => {
        reportData.push([
          i.starttime,
          i.nextDewormingDate,
          i.horse,
          i.RFBid,
          i.bornIn,
          i.dewormingTypeName,
          i.remarks,
          this.getDewormingStageText(i.dewormingStage),
        ]);
      });
    this.state.dewormingData
      .filter((i) => i.isDone)
      .forEach((i) => {
        reportDataFinished.push([
          i.starttime,
          i.nextDewormingDate,
          i.horse,
          i.RFBid,
          i.bornIn,
          i.dewormingTypeName,
          i.remarks,
          this.getDewormingStageText(i.dewormingStage),
        ]);
      });

    reportDataArr.push({
      header: "Tasks not done",
      data: reportData,
    });

    reportDataArr.push({
      header: "Tasks  done",
      data: reportDataFinished,
    });

    window.DataGate.makeXlsx(
      reportDataArr,
      "Deworming report",
      function (data, url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        a.download = "Report.pdf";
        document.body.appendChild(a);
        a.click();
        window.Spinner.hide();
      }
    );
  };

  newDeworming = () => {
    this.setState({ horseToCreateDewormingFor: { date: new Date() } });
  };
  planDeworming = () => {
    let date = new Date();

    let weeks = window.GlobalData.getItem(
      window.GlobalData.SETTINGS_FARRIER_WEEKS
    );
    if (weeks < 7) {
      date.setDate(
        date.getDate() + ((parseInt(weeks) + 1 + 7 - date.getDay()) % 7)
      );
    } else if (weeks > 7) {
      date = new Date().addDays(7 * (parseInt(weeks) - 4));
    }
    console.log(date.formatDesh());
    this.setState({
      horseToCreateDewormingFor: { date: date, isDone: true },
    });
  };

  onToggleClick = (training_id, oldColor) => {
    const newColor = oldColor ? "red" : "green";

    window.DataGate.updateTrainingDoneState(training_id, newColor, () => {
      this.loadData();
    });
  };

  renderTable = (dewormingData) => {
    if (!dewormingData || dewormingData.length === 0) return;
    return (
      <table className="VaccinationTable DewormingTable">
        <tbody>
          <tr>
            <th>{window.lang.trained}</th>
            <th>{window.lang.date}</th>
            <th>{window.lang.nextDeworming}</th>
            <th>{window.lang.horse}</th>
            <th>Reg. nr</th>
            <th>{window.lang.bornIn}</th>
            <th>Deworming type</th>
            <th>{window.lang.remark}</th>
            <th>Deworming Stage</th>
            <th></th>
            <th></th>
          </tr>
          {dewormingData.length ? (
            dewormingData.map((i, i_index) => (
              <tr key={Math.uuid()}>
                <td>
                  <ToggleView
                    checked={i.isDone}
                    onClick={() => this.onToggleClick(i.id, i.isDone)}
                  />
                </td>
                <td>{i.starttime}</td>
                <td>{i.nextDewormingDate}</td>
                <td>{i.horse}</td>
                <td>{i.RFBid}</td>
                <td>{i.bornIn}</td>
                <td>{i.dewormingTypeName}</td>
                <td>{i.remarks}</td>
                <td>{this.getDewormingStageText(i.dewormingStage)}</td>
                <td>
                  <img
                    className="actionIcon"
                    src="img/edit_icon.png"
                    onClick={() => {
                      if (i.id) this.setState({ entryOnEdit: i_index });
                    }}
                  />
                  <img
                    onClick={() => this.deleteDeworming(i)}
                    className="actionIcon"
                    src="img/trashcan.png"
                  />
                </td>
                <td>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    onClick={() =>
                      this.onNewDewromingForHorseButtonClick(i.horseId)
                    }
                  >
                    {window.lang.newDeworming}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
    );
  };
  render() {
    const { dewormingData } = this.state;
    const doneDewormingData = dewormingData.filter((i) => i.isDone);
    const notDoneDewormingData = dewormingData.filter((i) => !i.isDone);
    return (
      <div id="dewormingViewDiv">
        <div className="topRow">
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={this.newDeworming}
          >
            {window.lang.newDeworming}
          </button>
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={this.planDeworming}
          >
            {window.lang.planDeworming}
          </button>
          <img
            loading="lazy"
            id="makePDFButton0"
            className="reportFarrButton"
            src="img/pdf_download.png"
            onClick={this.onMakePdfClick}
          />
          <img
            loading="lazy"
            id="makeXlsxButton0"
            className="reportFarrButton"
            src="img/excel_download.png"
            onClick={this.onMakeXlsxClick}
          />
        </div>
        {this.renderTable(doneDewormingData)}
        {this.renderTable(notDoneDewormingData)}
        <div className="dewormingRemaininText">
          <span>{window.lang.remainingHorsesDeworm}</span>
        </div>
        <table className="VaccinationTable DewormingTable SecondTable">
          <tbody>
            <tr>
              <th>{window.lang.horse}</th>
              <th></th>
            </tr>
            {this.state.horsesWithoutDeworming.map((horse) => (
              <tr key={horse.id}>
                <td>{horse.name}</td>
                <td>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    onClick={() =>
                      this.onNewDewromingForHorseButtonClick(horse.id)
                    }
                  >
                    {window.lang.newDeworming}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.state.entryOnEdit >= 0 && (
          <EditDewormingPopup
            data={dewormingData[this.state.entryOnEdit]}
            close={() => this.setState({ entryOnEdit: -1 })}
            refreshData={this.loadData}
          />
        )}
        {this.state.horseToCreateDewormingFor && (
          <AddDewormingPopup
            close={() => {
              this.setState({ horseToCreateDewormingFor: null });
            }}
            horseToCreateDewormingFor={this.state.horseToCreateDewormingFor}
            refreshData={this.loadData}
          />
        )}
      </div>
    );
  }
}

function AddDewormingPopup({ close, refreshData, horseToCreateDewormingFor }) {
  const [horseArr, setHorseArr] = useState([]);
  const [currentHorsesIds, setCurrentHorsesIds] = useState([
    horseToCreateDewormingFor.horseId,
  ]);

  const monthsPerPeriod = [1, 3, 6, 12];
  const initialNextDate = horseToCreateDewormingFor.date ?? new Date();

  const [date, setDate] = useState(initialNextDate);
  const addMonths = (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };

  const [nextDewormingDate, setNextDewormingDate] = useState(
    addMonths(initialNextDate, 1)
  );

  const [nextPeriod, setNextPeriod] = useState(0);
  const [dewormingTypes, setDewormingTypes] = useState([]);
  const [selectedDewormingType, setSelectedDewormingType] = useState(null);

  const [currentDewormingStage, setCurrentDewormingStage] = useState(0);

  const [isDone, setIsDone] = useState(
    horseToCreateDewormingFor.isDone ?? false
  );

  const loadData = () => {
    let horseArr = window.DataStorage.getAllHorses();
    horseArr = horseArr.sort((h1, h2) =>
      h1.name.toLowerCase() > h2.name.toLowerCase() ? 1 : -1
    );
    setHorseArr(horseArr);
  };
  useEffect(() => {
    loadData();
    window.db.getUserDewormingType(window.myid, (dewormingTypes) => {
      setDewormingTypes(dewormingTypes);
      setSelectedDewormingType(dewormingTypes[0].Id);
    });
    setNextPeriod(getNextPeriodIndex());
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollToSelectedItems();
    }, 100);
  }, [currentHorsesIds]);
  const scrollToSelectedItems = () => {
    let selectedCollection = document.getElementsByClassName(
      "ui-btn selectedListItem"
    );
    let selectedArr = [].slice.call(selectedCollection);
    if (selectedArr.length) selectedArr.map((i) => i.scrollIntoView());
  };

  function monthDifference(date1, date2) {
    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();

    return yearDiff * 12 + monthDiff;
  }
  const getNextPeriodIndex = () => {
    let numberOfMonths = monthDifference(date, nextDewormingDate);

    if (numberOfMonths >= 12) {
      return 3;
    } else if (numberOfMonths >= 6) {
      return 2;
    } else if (numberOfMonths >= 3) {
      return 1;
    } else if (numberOfMonths >= 1) {
      return 0;
    }

    return 0;
  };

  const onHorseClick = (horseId) => {
    setCurrentHorsesIds([horseId]);
  };
  const renderHorseListSingle = (currentElement, index) => {
    const isFirstChild = index === 0;
    const isLastChild = index === horseArr.length - 1;
    const isSelected = currentHorsesIds.includes(currentElement.id);
    const listItemClass = isSelected
      ? "selectedListItem"
      : "unselectedListItem";
    const childClass = isFirstChild
      ? "ui-first-child"
      : isLastChild
      ? "ui-last-child"
      : "";

    return (
      <li
        key={Math.uuid()}
        data-icon="false"
        data-idf={currentElement.id}
        className={childClass}
        id="typeHorse"
        data-name={currentElement.name}
        type=""
        onClick={() => onHorseClick(currentElement.id)}
      >
        <a className={`ui-btn ${listItemClass}`} href="#">
          {currentElement.name}
        </a>
      </li>
    );
  };

  const handleChange = (date) => {
    let nextDate = new Date(JSON.parse(JSON.stringify(date)));
    nextDate = addMonths(nextDate, monthsPerPeriod[nextPeriod]);
    setDate(date);
    setNextDewormingDate(nextDate);
  };

  const dewormingTypesPeriod = [
    window.lang.oneMonth,
    window.lang.threeMonths,
    window.lang.sixMonths,
    window.lang.oneYear,
  ];
  const saveTraining = () => {
    // Create training record

    const horseUserId = horseArr.find(
      (horse) => horse.id === currentHorsesIds[0]
    )?.userid;
    window.db.getVeterinarianTrainingTypeByUser(
      horseUserId,
      (vTrainingTypes) => {
        var tr = {};
        let Id = Math.uuid();
        tr["Id"] = Id;
        tr["HorseId"] = currentHorsesIds[0];
        tr["StartTime"] = date.formatDesh();
        tr["Status"] = "";
        tr["U"] = "U";
        tr["Remark"] = "";
        tr["Color"] = "red";
        tr["Trainer"] = window.myid;
        tr["SyncTime"] = -1;
        tr["IsCanCopy"] = "0";
        tr["Duration"] = "1";
        tr["Type"] = vTrainingTypes[0].id;
        tr["TimeOfDay"] = "";
        tr["HasMultimedia"] = 0;
        tr["Evaluation"] = 0;
        tr["ReminderType"] = 4;
        tr["FarrierType"] = undefined;
        tr["PaddockPeriod"] = null;
        tr["VeterinarianType"] = "4";
        tr["DewormingStage"] = currentDewormingStage;
        tr["StartTime"] = date.formatDesh();
        tr["NextDewormingPeriod"] = nextPeriod;
        tr["DewormingType"] = selectedDewormingType;
        tr["Color"] = isDone ? "green" : "red";
        window.DataGate.insertOrUpdateTrainings(tr, (code, data) => {
          refreshData();
          close();
          window.Spinner.hide();
        });
      }
    );
  };
  return (
    <div>
      <div
        style={{ width: "560px", maxWidth: "90vw" }}
        className="ui-popup ui-page-theme-a editWindow"
      >
        <a
          href="#"
          className="ui-btn ui-corner-all ui-shadow ui-btn-a ui-icon-delete ui-btn-icon-notext ui-btn-right"
          onClick={close}
        ></a>
        <table className="vaccinationEditWindowReactTable">
          <tbody>
            <tr id="taskTextLine">
              <td align="center">{window.lang.horse}</td>
            </tr>

            <tr id="taskListsLine">
              <td>
                <div id="searchPickPlace" className="listCont">
                  <ul
                    data-role="listview"
                    data-inset="true"
                    className="ui-listview ui-listview-inset ui-corner-all ui-shadow"
                    id="dates3"
                    data-autodividers="true"
                  >
                    {horseArr.length
                      ? horseArr.map((currentElement, index) => {
                          return renderHorseListSingle(currentElement, index);
                        })
                      : null}
                  </ul>
                </div>
              </td>

              <td>
                <div className="Deworming-time-picker">
                  <DatePicker
                    customInput={<InputForDesktop />}
                    dateFormat="yyyy-MM-dd"
                    selected={date}
                    onChange={handleChange}
                  />
                </div>

                <select
                  id="dewormingStageSelectAddPopup"
                  value={currentDewormingStage}
                  data-role="none"
                  onChange={(e) => {
                    setCurrentDewormingStage(parseInt(e.target.value));
                  }}
                >
                  <option value={0}>Select deworming stage</option>
                  {window.lang.dewormingStages.map((i, i_index) => (
                    <option key={i} value={i_index + 1}>
                      {i}
                    </option>
                  ))}
                </select>
                <div className="dewormingEditSelect">
                  <select
                    id="vetTypeSelectReact"
                    data-role="none"
                    value={selectedDewormingType}
                    onChange={(e) => {
                      setSelectedDewormingType(e.target.value);
                    }}
                  >
                    {dewormingTypes.map((i, i_index) => (
                      <option key={i.Id} value={i.Id}>
                        {i.Name}
                      </option>
                    ))}
                  </select>

                  <select
                    id="vetTypeSelectReact"
                    data-role="none"
                    value={nextPeriod}
                    onChange={(e) => {
                      setNextDewormingDate(
                        addMonths(date, monthsPerPeriod[e.target.value])
                      );
                      setNextPeriod(e.target.value);
                    }}
                  >
                    {dewormingTypesPeriod.map((i, i_index) => (
                      <option key={i} value={i_index}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
                <p>
                  {window.lang.nextDeworming}: {nextDewormingDate.formatDesh()}
                </p>
                <div className="editDewormingIsDoneCheckbox">
                  <input
                    className="tcColor"
                    type="checkbox"
                    checked={isDone}
                    data-role="none"
                    onChange={(e) => {
                      setIsDone(e.target.checked);
                    }}
                  />
                  <span>{window.lang.trained}</span>
                </div>

                <button
                  className="openTimeSelectPopup ui-btn ui-shadow ui-corner-all"
                  onClick={saveTraining}
                >
                  {window.lang.ok}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function EditDewormingPopup({ close, data, refreshData }) {
  const monthsPerPeriod = [1, 3, 6, 12];
  const initialNextDate = isNaN(Date.parse(data.nextDewormingDate))
    ? new Date() // Use today's date if nextDewormingDate is invalid
    : new Date(data.nextDewormingDate);

  const [date, setDate] = useState(new Date(data.starttime));
  const [nextDewormingDate, setNextDewormingDate] = useState(initialNextDate);

  const [currentTraining, setCurrentTraining] = useState(null);

  const [nextPeriod, setNextPeriod] = useState(0);
  const [dewormingTypes, setDewormingTypes] = useState([]);
  const [selectedDewormingType, setSelectedDewormingType] = useState(
    data.dewormingTypeId
  );

  const [currentDewormingStage, setCurrentDewormingStage] = useState(
    data?.dewormingStage
  );

  const [isDone, setIsDone] = useState(data.isDone);

  useEffect(() => {
    window.db.getUserDewormingType(window.myid, (dewormingTypes) => {
      setDewormingTypes(dewormingTypes);
    });
    //fetch current training by Id
    window.DataGate.getTraining(data.id)
      .then((training) => {
        setCurrentTraining(training);
      })
      .catch((err) => {});
    setNextPeriod(getNextPeriodIndex());
  }, []);
  function monthDifference(date1, date2) {
    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();

    return yearDiff * 12 + monthDiff;
  }
  const getNextPeriodIndex = () => {
    let numberOfMonths = monthDifference(date, nextDewormingDate);

    if (numberOfMonths >= 12) {
      return 3;
    } else if (numberOfMonths >= 6) {
      return 2;
    } else if (numberOfMonths >= 3) {
      return 1;
    } else if (numberOfMonths >= 1) {
      return 0;
    }

    return 0;
  };

  const handleChange = (date) => {
    let nextDate = new Date(JSON.parse(JSON.stringify(date)));
    nextDate = addMonths(nextDate, monthsPerPeriod[nextPeriod]);
    setDate(date);
    setNextDewormingDate(nextDate);
  };
  const addMonths = (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };

  const dewormingTypesPeriod = [
    window.lang.oneMonth,
    window.lang.threeMonths,
    window.lang.sixMonths,
    window.lang.oneYear,
  ];

  const saveTraining = () => {
    let currentTrainingCopy = { ...currentTraining };
    currentTrainingCopy.DewormingStage = currentDewormingStage;
    currentTrainingCopy.StartTime = date.formatDesh();
    currentTrainingCopy.NextDewormingPeriod = nextPeriod;
    currentTrainingCopy.DewormingType = selectedDewormingType;
    currentTrainingCopy.Color = isDone ? "green" : "red";

    window.training.trainingRequestStart();

    window.DataGate.insertOrUpdateTrainings(
      currentTrainingCopy,
      (code, data) => {
        if (code == window.DataGate.CODE_OK) {
          window.training.trainingRequestEnd();
          refreshData();
          close();
          console.log("Training saved");
        } else {
          window.showConnectionError();
        }
      }
    );
  };

  return (
    <div>
      <div
        style={{ width: "360px", maxWidth: "90vw" }}
        className="ui-popup ui-page-theme-a editWindow"
      >
        <a
          href="#"
          className="ui-btn ui-corner-all ui-shadow ui-btn-a ui-icon-delete ui-btn-icon-notext ui-btn-right"
          onClick={close}
        ></a>
        <div className="Deworming-time-picker">
          <DatePicker
            customInput={<InputForDesktop />}
            dateFormat="yyyy-MM-dd"
            selected={date}
            onChange={handleChange}
          />
        </div>
        <select
          id="dewormingStageSelect"
          value={currentDewormingStage}
          data-role="none"
          onChange={(e) => {
            setCurrentDewormingStage(parseInt(e.target.value));
          }}
        >
          <option value={0}>Select deworming stage</option>
          {window.lang.dewormingStages.map((i, i_index) => (
            <option key={i} value={i_index + 1}>
              {i}
            </option>
          ))}
        </select>

        <div className="dewormingEditSelect">
          <select
            id="vetTypeSelectReact"
            data-role="none"
            value={selectedDewormingType}
            onChange={(e) => {
              setSelectedDewormingType(e.target.value);
            }}
          >
            {dewormingTypes.map((i, i_index) => (
              <option key={i.Id} value={i.Id}>
                {i.Name}
              </option>
            ))}
          </select>

          <select
            id="vetTypeSelectReact"
            data-role="none"
            value={nextPeriod}
            onChange={(e) => {
              setNextDewormingDate(
                addMonths(date, monthsPerPeriod[e.target.value])
              );
              setNextPeriod(e.target.value);
            }}
          >
            {dewormingTypesPeriod.map((i, i_index) => (
              <option key={i} value={i_index}>
                {i}
              </option>
            ))}
          </select>
        </div>
        <span className="text-center">
          {window.lang.nextDeworming}: {nextDewormingDate.formatDesh()}
        </span>
        <div className="editDewormingIsDoneCheckbox">
          <input
            className="tcColor"
            type="checkbox"
            checked={isDone}
            data-role="none"
            onChange={(e) => {
              setIsDone(e.target.checked);
            }}
          />
          <span>{window.lang.trained}</span>
        </div>

        <button
          className="openTimeSelectPopup ui-btn ui-shadow ui-corner-all"
          onClick={saveTraining}
        >
          {window.lang.ok}
        </button>
      </div>
    </div>
  );
}

const InputForDesktop = (props) => {
  return (
    <input
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      style={{ backgroundColor: "#292A3C", color: "#FFFFFF" }}
    />
  );
};
