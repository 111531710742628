import React, { Component } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "./NextTeethCheckPopup.css";

class NextTeethCheckPopupWindow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstDate: '',
            secondDate: ''
        };
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData() {
        let now = new Date();
        let next = new Date();
        next.setFullYear(now.getFullYear() + 1);
        this.setState({
            firstDate: now,
            secondDate: next
        })
    }

    onCancelClick = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('nextTeethCheckPopup'));
    }

    firstDateChange = (firstDate) => {
        let date = new Date(JSON.parse(JSON.stringify(firstDate)));
        this.setState({
            firstDate: date
        });
    };

    secondDateChange = (secondDate) => {
        let date = new Date(JSON.parse(JSON.stringify(secondDate)));
        this.setState({
            secondDate: date
        });
    }

    onAddButtonClick = () => {
        window.Spinner.show();
        let item = {
            Id: Math.uuid(),
            HorseId: this.props.horseId,
            TDateFirst: this.state.firstDate.formatDesh(),
            TDateSecond: this.state.secondDate.formatDesh(),
            U: 'u',
            SyncTime: -1
        };
        window.db.insertOrUpdateTeeth(item, () => {
            window.Spinner.hide();
            this.onCancelClick();
            this.props.callback();
        });
    }

    render() {
        const { firstDate, secondDate } = this.state;
        return (
            <div id="nextTeethCheckPopup" className="ui-popup ui-page-theme-a ui-corner-all" style={{ width: "260px" }}>
                <a href="#" className="ui-btn ui-corner-all ui-shadow ui-btn-a ui-icon-delete ui-btn-icon-notext ui-btn-right" onClick={this.onCancelClick}>
                </a>
                <div style={{ padding: "10px" }}>
                    <label style={{ marginLeft: "10px" }} > {window.lang.teethSubDate} </label>
                    <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                        <DatePicker
                            customInput={<InputForDesktop />}
                            dateFormat="yyyy-MM-dd"
                            selected={firstDate}
                            onChange={this.firstDateChange}
                        />
                    </div>
                    <label style={{ marginLeft: "10px" }} > {window.lang.teethNextDate} </label>
                    <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                        <DatePicker
                            customInput={<InputForDesktop />}
                            dateFormat="yyyy-MM-dd"
                            selected={secondDate}
                            onChange={this.secondDateChange}
                        />
                    </div>
                    <button className="ui-btn ui-shadow ui-corner-all" style={{ width: "100%" }} onClick={this.onAddButtonClick}>{window.lang.add}</button>
                </div>
            </div>
        )
    }
}

const InputForDesktop = (props) => {
    return (
        <input
            onClick={props.onClick}
            value={props.value}
            type="text"
            readOnly={true}
            style={{ backgroundColor: '#292A3C', color: '#FFFFFF' }}
        />
    )
}

export default class NextTeethCheckPopup {
    showNextTeethCheckPopup(horseId, callback) {
        ReactDOM.render(
            <NextTeethCheckPopupWindow
                horseId={horseId}
                callback={callback}
            />,
            document.getElementById('nextTeethCheckPopup')
        )
    }
}