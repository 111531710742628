import React, { useEffect, useState } from "react";
import "./FieldAndOtherTasks.css";
import OtherTasksViewLight from "../OtherTasksViewLight/OtherTasksViewLight";
import FieldAssignmentView from "../FieldAssignmentView/FieldAssignmentView";

export default function FieldAndOtherTasks({
  date,
  generalTaskArr,
  filteredHorses,
  filteredTrainingsField,
  fieldAreas,
}) {
  return (
    <div className="fieldAndOtherTasksViewHolderWrapper">
      <div className="fieldAndOtherTasksViewScrollableArea">
        <FieldAssignmentView
          fieldAreas={fieldAreas}
          filteredHorses={filteredHorses}
          filteredTrainings={filteredTrainingsField}
          date={date}
        />

        <OtherTasksViewLight date={date} generalTaskArr={generalTaskArr} />
      </div>
    </div>
  );
}
