import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './VaccinationNextPopup.css';

class VaccinationNextPopupWindow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vaccinationData: []
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        let horseArr = [...window.DataStorage.myHorseArray, ...window.DataStorage.notMyHorseArray];
        let reportData = [];
        for (let i = 0; i < horseArr.length; i++) {
            window.db.getVaccinationLatest(horseArr[i]["id"], (rows) => {
                if (rows.length == 0) {
                    return;
                }
                let lastItem = rows.item(0);
                let type = window.VaccinationPage.types[lastItem["vtype"] - 1];
                let nextType = window.VaccinationPage.types[type.NextIndex];
                let minMonths = nextType.MinMonth;
                let maxMonths = nextType.MaxMonth;
                let minDays = nextType.MinDay;
                let maxDays = nextType.MaxDay;
                let lastDate = Date.getNewDate(lastItem["vdate"]);
                let minDate = new Date(lastDate);
                let maxDate = new Date(lastDate);

                minDate.setMonth(minDate.getMonth() + minMonths);
                minDate = minDate.addDays(minDays);
                maxDate.setMonth(maxDate.getMonth() + maxMonths);
                maxDate = maxDate.addDays(maxDays);
                let daysLeft = Date.daysBetween(maxDate, new Date());

                reportData.push({
                    horse: horseArr[i]["name"], vaccinationType: nextType.Name, lastVaccination: lastDate.formatDeshStartDay(), nextVaccination: maxDate.formatDeshStartDay(),
                    daysLeft: daysLeft, vaccinationAllow: Date.daysBetween(new Date(), minDate) < 0 ? window.lang.vacNotAllowed : ''
                });
                reportData = reportData.sort(this.sortVacinationArr);
                this.setState({
                    vaccinationData: reportData
                })
            });
        }
    }

    sortVacinationArr = (a, b) => {
        if (a.daysLeft < b.daysLeft)
            return -1;
        if (a.daysLeft > b.daysLeft)
            return 1;
        return 0;
    }

    onCancelClick = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('VaccinationNextPopup'));
    }

    render() {
        const { vaccinationData } = this.state;
        let height = window.$(window).height();
        return (

            <div id="vaccinationNextPopup" className="ui-popup ui-page-theme-a ui-corner-all" style={{ maxHeight: height - "50" }}>
                <a href="#" className="ui-btn ui-corner-all ui-shadow ui-btn-a ui-icon-delete ui-btn-icon-notext ui-btn-right" onClick={this.onCancelClick}>
                </a>
                <div style={{ overflow: "scroll", maxHeight: height - "60", margin: "15px" }}>
                    {vaccinationData.length ?
                        vaccinationData.map(i => (
                            <div key={Math.uuid()} style={{ margin: "10px" }}>
                                <span style={{ marginLeft: "45%" }}><b>{i.horse}</b></span>
                                <table className="AllVaccinationTable">
                                    <tbody>
                                        <tr>
                                            <th>
                                                {window.lang.vaccinationType}
                                            </th>
                                            <th>
                                                Last Vaccination Date
                                            </th>
                                            <th>
                                                {window.lang.nextVaccination}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                {i.vaccinationType}
                                            </td>
                                            <td>
                                                {i.lastVaccination}
                                            </td>
                                            <td style={i.daysLeft > 0 ? { color: 'black' } : { color: 'red' }}>
                                                {i.nextVaccination}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={i.daysLeft > 0 ? { color: 'black' } : { color: 'red' }}>
                                                {window.lang.daysLeft + ': ' + i.daysLeft}
                                            </td>
                                            <td colSpan="2" style={i.vaccinationAllow.length ? { color: 'red', textAlign: "right" } : { color: 'black', textAlign: "right" }}>
                                                {i.vaccinationAllow}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))
                        : <></>
                    }
                </div>
            </div>
        )
    }
}

export default class VaccinationNextPopup {
    showVaccinationNextPopup() {
        ReactDOM.render(
            <VaccinationNextPopupWindow />,
            document.getElementById('VaccinationNextPopup')
        )
    }
}