import React, { Component } from "react";
import uniqueId from "lodash/uniqueId";
import ClickNHold from "react-click-n-hold";
import TrainingCellMap from "../TrainingCellMap";
import TrainingPopup from "../../TrainingPopup/TrainingPopup";
import "./TrainingDay.css";

class TrainingDay extends Component {
  renderAlphabetic = () => {
    const { trainings, date, horse, isSelectedDate, rider } = this.props;

    const columns = this.props.trainingCellMap.map[date.formatDesh()].columns;

    if (columns.length == 0)
      return (
        <TrainingCell
          holdEnabled={true}
          horse={this.props.horse}
          date={this.props.date}
          showRider={this.props.showRider}
        />
      );

    return (
      <table className="traningDayTable">
        <tbody>
          <tr>
            {columns.map((column) => (
              <td key={`${horse.id}&${date.formatDesh()}&${column.shortname}`}>
                {this.getTrainingCellsAlphabetic(column, rider)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  getTrainingCellsAlphabetic = (column, rider) => {
    const trainigs = this.props.trainings.filter(
      (t) => t.shortname == column.shortname
    );
    if (trainigs.length == 0) {
      return (
        <TrainingCell
          holdEnabled={true}
          horse={this.props.horse}
          column={column}
          date={this.props.date}
          showRider={this.props.showRider}
        />
      );
    }

    return (
      <div className="trainingCellContainer">
        {trainigs.map((t) => (
          <TrainingCell
            holdEnabled={true}
            key={t.id}
            training={t}
            horse={this.props.horse}
            date={this.props.date}
            showRider={this.props.showRider}
            rider={rider}
          />
        ))}
      </div>
    );
  };

  renderAlphabeticCompact = () => {
    const { trainings, date, horse, isSelectedDate, rider } = this.props;
    if (
      window.training.mode == window.TrainingManager.riderStatic ||
      window.training.mode == window.TrainingManager.riderStaticAndEquipment
    ) {
      let trainerColsArr =
        this.props.trainingCellMap.maxColumnCompactMap[date.formatDesh()];
      if (horse.trainer == null || horse.trainer == window.myid) {
        if (trainerColsArr[window.myid]) {
          if (trainerColsArr["null"]) {
            if (
              trainerColsArr["null"].columns >
              trainerColsArr[window.myid].columns
            )
              var columnCount = trainerColsArr["null"].columns;
            else var columnCount = trainerColsArr[window.myid].columns;
          } else {
            var columnCount = trainerColsArr[window.myid].columns;
          }
        } else {
          if (trainerColsArr["null"]) {
            var columnCount = trainerColsArr["null"].columns;
          } else {
            var columnCount = 1;
          }
        }
      } else if (trainerColsArr[rider]) {
        var columnCount = trainerColsArr[rider].columns;
      } else var columnCount = 1;
    } else {
      var columnCount =
        this.props.trainingCellMap.maxColumnCompactMap[date.formatDesh()];
    }

    const allColumns =
      this.props.trainingCellMap.map[date.formatDesh()].columns;
    const shortColumns = [];
    for (let ci = 0; ci < allColumns.length; ci++) {
      //build current trainings
      const tByType = trainings.filter(
        (t) => t.shortname == allColumns[ci].shortname
      );
      if (tByType.length != 0) {
        shortColumns.push(tByType);
      }
    }

    for (let ci = shortColumns.length; ci < columnCount; ci++) {
      //add empty to columnCount
      shortColumns.push([]);
    }

    return (
      <table className="traningDayTable">
        <tbody>
          <tr>
            {shortColumns.map((trainigs) => (
              <td key={`${horse.id}&${date.formatDesh()}&${uniqueId()}`}>
                {/*key*/}
                {trainigs.length != 0 ? (
                  <div className="trainingCellContainer">
                    {trainigs.map((t) => (
                      <TrainingCell
                        holdEnabled={true}
                        key={t.id}
                        training={t}
                        horse={this.props.horse}
                        date={this.props.date}
                        showRider={this.props.showRider}
                        rider={rider}
                      />
                    ))}
                  </div>
                ) : (
                  <TrainingCell
                    holdEnabled={true}
                    horse={this.props.horse}
                    date={this.props.date}
                    showRider={this.props.showRider}
                  />
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  renderTimeTop = () => {
    const { date, horse, rider } = this.props;
    const columns = this.props.trainingCellMap.map[date.formatDesh()].columns;

    if (columns.length == 0)
      return (
        <TrainingCell
          holdEnabled={true}
          horse={horse}
          date={date}
          showRider={this.props.showRider}
        />
      );

    return (
      <table className="traningDayTable">
        <tbody>
          <tr>
            {columns.map((column) => (
              <td
                key={`${horse.id}&${date.formatDesh()}&${
                  column.isTimeColumn ? column.TimeOfDay : column.shortname
                }`}
              >
                {this.getTrainingCellsTime(column, rider)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  getTrainingCellsTime = (column, rider) => {
    let trainigs = [];
    if (column.isTimeColumn) {
      trainigs = this.props.trainings.filter(
        (t) => t.TimeOfDay == column.TimeOfDay
      );
    } else {
      trainigs = this.props.trainings.filter(
        (t) =>
          t.shortname == column.shortname &&
          (t.TimeOfDay == null || t.TimeOfDay == "")
      );
    }

    if (trainigs.length == 0) {
      return (
        <TrainingCell
          holdEnabled={true}
          horse={this.props.horse}
          column={column}
          date={this.props.date}
          showRider={this.props.showRider}
        />
      );
    }

    return (
      <div className="trainingCellContainer">
        {trainigs.map((t) => (
          <TrainingCell
            holdEnabled={true}
            key={t.id}
            training={t}
            horse={this.props.horse}
            date={this.props.date}
            showRider={this.props.showRider}
            rider={rider}
          />
        ))}
      </div>
    );
  };

  renderTimeTopCompact = () => {
    const { trainings, date, horse, isSelectedDate, rider } = this.props;
    if (
      window.training.mode == window.TrainingManager.riderStatic ||
      window.training.mode == window.TrainingManager.riderStaticAndEquipment
    ) {
      let trainerColsArr =
        this.props.trainingCellMap.maxColumnCompactMap[date.formatDesh()];
      if (horse.trainer == null || horse.trainer == window.myid) {
        if (trainerColsArr[window.myid]) {
          if (trainerColsArr["null"]) {
            if (
              trainerColsArr["null"].columns >
              trainerColsArr[window.myid].columns
            )
              var columnCount = trainerColsArr["null"].columns;
            else var columnCount = trainerColsArr[window.myid].columns;
          } else {
            var columnCount = trainerColsArr[window.myid].columns;
          }
        } else {
          if (trainerColsArr["null"]) {
            var columnCount = trainerColsArr["null"].columns;
          } else {
            var columnCount = 1;
          }
        }
      } else if (trainerColsArr[rider]) {
        var columnCount = trainerColsArr[rider].columns;
      } else var columnCount = 1;
    } else {
      var columnCount =
        this.props.trainingCellMap.maxColumnCompactMap[date.formatDesh()];
    }

    const allColumns =
      this.props.trainingCellMap.map[date.formatDesh()].columns;
    const shortColumns = [];
    for (let ci = 0; ci < allColumns.length; ci++) {
      //build current trainings
      const tByColumn = allColumns[ci].isTimeColumn
        ? trainings.filter((t) => t.TimeOfDay == allColumns[ci].TimeOfDay)
        : trainings.filter(
            (t) =>
              t.shortname == allColumns[ci].shortname &&
              (t.TimeOfDay == null || t.TimeOfDay == "")
          );
      if (tByColumn.length != 0) {
        shortColumns.push(tByColumn);
      }
    }

    for (let ci = shortColumns.length; ci < columnCount; ci++) {
      //add empty to columnCount
      shortColumns.push([]);
    }

    return (
      <table className="traningDayTable">
        <tbody>
          <tr>
            {shortColumns.map((trainigs) => (
              <td key={`${horse.id}&${date.formatDesh()}&${uniqueId()}`}>
                {/*key*/}
                {trainigs.length != 0 ? (
                  <div className="trainingCellContainer">
                    {trainigs.map((t) => (
                      <TrainingCell
                        holdEnabled={true}
                        key={t.id}
                        training={t}
                        horse={this.props.horse}
                        date={this.props.date}
                        showRider={this.props.showRider}
                        rider={rider}
                      />
                    ))}
                  </div>
                ) : (
                  <TrainingCell
                    holdEnabled={true}
                    horse={this.props.horse}
                    date={this.props.date}
                    showRider={this.props.showRider}
                  />
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  render() {
    switch (this.props.trainingCellMap.orderBy) {
      case TrainingCellMap.ORDER_BY_ALPHABETIC:
        return this.renderAlphabetic();
      case TrainingCellMap.ORDER_BY_ALPHABETIC_COMPACT:
        return this.renderAlphabeticCompact();
      case TrainingCellMap.ORDER_BY_TIME_TOP:
      case TrainingCellMap.ORDER_BY_TIME_ALPHABETIC:
        return this.renderTimeTop();
      case TrainingCellMap.ORDER_BY_TIME_TOP_COMPACT:
        return this.renderTimeTopCompact();
    }
    return (
      <TrainingCell
        holdEnabled={true}
        horse={this.props.horse}
        date={this.props.date}
        showRider={this.props.showRider}
      />
    );
  }
}

export default TrainingDay;

export class TrainingCell extends Component {
  onCellClick = (e) => {
    window.training.positionLastTimeClick = { x: e.screenX, y: e.screenY };
    let training = this.props.training;

    let trainType = null;
    let trainerId = null;
    let trainerName = null;
    if (
      this.props.callbackAdd != null &&
      this.props.useOnlyAddCallback &&
      training == null
    ) {
      this.props.callbackAdd();
      return;
    }
    let currentTrainerId = window.DataStorage.getAllHorses().find(
      (i) => i.id == this.props.horse.id
    ).trainer;
    if (!currentTrainerId)
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == window.myid
      );
    else
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == currentTrainerId
      );
    trainerId = currentTrainer.Id;
    trainerName = currentTrainer.Name;
    if (training != null) {
      trainType = training.type;
      trainerId = training.trainer;
      trainerName = training.trainername;
    } else if (this.props.fieldAssignInfo || this.props.walkerAssignInfo) {
      let horse = window.DataStorage.getAllHorses().find(
        (h) => h.id == this.props.horse.id
      );
      let trainingTypes = window.DataStorage.trainTypes.filter(
        (i) => i.owner == horse.userid
      );
      if (this.props.fromWalkerAssignmentView) {
        trainType = trainingTypes.find((tt) => this.isWalker(tt.name)).id;
      } else {
        trainType = trainingTypes.find((tt) => this.isField(tt.name)).id;
      }
    } else if (this.props.column != null) {
      trainType = this.props.column.typeIds[0];
    }

    let item = {
      date: this.props.date,
      horseName: this.props.horse.name,
      training: this.props.training,
      trainId: training != null ? training.id : null,
    };

    let options = {
      horseId: this.props.horse.id,
      trainingType: trainType,
      date: this.props.date,
      trainerId: trainerId,
      trainerName: trainerName,
      fieldAssignInfo: this.props.fieldAssignInfo,
      walkerAssignInfo: this.props.walkerAssignInfo,
    };

    let trainingPopup = new TrainingPopup();
    trainingPopup.showPopup(options, this.props.training, () => {});
  };

  initNewPermanent = () => {
    let $ = window.$;
    var onInitDone = function () {
      if ($("#dates2 li").length != 0) {
        $(`#dates2 li[idf="${window.myid}"] a`).click();
      }
      if ($(".tcTrainer option").length != 0) {
        $(".tcTrainer").val(window.myid);
      }
      $(".tcColor").prop("checked", true);
    };
    var waitForInit = function () {
      if (window.trainingPopup.isTrainerLoading) {
        setTimeout(waitForInit, 200);
      } else {
        onInitDone();
      }
    };
    waitForInit();
  };

  getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");

    return hours + ":" + minutes;
  }
  onCellLongClick = (e) => {
    const { holdEnabled } = this.props;
    if (!holdEnabled) return;
    const { setTimeOnFirstHold, training } = this.props;
    if (setTimeOnFirstHold && training.TimeOfDay == "") {
      let training_aux = { ...training };
      training_aux.TimeOfDay = this.getCurrentTime();
      window.DataStorage.updateTrainingBackground(training_aux);
      return;
    }

    let training_aux = { ...training };
    training_aux.color = training.color == "red" ? "green" : "red";

    window.DataStorage.updateTrainingBackground(training_aux);
  };
  isField = (name) => {
    if (!name) return false;
    name = name.toLowerCase();

    return name == "field" || name == "fold" || name == "feld";
  };

  isWalker = (name) => {
    if (!name) return false;
    name = name.toLowerCase();

    return name == "walker";
  };
  getFieldInfo = () => {
    const { training } = this.props;
    const field_info = {
      field_area_id: training?.FieldAreaId,
      field_id: training?.FieldId,
      time_id: training?.FieldAreaTimePeriodId,
    };
    return field_info;
  };
  getWalkerInfo = () => {
    const { training } = this.props;
    const walker_info = {
      walker_area_id: training?.WalkerAreaId,
      walker_id: training?.WalkerId,
      time_id: training?.WalkerAreaTimePeriodId,
    };
    return walker_info;
  };
  handleDragStart = (e) => {
    e.dataTransfer.setData("training", JSON.stringify(this.props.training));

    const field_info = this.getFieldInfo();
    const walker_info = this.getWalkerInfo();
    e.dataTransfer.setData("field_info", JSON.stringify(field_info));
    e.dataTransfer.setData("walker_info", JSON.stringify(walker_info));
  };

  handleDrop = (e) => {
    e.preventDefault();

    let source_training = JSON.parse(e.dataTransfer.getData("training"));

    if (this.isField(source_training.name)) {
      let source_field_info_str = e.dataTransfer.getData("field_info");
      let target_field_info_str = JSON.stringify(
        this.props.fieldAssignInfo || this.getFieldInfo()
      );
      let target_training = this.props.training;
      let target_field_info = this.props.fieldAssignInfo;

      if (!source_training) {
        return;
      }

      if (target_field_info_str == source_field_info_str) return;

      if (!target_training) {
        let training_aux = { ...source_training };
        training_aux.FieldAreaId = target_field_info.field_area_id;
        training_aux.FieldId = target_field_info.field_id;
        training_aux.FieldAreaTimePeriodId = target_field_info.time_id;

        window.DataStorage.updateTrainingBackground(training_aux);
        return;
      }

      if (source_training?.id == target_training?.id) return;
      let swapPayload = {
        source_training_id: source_training?.id,
        target_training_id: target_training?.id,
      };

      // don't swap if they are in the same field/time

      window.DataStorage.swapFieldsOfTrainingBackground(swapPayload);
    } else if (this.isWalker(source_training.name)) {
      let source_walker_info_str = e.dataTransfer.getData("walker_info");

      let target_walker_info_str = JSON.stringify(
        this.props.walkerAssignInfo || this.getWalkerInfo()
      );
      let target_training = this.props.training;
      let target_walker_info = this.props.walkerAssignInfo;

      if (!source_training) {
        return;
      }

      if (target_walker_info_str == source_walker_info_str) return;

      if (!target_training) {
        let training_aux = { ...source_training };
        training_aux.WalkerAreaId = target_walker_info.walker_area_id;
        training_aux.WalkerId = target_walker_info.walker_id;
        training_aux.WalkerAreaTimePeriodId = target_walker_info.time_id;

        window.DataStorage.updateTrainingBackground(training_aux);
        return;
      }

      if (source_training?.id == target_training?.id) return;
      let swapPayload = {
        source_training_id: source_training?.id,
        target_training_id: target_training?.id,
      };

      // don't swap if they are in the same field/time

      window.DataStorage.swapWalkersOfTrainingBackground(swapPayload);
    } else {
      return;
    }
  };

  handleDragOver = (e) => {
    e.preventDefault();
  };

  render() {
    const { training, column, horse, date, showRider, rider, showHorseName } =
      this.props;
    if (training == null || !horse) {
      return (
        <div
          className={
            this.props.multipleOnSameColumn
              ? "trainingCell trainingCellBorder"
              : "trainingCell"
          }
          draggable
          onDragStart={(e) => horse && this.handleDragStart(e)}
          onDrop={(e) => horse && this.handleDrop(e)}
          onDragOver={horse && this.handleDragOver}
          style={{ width: "100%" }}
          onClick={horse && this.onCellClick}
        >
          <div className="trainingCellName">-</div>
        </div>
      );
    }

    let riderInitials = null;
    if (showRider) {
      if (
        window.training.mode == window.TrainingManager.riderStatic ||
        window.training.mode ==
          window.TrainingManager.riderStaticAndEquipment ||
        window.training.mode == window.TrainingManager.riderStaticLight ||
        window.training.mode ==
          window.TrainingManager.riderStaticAndEquipmentLight
      ) {
        if (rider != training.trainer)
          riderInitials = window.getInitials(training.trainername);
      } else riderInitials = window.getInitials(training.trainername);
    }
    let timeText = null;
    if (training.TimeOfDay != null && training.TimeOfDay != "") {
      timeText = window.training.getTimeOfDayTitle(training.TimeOfDay);
    }
    if (timeText == "0" || timeText == " ") {
      timeText = null;
    }

    let nameClass =
      timeText != null || training.remark != ""
        ? "trainingCellNameSmall"
        : "trainingCellName";

    // if (!showHorseName)
    nameClass +=
      training.color == "red"
        ? timeText && this.props.fromFieldAssignmentView
          ? " trainingCellNotDoneTimeSet"
          : " trainingCellNotDone"
        : " trainingCellDone";
    return (
      <div
        draggable={this.props.draggable}
        onDragStart={(e) => this.handleDragStart(e)}
        onDrop={(e) => this.handleDrop(e)}
        onDragOver={this.handleDragOver}
      >
        <ClickNHold
          time={1.5}
          onClickNHold={this.onCellLongClick}
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className={
              this.props.multipleOnSameColumn
                ? "trainingCell trainingCellBorder"
                : "trainingCell"
            }
            onClick={this.onCellClick}
          >
            {timeText != null && (
              <div className="trainingCellTime">{timeText}</div>
            )}
            <div className={nameClass}>
              {showHorseName ? horse.name : training.shortname}
              {/* {training.remark != "" && <>*</>} */}
              {riderInitials != null && <>({riderInitials})</>}
            </div>
            {this.isField(training.name) &&
              !this.props.fromFieldAssignmentView && (
                <div className="trainingCellNotes">
                  <span>
                    {training.FieldAssignmentFieldAreaName}{" "}
                    {training.FieldAssignmentFieldName && "- "}
                    {training.FieldAssignmentFieldName}{" "}
                    {training.FieldAssignmentFieldTimePeriod && "- "}
                    {training.FieldAssignmentFieldTimePeriod}
                  </span>
                </div>
              )}

            {this.isWalker(training.name) &&
              !this.props.fromWalkerAssignmentView && (
                <div className="trainingCellNotes">
                  <span>
                    {training.WalkerAssignmentWalkerAreaName}{" "}
                    {training.WalkerAssignmentWalkerName && "- "}
                    {training.WalkerAssignmentWalkerName}{" "}
                    {training.WalkerAssignmentWalkerTimePeriod && "- "}
                    {training.WalkerAssignmentWalkerTimePeriod}
                  </span>
                </div>
              )}
            {training.remark != "" && (
              <div className="trainingCellNotes">{training.remark}</div>
            )}
          </div>
        </ClickNHold>
      </div>
    );
  }
}
