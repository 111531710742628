import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./RiderRole.css";
import PermissionsPage from "../RiderRole/PermissionsPage";

class RiderRolePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRole: "",
      rolesArr: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.db.getRiderRoles(window.myid, (data) => {
      data = data.sort(this.sortRolesArr);
      this.setState({
        rolesArr: data,
      });
    });
  };

  sortRolesArr = (a, b) => {
    if (a.OrderC < b.OrderC) return -1;
    if (a.OrderC > b.OrderC) return 1;
    return 0;
  };

  onExitClick = () => {
    window.showPage("#sharingPage");
    window.managers.extention.showSharingPage();
  };

  onNewRoleInputChange = (e) => {
    this.setState({
      newRole: e.target.value,
    });
  };

  onAddNewRoleClick = () => {
    window.Spinner.show();
    window.DataGate.createRiderRole(
      Math.uuid(),
      window.myid,
      this.state.newRole,
      () => {
        window.Spinner.hide();
        this.setState(
          {
            newRole: "",
          },
          this.loadData()
        );
      }
    );
  };

  onDeleteRoleClick = (e) => {
    window.Spinner.show();
    window.DataGate.deleteRiderRole(e.currentTarget.dataset.id, () => {
      window.Spinner.hide();
      this.loadData();
    });
  };

  onEditClick = (e) => {
    window.showPage("#permissionsPageReact");
    let permissionsPage = new PermissionsPage();
    permissionsPage.showPermissions(e.currentTarget.dataset.id);
  };

  onOrderDownClick = (item, itemIndex) => {
    if (itemIndex >= this.state.rolesArr.length - 1) return;

    window.DataGate.swipeRiderRoleOrder(
      this.state.rolesArr[itemIndex].Id,
      this.state.rolesArr[itemIndex + 1].Id,
      () => {
        this.loadData();
      }
    );
  };

  onOrderUpClick = (item, itemIndex) => {
    if (itemIndex <= 0) return;

    window.DataGate.swipeRiderRoleOrder(
      this.state.rolesArr[itemIndex].Id,
      this.state.rolesArr[itemIndex - 1].Id,
      () => {
        this.loadData();
      }
    );
  };

  render() {
    const { newRole, rolesArr } = this.state;
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onExitClick}
          />
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onExitClick}
          />
        </div>
        <div className="ppContent">
          {rolesArr.length ? (
            <table className="roleTable">
              <tbody>
                <tr style={{ fontWeight: "bold" }}>
                  <td>Name</td>
                  <td>Edit</td>
                  <td>Delete</td>
                  <td colSpan="2">Order</td>
                </tr>
                {rolesArr.map((item, itemIndex) => (
                  <tr key={item.Id}>
                    <td style={{ width: "60%" }}>{item.Name}</td>
                    <td style={{ width: "10%" }}>
                      <button
                        className="ui-btn ui-corner-all npButton"
                        style={{ margin: "auto" }}
                        data-id={item.Id}
                        onClick={this.onEditClick}
                      >
                        Edit
                      </button>
                    </td>
                    <td style={{ width: "10%" }}>
                      <button
                        className="ui-btn ui-corner-all npButton"
                        style={{ margin: "auto" }}
                        data-id={item.Id}
                        onClick={this.onDeleteRoleClick}
                      >
                        Delete
                      </button>
                    </td>
                    <td style={{ width: "10%" }}>
                      <img
                        loading="lazy"
                        className="ttOrderDown"
                        src="css/images/icons-png/arrow-d-black.png"
                        data-id={item.Id}
                        onClick={() => this.onOrderDownClick(item, itemIndex)}
                      />
                    </td>
                    <td style={{ width: "10%" }}>
                      <img
                        loading="lazy"
                        className="ttOrderUp"
                        src="css/images/icons-png/arrow-u-black.png"
                        data-id={item.Id}
                        onClick={() => this.onOrderUpClick(item, itemIndex)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"
                  style={{ width: "70%" }}
                >
                  <input
                    type="text"
                    value={newRole}
                    onChange={this.onNewRoleInputChange}
                  />
                </td>
                <td style={{ width: "30%" }}>
                  <button
                    className="ui-btn ui-corner-all npButton"
                    onClick={this.onAddNewRoleClick}
                  >
                    Add Role
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default class RiderRole {
  showRiderRolePage() {
    ReactDOM.render(
      <RiderRolePage />,
      document.getElementById("riderRolePageReact")
    );
  }
}
