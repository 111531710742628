import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class DeleteUpdateGeneralTaskPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDelete: false,
            task: ''
        };
    }

    componentDidMount = () => {
        this.setState({
            isDelete: this.props.isDelete,
            task: this.props.task
        });
    }

    onCancelClick = () => {
        if (this.state.isDelete) {
            this.deleteGeneralTaskGroup(this.state.task);
        } else {
            this.updateGeneralTask(this.state.task, true);
        }

        if (this.props.doneCallback)
            this.props.doneCallback();
        this.hide();
    }

    onOkClick = () => {
        if (this.state.isDelete) {
            window.DataStorage.deleteGeneralTaskById(this.state.task.Id)
            window.training.refreshLayout();
            let userForId = this.state.task.UserForId;
            window.DataGate.deleteGeneralTask(this.state.task.Id, userForId, () => {
                window.training.refresh({ showSpinner: false });
            });
        } else {
            this.updateGeneralTask(this.state.task, false);
        }

        if (this.props.doneCallback)
            this.props.doneCallback();
        this.hide();
    }

    hide = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('updDelGeneralTaskDivReact'));
    }

    deleteGeneralTaskGroup = (task) => {
        task["ConnectionId"] = window.notification.connectionId;
        window.DataGate.deleteGeneralTaskGroup(task, (code, data) => {
            if (code == window.DataGate.CODE_OK) {
                window.training.refresh({ showSpinner: false });
            }
            if (this.props.callback) {
                this.props.callback(code == window.DataGate.CODE_OK);
            }
            window.Spinner.hide();
        });
    }

    updateGeneralTask = (generalTask, isForAllGroup) => {
        if (!isForAllGroup) {
            generalTask.Flag = window.DataStorage.GeneralTaskFlagUpdate;
            window.DataStorage.addGeneralTask(generalTask, window.DataStorage.GeneralTaskFlagAdd);
        }

        window.DataGate.insertOrUpdateGeneralTaskGroup(generalTask, isForAllGroup, (code, data) => {
            if (code == window.DataGate.CODE_OK) {
                window.training.refresh({ showSpinner: false });
            }
            if (this.props.callback) {
                this.props.callback(code == window.DataGate.CODE_OK);
            }
        });
    }

    render() {
        return (
            <div className="copy-task ui-page-theme-a" style={{ display: 'block' }}>
                <table>
                    {this.state.isDelete ?
                        <tbody>
                            <tr>
                                <td>
                                    <div id="tsWPText1">{window.lang.thisTaskIsPartOfaSeries + ' ' + window.lang.doYouWantTo + ':'}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right' }}>
                                    <button id="tsWPOkButton" className=' ui-btn ui-shadow ui-corner-all' style={{ marginBottom: '0px' }} onClick={this.onOkClick}>Delete this task only</button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right' }}>
                                    <button id="tsWPCancelButton" className=' ui-btn ui-shadow ui-corner-all' style={{ margin: '0px' }} onClick={this.onCancelClick}>Delete all tasks in this serie</button>
                                </td>
                            </tr>
                        </tbody>
                        :
                        <tbody>
                            <tr>
                                <td>
                                    <div id="tsWPText1">{window.lang.thisTaskIsPartOfaSeries + ' ' + window.lang.doYouWantTo + ':'}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right' }}>
                                    <button id="tsWPOkButton" className=' ui-btn ui-shadow ui-corner-all' style={{ marginBottom: '0px' }} onClick={this.onOkClick}>{window.lang.changeThisTaskOnly}</button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right' }}>
                                    <button id="tsWPCancelButton" className=' ui-btn ui-shadow ui-corner-all' style={{ margin: '0px' }} onClick={this.onCancelClick}>{window.lang.changeAllTasksInThisSerie}</button>
                                </td>
                            </tr>
                        </tbody>
                    }
                </table>
            </div>
        )
    }
}

export default class DeleteUpdateGeneralTaskPopupWindow {
    showWindow(task, isDelete, doneCallback) {
        ReactDOM.render(
            <DeleteUpdateGeneralTaskPopup
                task={task}
                isDelete={isDelete}
                doneCallback={doneCallback}
            />,
            document.getElementById('updDelGeneralTaskDivReact')
        )
    }
}