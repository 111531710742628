import React, { Component } from "react";

import ToggleView from "../../Controls/ToggleView/ToggleView";
import TrainingPopup from "../../TrainingPopup/TrainingPopup";
import ZipViewer from "../../ZipViewer/ZipViewer";
import MultimediaHelper from "./../../Multimedia/MultimediaHelper";
import "./VeterinarianPage.css";

import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "react-pdf/dist/esm/pdf.worker.entry";
//import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export default class VeterinarianPage extends Component {
  static DropboxToken =
    "HqmkA9nKm0wAAAAAAADLWpj-stRJN_4JyfLxtRoZD-Rzpjyz9nnuI_CMbkRkOoSk";

  constructor(props) {
    super(props);

    this.state = {
      dbxFullScreenItem: null,
      showFullScreenView: false,
      showFullScreenItemIndex: 0,
      showFullScreenLoading: false,
      vetDataItemIndex: 0,
      multiSelectMode: false,
      veterinarianData: [],
      showPdfDocument: null,
      currentPdfPage: 1,
      pdfNumberOfPages: 1,
      showZipViewer: null,
    };
    this.dropbox = new window.Dropbox.Dropbox({
      accessToken: VeterinarianPage.DropboxToken,
    });

    console.log(pdfjsWorker);

    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; //`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.pdfPageWidth = window.isMobileDevice()
      ? window.innerWidth - 40
      : Math.min(window.innerWidth - 40, 800);
  }

  componentDidMount() {
    window.$("#bottomMenu").hide();
    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  loadData = () => {
    window.Spinner.show();
    window.db.getVeterinarianTrainingsByHorse(
      this.props.horseId,
      window.myid,
      new Date().formatDeshStartDay(),
      (vetData) => {
        if (vetData.length) {
          vetData = vetData.sort((first, second) => {
            if (first["starttime"] == second["starttime"])
              return first.synctime - second.synctime;
            return first["starttime"] < second["starttime"] ? 1 : -1;
          });
          this.setState(
            {
              veterinarianData: vetData,
            },
            window.Spinner.hide()
          );

          let horse = window.DataStorage.getAllHorses().find(
            (h) => h.id == this.props.horseId
          );
          for (let i = 0; i < vetData.length; i++) {
            this.helper = new MultimediaHelper(
              "Task",
              horse.id,
              horse.userid,
              null,
              vetData[i].id
            );
            this.helper.loadList(document.location.href, (data) => {
              //this.setState({
              //    veterinarianData: vetData
              //}, window.Spinner.hide());
              // console.log(data);
              this.path = `xray-${vetData[i].id}`;
              this.helper
                .listBlobsWithProperties(this.path)
                .then((response) => {
                  console.log(response);
                  let plusFiles = [];
                  if (data != null) {
                    plusFiles = [...plusFiles, ...data];
                    //vetData[i].multimedia = data;
                  }
                  if (response.length) {
                    plusFiles = [...plusFiles, ...response];
                    //vetData[i].plusFiles = response.entries;
                  }
                  vetData[i].plusFiles = plusFiles;
                  vetData[i].multimedia = [];
                  this.setState(
                    {
                      veterinarianData: vetData,
                    },
                    window.Spinner.hide()
                  );
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          }
        } else window.Spinner.hide();
      }
    );
  };

  getVeterinarTypeName = (index) => {
    for (var i = 0; i < window.VeterinarPage.VeterinarType.length; i++) {
      if (window.VeterinarPage.VeterinarType[i]["value"] == index) {
        var name = window.VeterinarPage.VeterinarType[i]["Name"];
        break;
      } else {
        var name = window.VeterinarPage.VeterinarType[0]["Name"];
      }
    }
    return name;
  };

  onNewVetereinarianClick = () => {
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    let trainingTypes = window.DataStorage.trainTypes.filter(
      (i) => i.owner == horse.userid
    );
    let vetTraining = trainingTypes.filter((i) => i.IsVeterinarian == true);
    let currentTrainerId = window.DataStorage.getAllHorses().find(
      (i) => i.id == this.props.horseId
    ).trainer;
    if (!currentTrainerId)
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == window.myid
      );
    else
      var currentTrainer = window.DataStorage.allUsers.find(
        (i) => i.Id == currentTrainerId
      );
    let options = {
      horseId: horse.id,
      trainingType: vetTraining[0].id,
      date: new Date(),
      isDone: false,
      isVetPage: true,
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    let trainingPopup = new TrainingPopup();
    trainingPopup.showPopup(
      options,
      null,
      () => {
        //this.loadData(); // ToDo update loadData
      },
      (item) => {
        let newVetData = this.state.veterinarianData;
        newVetData.push(item);
        newVetData = newVetData.sort((first, second) => {
          if (first["starttime"] == second["starttime"])
            return first.synctime - second.synctime;
          return first["starttime"] < second["starttime"] ? 1 : -1;
        });
        this.setState({
          veterinarianData: newVetData,
        });
      }
    );
  };

  onDoneToggle = (item) => {
    item.color = item.color == "green" ? "red" : "green";
    let veterinarianData = [...this.state.veterinarianData];
    let index = veterinarianData.findIndex((t) => t.id == item.id);
    veterinarianData[index] = item;
    this.setState({
      veterinarianData: veterinarianData,
    });
    let trainingItem = window.DataStorage.addTrainingArr.find(
      (d) => d.id == item.id
    );
    if (trainingItem == null) {
      trainingItem = this.state.veterinarianData.find((d) => d.id == item.id);
    }
    trainingItem.color = item.color;
    window.DataStorage.updateTrainingBackground(trainingItem);
  };

  onEditClick = (id) => {
    let trainingItem = window.DataStorage.addTrainingArr.find(
      (d) => d.id == id
    );
    if (trainingItem == null) {
      trainingItem = this.state.veterinarianData.find((d) => d.id == id);
    }
    var currentTrainer = window.DataStorage.allUsers.find(
      (i) => i.Id == trainingItem.trainer
    );
    const options = {
      horseId: trainingItem.horseid,
      trainingType: trainingItem.type,
      date: new Date(trainingItem.starttime),
      isVetPage: true,
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    let trainingPopup = new TrainingPopup();
    trainingPopup.showPopup(
      options,
      trainingItem,
      () => {
        // this.loadData(); // ToDo update loadData
      },
      (item) => {
        let index = this.state.veterinarianData.findIndex((i) => i.id == id);
        let newVetData = [...this.state.veterinarianData];
        newVetData[index] = item;
        newVetData = newVetData.sort((first, second) => {
          if (first["starttime"] == second["starttime"])
            return first.synctime - second.synctime;
          return first["starttime"] < second["starttime"] ? 1 : -1;
        });
        this.setState({
          veterinarianData: newVetData,
        });
      }
    );
  };

  onDeleteClick = (id) => {
    let newVetData = this.state.veterinarianData.filter((i) => i.id != id);
    this.setState({
      veterinarianData: newVetData,
    });
    window.db.deleteTrainingCallBack(id, () => {
      //this.loadData(); // ToDo update loadData
    });
  };

  onSaveClick = () => {
    window.Managers.extention.showHorsePage(this.props.horseId);
    window.$("#bottomMenu").show();
  };

  showFullscreen = (index, itemIndex) => {
    let item = this.state.veterinarianData[index].multimedia[itemIndex];
    if (item.fullUrl == null) {
      this.setState({
        showFullScreenView: true,
        vetDataItemIndex: index,
        showFullScreenItemIndex: itemIndex,
        showFullScreenLoading: true,
      });
      this.helper.getItemLink(item, (url) => {
        this.setState({
          showFullScreenView: true,
          vetDataItemIndex: index,
          showFullScreenItemIndex: itemIndex,
          showFullScreenLoading: false,
        });
      });
    } else {
      this.setState({
        showFullScreenView: true,
        vetDataItemIndex: index,
        showFullScreenItemIndex: itemIndex,
        showFullScreenLoading: false,
      });
    }
  };

  showFullscreenRight = () => {
    const index = this.state.showFullScreenItemIndex + 1;
    const vetIndex = this.state.vetDataItemIndex;
    if (this.state.veterinarianData[vetIndex].multimedia)
      if (index < this.state.veterinarianData[vetIndex].multimedia.length) {
        this.showFullscreen(this.state.vetDataItemIndex, index);
      }
  };

  showFullscreenLeft = () => {
    const index = this.state.showFullScreenItemIndex - 1;
    if (index >= 0) {
      this.showFullscreen(this.state.vetDataItemIndex, index);
    }
  };

  hideFullscreen = () => {
    this.setState({
      showFullScreenView: false,
    });
  };

  renderFullscreenItem = () => {
    const item =
      this.state.veterinarianData[this.state.vetDataItemIndex].plusFiles[
        this.state.showFullScreenItemIndex
      ];
    switch (item.type) {
      case 1:
        return (
          <img loading="lazy" src={item.fullUrl} className="hgFullScreenItem" />
        );
      case 2:
        return (
          <video controls="controls" className="hgFullScreenItem">
            <source src={item.fullUrl} type="video/mp4" />
          </video>
        );
    }
    return <div></div>;
  };

  toggleExtraButtons = () => {
    this.setState({
      multiSelectMode: !this.state.multiSelectMode,
    });
  };

  selectMultimedia = (index, itemIndex) => {
    let veterinarianData = this.state.veterinarianData;
    veterinarianData[index].multimedia[itemIndex].isSelected =
      !veterinarianData[index].multimedia[itemIndex].isSelected;
    this.setState({
      veterinarianData: veterinarianData,
    });
  };

  selectFile = (index, itemIndex) => {
    let veterinarianData = this.state.veterinarianData;
    veterinarianData[index].plusFiles[itemIndex].isSelected =
      !veterinarianData[index].plusFiles[itemIndex].isSelected;
    this.setState({
      veterinarianData: veterinarianData,
    });
  };

  onDownloadClick = () => {
    //@todo
    let multimediaItems = JSON.parse(
      JSON.stringify(
        this.state.veterinarianData.filter(
          (i) => i.multimedia && i.multimedia.length
        )
      )
    );
    let selectedMultimediaItems = multimediaItems
      .map((j) => j.multimedia)
      .flat()
      .filter((i) => i.isSelected);
    let plusFilesItems = JSON.parse(
      JSON.stringify(this.state.veterinarianData.filter((i) => i.plusFiles))
    );
    let selectedPlusFilesItems = plusFilesItems
      .map((j) => j.plusFiles)
      .flat()
      .filter((i) => i.isSelected);
    this.setState({
      multiSelectMode: false,
    });

    // if (selectedMultimediaItems.length != 0) {
    //   this.helper.downloadItems(selectedMultimediaItems, () => {});
    // }
    if (selectedPlusFilesItems.length <= 0) return;
    const imagesToDownload = selectedPlusFilesItems.map((el) => {
      return { url: el.url, name: el.name };
    });
    if (imagesToDownload.length < 5) {
      MultimediaHelper.downloadFilesOneByOne(imagesToDownload);
    } else {
      MultimediaHelper.downloadZip(imagesToDownload);
    }
    // console.log(selectedPlusFilesItems);
    // // return;
    // for (let i in selectedPlusFilesItems) {
    //   const url = i.url;
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", decodeURI(selectedPlusFilesItems[i].name));
    //   document.body.appendChild(link);
    //   link.click();
    // }
  };

  onShareClick = () => {
    let plusFilesItems = JSON.parse(
      JSON.stringify(this.state.veterinarianData.filter((i) => i.plusFiles))
    );
    let selectedPlusFilesItems = plusFilesItems
      .map((j) => j.plusFiles)
      .flat()
      .filter((i) => i.isSelected);
    this.setState({
      multiSelectMode: false,
    });

    console.log(selectedPlusFilesItems);
    const helper = new MultimediaHelper(
      MultimediaHelper.FOLDER_OTHER,
      null,
      null,
      null,
      null
    );
    helper.downloadAndShareItems(selectedPlusFilesItems);
  };

  onShareFullscreenItem = () => {
    const helper = new MultimediaHelper(
      MultimediaHelper.FOLDER_OTHER,
      null,
      null,
      null,
      null
    );
    helper.downloadAndShareItems([this.state.dbxFullScreenItem]);
  };

  onViewDocumentClick = (index, itemIndex) => {
    let dataArr = this.state.veterinarianData;
    let item = dataArr[index].plusFiles[itemIndex];
    // this.dropbox
    //   .filesGetTemporaryLink({ path: item.path_display })
    //   .then((response) =>
    let response = item;
    // return;
    try {
      let currentItem = response;
      let ext = currentItem.name.split(".").pop().toLowerCase();
      if (
        ext == "png" ||
        ext == "jpeg" ||
        ext == "jpg" ||
        ext == "gif" ||
        ext == "bmp"
      ) {
        item.type = 1;
        item.fullUrl = response.url;
        this.setState({
          veterinarianData: dataArr,
          showFullScreenView: true,
          showFullScreenLoading: false,
          vetDataItemIndex: index,
          showFullScreenItemIndex: itemIndex,
          dbxFullScreenItem: currentItem,
        });
      } else if (ext == "mp4" || ext == "mov" || ext == "wmv" || ext == "avi") {
        item.type = 2;
        item.fullUrl = response.url;
        this.setState({
          veterinarianData: dataArr,
          showFullScreenView: true,
          showFullScreenLoading: false,
          vetDataItemIndex: index,
          showFullScreenItemIndex: itemIndex,
          dbxFullScreenItem: currentItem,
        });
      } else if (ext == "pdf") {
        this.setState({
          showPdfDocument: response.url,
          currentPdfPage: 1,
          pdfNumberOfPages: 1,
          dbxFullScreenItem: currentItem,
        });
      } else if (ext == "zip") {
        this.setState({
          showZipViewer: response.url,
        });
      } else {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = response.url;
        a.target = "_blank";
        //a.download = 'Report.pdf';
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  onPdfDocumentLoadSuccess = (args) => {
    this.setState({
      pdfNumberOfPages: args.numPages,
    });
  };

  onCurrentPdfPageChange = (offset) => {
    const currentPdfPage = this.state.currentPdfPage + offset;
    if (currentPdfPage <= 0 || currentPdfPage > this.state.pdfNumberOfPages)
      return;

    this.setState({
      currentPdfPage: currentPdfPage,
    });
  };

  hidePdfDocument = () => {
    this.setState({
      showPdfDocument: null,
    });
  };

  hideZipViewer = () => {
    this.setState({
      showZipViewer: null,
    });
  };

  render() {
    const { veterinarianData } = this.state;
    let horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == this.props.horseId
    );
    console.log(veterinarianData);
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onSaveClick}
          />
          <span className="npTitle">{window.lang.veterinarian}</span>
          <span
            id="vacHorseInfo"
            style={{
              position: "absolute",
              right: "15%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {horse.name}
          </span>
          <img
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <table style={{ width: "100%", marginTop: "60px" }}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top", width: "80%" }}>
                <button
                  className="ui-btn ui-shadow ui-corner-all"
                  style={{ width: "initial" }}
                  onClick={this.onNewVetereinarianClick}
                >
                  {" "}
                  {window.lang.newVeterinar}
                </button>
              </td>
              <td style={{ float: "right", marginRight: "40px" }}>
                {!this.state.multiSelectMode ? (
                  <div
                    style={{
                      cursor: "pointer",
                      marginTop: "20px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={this.toggleExtraButtons}
                  >
                    <img loading="lazy" src="img/vet_select_icon.png" />
                    <span>{window.lang.select}</span>
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {window.multimediaMobileHelper.isActive ? (
                      <img
                        className="hgHeaderIcon"
                        style={{ marginRight: "10px" }}
                        src="img/share.png"
                        onClick={this.onShareClick}
                      />
                    ) : (
                      <img
                        className="hgHeaderIcon"
                        src="img/download.png"
                        onClick={this.onDownloadClick}
                      />
                    )}
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={this.toggleExtraButtons}
                    >
                      {window.lang.cancel2}
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <table className="farrierTable">
            <tbody>
              <tr>
                <th>Done</th>
                <th>{window.lang.date}</th>
                <th>Type</th>
                <th style={{ width: "40%" }}>{window.lang.remark}</th>
                <th>Files</th>
                <th></th>
              </tr>
              {veterinarianData.length ? (
                veterinarianData.map((i, index) => (
                  <tr key={Math.uuid()}>
                    <td>
                      <ToggleView
                        checked={i.color == "green" ? true : false}
                        onClick={() => this.onDoneToggle(i)}
                      />
                    </td>
                    <td>{i.starttime}</td>
                    <td>{this.getVeterinarTypeName(i["VeterinarianType"])}</td>
                    <td>{i.remark}</td>
                    <td>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {i.multimedia &&
                          i.multimedia.map((item, number) => (
                            <div key={item.thumbUrl} className="hgImageItem">
                              <div
                                className="hgThumbImage"
                                style={{
                                  height: "50px",
                                  width: "100px",
                                  backgroundImage: `url('${item.thumbUrl}')`,
                                }}
                                onClick={() =>
                                  !this.state.multiSelectMode
                                    ? this.showFullscreen(index, number)
                                    : this.selectMultimedia(index, number)
                                }
                              >
                                {(item.type == MultimediaHelper.TYPE_VIDEO ||
                                  item.type ==
                                    MultimediaHelper.TYPE_YOUTUBE) && (
                                  <img
                                    src="img/play_arrow.svg"
                                    className="hgThumbImagePlay"
                                  />
                                )}
                                {this.state.multiSelectMode && (
                                  <img
                                    className="hgThumbSelectIcon"
                                    src={
                                      item.isSelected
                                        ? "img/icon_okn.png"
                                        : "img/icon_circle.png"
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                      {i.plusFiles && (
                        <table>
                          <tbody>
                            {i.plusFiles.map((file, fileIndex) => (
                              <tr key={fileIndex}>
                                <td style={{ border: "none" }}>
                                  {this.state.multiSelectMode && (
                                    <input
                                      type="checkbox"
                                      checked={file.isSelected}
                                      onChange={() =>
                                        this.selectFile(index, fileIndex)
                                      }
                                    />
                                  )}
                                </td>
                                <td
                                  style={{ border: "none", textAlign: "left" }}
                                  onClick={() =>
                                    !this.state.multiSelectMode
                                      ? this.onViewDocumentClick(
                                          index,
                                          fileIndex
                                        )
                                      : this.selectFile(index, fileIndex)
                                  }
                                >
                                  {decodeURI(file.name)}
                                  <img
                                    src="img/view_file.png"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </td>
                    <td>
                      <img
                        className="actionIcon"
                        src="img/edit_icon.png"
                        onClick={() => this.onEditClick(i.id)}
                      />
                      <img
                        className="actionIcon"
                        src="img/trashcan.png"
                        onClick={() => this.onDeleteClick(i.id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
          {this.state.showFullScreenView && (
            <div className="hgFullScreenView">
              {!this.state.showFullScreenLoading && this.renderFullscreenItem()}
              {window.multimediaMobileHelper.isActive && (
                <img
                  className="hgFullScreenShare"
                  src="img/share.png"
                  onClick={this.onShareFullscreenItem}
                />
              )}
              <img
                className="hgFullScreenClose"
                src="img/icon_canceln.png"
                onClick={this.hideFullscreen}
              />
            </div>
          )}
          {this.state.showPdfDocument && (
            <div className="hgFullScreenView">
              <div className="pdfDocument" style={{ width: this.pdfPageWidth }}>
                <Document
                  file={this.state.showPdfDocument}
                  onLoadSuccess={this.onPdfDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={this.state.currentPdfPage}
                    renderTextLayer={false}
                    width={this.pdfPageWidth}
                  />
                </Document>
              </div>
              <div className="pdfPages">
                <img
                  className="pdfArrowIcon"
                  src="img/icon_left.svg"
                  onClick={() => this.onCurrentPdfPageChange(-1)}
                />
                <div>
                  {this.state.currentPdfPage}/{this.state.pdfNumberOfPages}
                </div>
                <img
                  className="pdfArrowIcon"
                  src="img/icon_right.svg"
                  onClick={() => this.onCurrentPdfPageChange(1)}
                />
              </div>
              {window.multimediaMobileHelper.isActive && (
                <img
                  className="hgFullScreenShare"
                  src="img/share.png"
                  onClick={this.onShareFullscreenItem}
                />
              )}
              <img
                className="hgFullScreenClose"
                src="img/icon_canceln.png"
                onClick={this.hidePdfDocument}
              />
            </div>
          )}
          {this.state.showZipViewer && (
            <div className="hgFullScreenView">
              <ZipViewer
                fileUrl={this.state.showZipViewer}
                style={{ marginTop: "50px" }}
              />
              <img
                className="hgFullScreenClose"
                src="img/icon_canceln.png"
                onClick={this.hideZipViewer}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
