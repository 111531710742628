export default class MultimediaMobileHelper {
  constructor() {
    this.MultimediaIOS = null;
    if (
      window.webkit != null &&
      window.webkit.messageHandlers != null &&
      window.webkit.messageHandlers.MultimediaIOS != null
    )
      this.MultimediaIOS = window.webkit.messageHandlers.MultimediaIOS;

    this.isActive =
      this.MultimediaIOS != null || window.MultimediaAndroid != null;
  }

  postMessage(dataObj) {
    if (this.MultimediaIOS) {
      this.MultimediaIOS.postMessage(dataObj);
    } else if (window.MultimediaAndroid) {
      window.MultimediaAndroid.postMessage(dataObj.type, dataObj.data);
    }
  }

  setHorseList(horses) {
    if (this.isActive) {
      this.postMessage({ type: "setHorseList", data: JSON.stringify(horses) });
    }
  }

  loadList(userId, horseId, callback) {
    this.loadCallback = callback;
    if (this.isActive) {
      this.postMessage({
        type: "getImageList",
        data: JSON.stringify({ userId: userId, horseId: horseId }),
      });
    }
  }

  onLoadCallback(data) {
    if (this.loadCallback) this.loadCallback(data);
  }

  cancelUpload(request_origin, fileName) {
    this.postMessage({
      type: "cancelUpload",
      data: JSON.stringify({
        request_origin,
        fileName,
      }),
    });
  }

  downloadExcell() {
    this.postMessage({
      type: "downloadExcel",
      data: JSON.stringify({}),
    });
  }

  showFullScreen(userId, horseId, imageItem) {
    if (this.isActive) {
      this.postMessage({
        type: "showFullscreen",
        data: JSON.stringify({
          userId: userId,
          horseId: horseId,
          fileName: imageItem.name,
        }),
      });
    }
  }

  onUploadDoneCallback(fileName, data, target_origin) {
    if (this.uploadDoneCallback)
      this.uploadDoneCallback(fileName, data, target_origin);
  }

  onUploadStartCallback(fileName, imageUrl, target_origin) {
    if (this.uploadStartCallback) {
      this.uploadStartCallback(fileName, imageUrl, target_origin);
    }
  }

  onUploadProgressCallback(fileName, percent, target_origin) {
    if (this.uploadProgressCallback) {
      this.uploadProgressCallback(fileName, percent, target_origin);
    }
  }

  onUploadListCallback(data, target_origin) {
    if (this.uploadListCallback) this.uploadListCallback(data, target_origin);
  }

  changeName(item, nameNew, horseId, callback) {
    if (this.isActive) {
      this.changeNameCallback = callback;
      this.postMessage({
        type: "renameImage",
        data: JSON.stringify({
          id: item.id,
          name: item.name,
          path_display: item.path_display,
          horseId: horseId,
        }),
      });
    }
  }

  onChangeNameCallback(item) {
    if (this.changeNameCallback) this.changeNameCallback(item);
  }

  shareItems(userId, horseId, items) {
    let itemNames = items.map((item) => item.name);
    this.postMessage({
      type: "shareItems",
      data: JSON.stringify({
        userId: userId,
        horseId: horseId,
        itemNames: itemNames,
      }),
    });
  }

  downloadAndShareItems(items) {
    this.postMessage({
      type: "downloadAndShareItems",
      data: JSON.stringify(items),
    });
  }

  onDownloadSyncCallback(count, totalCount) {
    if (count > 0) {
      window.$("#syncMultmediaInfo").show();
      window
        .$("#syncMultmediaInfo")
        .html(`Sync multimedia horses: ${count} of ${totalCount}`);
    } else {
      window.$("#syncMultmediaInfo").hide();
    }
  }
}
