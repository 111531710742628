import React, { Component } from "react";
import "./WeekView.css";
import HorseCell from "../HorseCell/HorseCell";
import TrainingCellMap from "../TrainingCellMap";
import TrainingDay, { TrainingCell } from "../TrainingCell/TrainingDay";
import DataGateActions from "../../DataGateActions";

class WeekView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderDate: null,
      horseArr: [],
      horseEquipmentFlag: false,
    };
    this.dates = [];
    this.permanentTypes = [];
    this.trainingCellMap = new TrainingCellMap();
    this.showRider =
      window.GlobalData.getItem(window.GlobalData.SHOW_RIDER_ON_TASKS) != 0;
  }

  componentDidUpdate = () => {
    if (this.state.horseArr != this.props.filteredHorses)
      this.setState({
        horseArr: this.props.filteredHorses,
      });
  };

  componentDidMount() {
    this.setState({
      horseArr: this.props.filteredHorses,
    });
  }

  equipmentUpdate = (e, horse) => {
    if (this.state.horseEquipmentFlag) {
      horse.Equipment = e.target.value;
      DataGateActions.updateEquipment({
        Equipment: e.target.value,
        HorseId: horse.id,
      });
      this.setState({
        horseEquipmentFlag: false,
      });
    }
  };

  equipmentChange = (e, horse) => {
    let arr = this.state.horseArr;
    arr.find((h) => h.id == horse.id).Equipment = e.target.value;
    this.setState({
      horseArr: arr,
      horseEquipmentFlag: true,
    });
  };

  onOrderChange = (orderDate) => {
    this.setState({
      orderDate: orderDate,
    });
  };

  getTrainingsForDay = (horse, date) => {
    return this.filteredTrainings.filter(
      (t) => t.horseid == horse.id && t.starttime == date
    );
  };

  widthFirstColumn() {
    let widthFC = 200;
    if (window.training.period == 1) {
      if (window.GlobalData.getItem(window.GlobalData.SHOW_HORSELOGO) == 1) {
        widthFC = window.isMobileDevice() ? 150 : 200;
      } else {
        widthFC = window.isMobileDevice() ? 150 : 200;
      }
    } else {
      if (window.GlobalData.getItem(window.GlobalData.SHOW_HORSELOGO) == 1) {
        widthFC = window.isMobileDevice() ? 100 : 180;
      } else {
        widthFC = window.isMobileDevice() ? 70 : 180;
      }
    }
    return widthFC;
  }

  renderOrderByHorse() {
    let horseArr = this.state.horseArr;

    let myHorseArr = horseArr.filter((h) => h.userid == window.myid);
    myHorseArr = myHorseArr.sort(function (h1, h2) {
      return h1.name.toLowerCase() > h2.name.toLowerCase() ? 1 : -1;
    });

    let notMyHorseArr = horseArr.filter((h) => h.userid != window.myid);
    notMyHorseArr = notMyHorseArr.sort(function (h1, h2) {
      return h1.name.toLowerCase() > h2.name.toLowerCase() ? 1 : -1;
    });

    return (
      <>
        {myHorseArr.map((horse) => this.getRow(horse))}
        {notMyHorseArr.length != 0 && myHorseArr.length != 0 && (
          <tr>
            <td
              className="sharedWithMeRow"
              colSpan={
                window.training.period +
                this.permanentTypes.length +
                (this.props.showEquipment ? 2 : 1)
              }
            >
              {window.lang.sharedWithMe}
            </td>
          </tr>
        )}
        {notMyHorseArr.map((horse) => this.getRow(horse))}
      </>
    );
  }

  renderOrderByDate = () => {
    let horseArr = this.trainingCellMap.sortHorsesBySelectedDate(
      this.state.horseArr,
      this.state.orderDate,
      this.getTrainingsForDay
    );

    return <>{horseArr.map((horse) => this.getRow(horse))}</>;
  };

  getRow = (horse) => {
    let widthFirstColumn = this.widthFirstColumn();
    let trainingPeriod = window.GlobalData.getItem(
      window.GlobalData.KEY_DAYS_NUMBER
    );
    return (
      <tr key={horse.id}>
        <td style={{ width: widthFirstColumn, textAlign: "left" }}>
          <HorseCell horse={horse} />
        </td>
        {this.dates.map((date) => (
          <td
            key={`${horse.id}&${date}`}
            style={
              this.dates.length == 1 && trainingPeriod != -8
                ? { width: "100%" }
                : {}
            }
          >
            <TrainingDay
              trainings={this.getTrainingsForDay(horse, date.formatDesh())}
              date={date}
              isSelectedDate={date.formatDesh == this.state.orderDate}
              horse={horse}
              trainingCellMap={this.trainingCellMap}
              showRider={this.showRider}
            />
          </td>
        ))}
        {this.permanentTypes.map((type) => (
          <td key={`${horse.id}&${type.id}`} className="permanentHeader">
            {this.permanentCell(horse, type)}
          </td>
        ))}
        {this.props.showEquipment && (
          <td>
            <textarea
              className="equipmentInput"
              value={horse.Equipment != null ? horse.Equipment : ""}
              onChange={(e) => this.equipmentChange(e, horse)}
              onBlur={(e) => this.equipmentUpdate(e, horse)}
            />
          </td>
        )}
      </tr>
    );
  };

  permanentCell = (horse, ttype) => {
    let trainings = this.permanentTrainings.filter(
      (t) => t.horseid == horse.id && t.type == ttype.id
    );
    if (trainings.length == 0) {
      return (
        <TrainingCell
          holdEnabled={true}
          horse={horse}
          column={{ shortname: ttype.shortname }}
          date={this.dates[0]}
          showRider={this.showRider}
          callbackAdd={() =>
            this.addPermanentTraining(horse, ttype, this.dates[0])
          }
          useOnlyAddCallback={true}
        />
      );
    }

    return (
      <div className="trainingCellContainer">
        {trainings.map((t) => (
          <TrainingCell
            holdEnabled={true}
            key={t.id}
            training={t}
            horse={horse}
            date={this.dates[0]}
            showRider={this.showRider}
          />
        ))}
      </div>
    );
  };

  addPermanentTraining = (horse, type, date) => {
    DataGateActions.addTrainingForPermanentColumn(horse, type, date);
    window.training.refreshLayout();
    //window.training.buildFromCache();
  };

  render() {
    this.showRider =
      window.GlobalData.getItem(window.GlobalData.SHOW_RIDER_ON_TASKS) != 0;
    let widthFirstColumn = this.widthFirstColumn();
    let today = new Date();
    this.dates = [];
    for (var i = 0; i < window.training.period; i++) {
      this.dates.push(window.training.startDate.addDays(i));
    }

    this.permanentTypes = [];
    this.filteredTrainings = this.props.filteredTrainings;
    if (this.dates.length == 1) {
      this.permanentTypes = window.DataStorage.trainTypes.filter(
        (tt) => tt.IsPermanent
      );
      const permanentTypeIds = this.permanentTypes.map((tt) => tt.id);
      if (this.permanentTypes.length != null) {
        this.filteredTrainings = this.props.filteredTrainings.filter(
          (t) => permanentTypeIds.indexOf(t.type) == -1
        );
        this.permanentTrainings = this.props.filteredTrainings.filter(
          (t) => permanentTypeIds.indexOf(t.type) != -1
        );
      }
    }

    this.trainingCellMap.refreshMap(
      this.dates.map((d) => d.formatDesh()),
      this.filteredTrainings
    );

    return (
      <div>
        <table
          className={
            !window.isMobileDevice()
              ? "tableWeekView"
              : "tableWeekView tableWeekViewMobile"
          }
        >
          <thead>
            <tr>
              <td
                style={{ width: widthFirstColumn }}
                onClick={(e) => this.onOrderChange(null)}
              >
                {window.lang.horse}
                {this.state.orderDate == null && <div>&#x25BC;</div>}
              </td>
              {this.dates.map((date) => (
                <td
                  key={date}
                  className={
                    date.formatDesh() == today.formatDesh() ? "todayHeader" : ""
                  }
                  onClick={(e) => this.onOrderChange(date.formatDesh())}
                >
                  {`${date.getDate()}.${date.getMonth() + 1}`}
                  <br />
                  {date.dayOfWeek()}
                  {this.state.orderDate == date.formatDesh() && (
                    <div>&#x25BC;</div>
                  )}
                </td>
              ))}
              {this.permanentTypes.map((type) => (
                <td key={type.id} className="permanentHeader">
                  {type.name}
                </td>
              ))}
              {this.props.showEquipment && <td>{window.lang.equipment}</td>}
            </tr>
          </thead>
        </table>
        <div
          className={
            !window.isMobileDevice() && !window.isIphoneIpad()
              ? "scrollable"
              : "scrollableMobile"
          }
        >
          <table
            className={
              !window.isMobileDevice() && !window.isIphoneIpad()
                ? "tableWeekView"
                : "tableWeekView tableWeekViewMobile"
            }
          >
            <tbody>
              {this.state.orderDate == null
                ? this.renderOrderByHorse()
                : this.renderOrderByDate()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default WeekView;
