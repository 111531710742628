import React from "react";
import "./MediaTypeAlert.css";
import { useEffect } from "react";
export default function MediaTypeAlert({
  show,
  setShow,
  selectImages,
  selectVideos,
}) {
  if (!show) return null;

  const selectImagesHandler = () => {
    setShow(false);
    selectImages();
  };
  const selectVideosHandler = () => {
    setShow(false);
    selectVideos();
  };

  return (
    <div className="card__container" onClick={() => setShow(false)}>
      <div className="card">
        <div className="info__padding">
          <h3>Select media type</h3>
          <p>Please select the type of files you want to upload</p>
        </div>
        <div className="button__group">
          <button onClick={selectImagesHandler}>Image</button>
          <button onClick={selectVideosHandler}>Video</button>
        </div>
      </div>
    </div>
  );
}
