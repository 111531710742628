import React, { Component } from "react";
import ReactDOM from "react-dom";
import ToggleView from "../Controls/ToggleView/ToggleView";

class Permissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleId: "",
      dataArr: [],
      OrderC: "",
      hOpenHorse: "",
      hSeeVET: "",
      hWrite: "",
      hSeePriceInReport: "",
      tShowSmileOnTask: "",
      rSeeHorseData: "",
      vDeleteDay: "",
      vCopyAllHorses: "",
      hCreateOnMyAccount: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.roleId != prevProps.roleId) {
      this.loadData();
    }
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.db.getRiderRolePermissions(this.props.roleId, (data) => {
      var item = data[0];
      console.log(data);
      this.setState({
        roleId: this.props.roleId,
        dataArr: data,
        OrderC: item.OrderC,
        hOpenHorse: item.hOpenHorse,
        hSeeVET: item.hSeeVET,
        hWrite: item.hWrite,
        hSeePriceInReport: item.hSeePriceInReport,
        tShowSmileOnTask: item.tShowSmileOnTask,
        rSeeHorseData: item.rSeeHorseData,
        vDeleteDay: item.vDeleteDay,
        vCopyAllHorses: item.vCopyAllHorses,
        hCreateOnMyAccount: item.hCreateOnMyAccount,
      });
    });
  };

  onToggleChange = (name) => {
    this.setState({ [name]: this.state[name] ? 0 : 1 });
  };

  onHOpenHorseClick = () => {
    this.state.hOpenHorse
      ? this.setState({ hOpenHorse: 0 })
      : this.setState({ hOpenHorse: 1 });
  };

  onHSeeVETClick = () => {
    this.state.hSeeVET
      ? this.setState({ hSeeVET: 0 })
      : this.setState({ hSeeVET: 1 });
  };

  onHWriteClick = () => {
    this.state.hWrite
      ? this.setState({ hWrite: 0 })
      : this.setState({ hWrite: 1 });
  };

  onHSeePriceInReportClick = () => {
    this.state.hSeePriceInReport
      ? this.setState({ hSeePriceInReport: 0 })
      : this.setState({ hSeePriceInReport: 1 });
  };

  onTShowSmileOnTaskClick = () => {
    this.state.tShowSmileOnTask
      ? this.setState({ tShowSmileOnTask: 0 })
      : this.setState({ tShowSmileOnTask: 1 });
  };

  onRSeeHorseDataClick = () => {
    this.state.rSeeHorseData
      ? this.setState({ rSeeHorseData: 0 })
      : this.setState({ rSeeHorseData: 1 });
  };

  onVDeleteDayClick = () => {
    this.state.vDeleteDay
      ? this.setState({ vDeleteDay: 0 })
      : this.setState({ vDeleteDay: 1 });
  };

  onVCopyAllHorsesClick = () => {
    this.state.vCopyAllHorses
      ? this.setState({ vCopyAllHorses: 0 })
      : this.setState({ vCopyAllHorses: 1 });
  };

  onOKClick = () => {
    const {
      roleId,
      hOpenHorse,
      hSeeVET,
      hWrite,
      hSeePriceInReport,
      tShowSmileOnTask,
      rSeeHorseData,
      vDeleteDay,
      vCopyAllHorses,
      hCreateOnMyAccount,
    } = this.state;
    window.Spinner.show();
    window.DataGate.editRiderRole(
      roleId,
      hOpenHorse,
      hSeeVET,
      hWrite,
      hSeePriceInReport,
      tShowSmileOnTask,
      rSeeHorseData,
      vDeleteDay,
      vCopyAllHorses,
      hCreateOnMyAccount,
      () => {
        window.Spinner.hide();
        this.onCancelClick();
      }
    );
  };

  onCancelClick = () => {
    window.showPage("#riderRolePage");
    window.$("#riderRolePage").css("min-height", "95%");
  };

  render() {
    const {
      dataArr,
      OrderC,
      hOpenHorse,
      hSeeVET,
      hWrite,
      hSeePriceInReport,
      tShowSmileOnTask,
      rSeeHorseData,
      vDeleteDay,
      vCopyAllHorses,
      hCreateOnMyAccount,
    } = this.state;
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="permissionsheaderIconLeft"
            src="img/icon_canceln.png"
            onClick={this.onCancelClick}
          />
          <h1 id="permissionsNameHeader"></h1>
          <img
            loading="lazy"
            className="permissionsheaderIconRignt"
            src="img/icon_okn.png"
            onClick={this.onOKClick}
          />
        </div>
        <div className="ppContent">
          {dataArr.length ? (
            <table
              className="shareTable"
              style={{ width: "100%", textAlign: "center" }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "70%" }}>
                    <h3>{window.lang.permissionForHorses}</h3>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    {window.lang.permissionCreateHorseOnMyAccount}
                  </td>
                  <td>
                    <ToggleView
                      checked={hCreateOnMyAccount}
                      onClick={() => this.onToggleChange("hCreateOnMyAccount")}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    {window.lang.permissionOpenHorse}
                  </td>
                  <td>
                    <ToggleView
                      checked={hOpenHorse}
                      onClick={this.onHOpenHorseClick}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    {window.lang.permissionSeeVET}
                  </td>
                  <td>
                    <ToggleView
                      checked={hSeeVET}
                      onClick={this.onHSeeVETClick}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    Permission to see 'sales' data under horse// see sales
                  </td>
                  <td>
                    <ToggleView
                      checked={hSeePriceInReport}
                      onClick={this.onHSeePriceInReportClick}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    {window.lang.permissionWrite}
                  </td>
                  <td>
                    <ToggleView checked={hWrite} onClick={this.onHWriteClick} />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    <h3>{window.lang.permissionForTasks}</h3>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    {window.lang.permissionShowSmilOnTask}
                  </td>
                  <td>
                    <ToggleView
                      checked={tShowSmileOnTask}
                      onClick={this.onTShowSmileOnTaskClick}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    <h3>Permission for Reports</h3>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    See horse data in reports // See horse data
                  </td>
                  <td>
                    <ToggleView
                      checked={rSeeHorseData}
                      onClick={this.onRSeeHorseDataClick}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    <h3>{window.lang.permissionForView}</h3>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    {window.lang.permissionDeleteDay}
                  </td>
                  <td>
                    <ToggleView
                      checked={vDeleteDay}
                      onClick={this.onVDeleteDayClick}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    {window.lang.permissionCopyAllHorses}
                  </td>
                  <td>
                    <ToggleView
                      checked={vCopyAllHorses}
                      onClick={this.onVCopyAllHorsesClick}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default class PermissionsPage {
  showPermissions(roleId) {
    ReactDOM.render(
      <Permissions roleId={roleId} />,
      document.getElementById("permissionsContentReact")
    );
  }
}
