import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './TeethPopup.css';

class TeethWindow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            teethData: []
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData() {
        window.Spinner.show();
        window.db.getTeethDataForAllHorses(window.myid, (data) => {
            let reportData = [];
            data.filter(item => {
                let curr = reportData.find(i => item.HorseId == i.horseid);
                if (!curr) {
                    let firstDate = Date.getNewDate(item.TDateFirst);
                    let secondDate = Date.getNewDate(item.TDateSecond);
                    let daysLeft = Date.daysBetween(secondDate, firstDate);
                    let horse = window.DataStorage.getAllHorses().find(h => h.id == item.HorseId);
                    reportData.push({ horseName: horse.name, horseid: item.HorseId, firstDate: firstDate.formatDeshStartDay(), secondDate: secondDate.formatDeshStartDay(), daysLeft: daysLeft });
                    return true;
                } else {
                    return false;
                }
            });
            reportData = reportData.sort(this.sortTeethArr);
            this.setState({
                teethData: reportData
            });
            window.Spinner.hide();
        });
    }

    sortTeethArr = (a, b) => {
        if (a.daysLeft < b.daysLeft)
            return -1;
        if (a.daysLeft > b.daysLeft)
            return 1;
        return 0;
    }

    onCancelClick = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('allTeethPopup'));
    }

    render() {
        const { teethData } = this.state;
        let height = window.$(window).height();
        return (
            <div id="teethAllPopup" className="ui-popup ui-page-theme-a ui-corner-all" style={{ maxHeight: height - "50" }}>
                <a href="#" className="ui-btn ui-corner-all ui-shadow ui-btn-a ui-icon-delete ui-btn-icon-notext ui-btn-right" onClick={this.onCancelClick}>
                </a>
                <div style={{ overflow: "scroll", maxHeight: height - "60", margin: "15px" }}>
                    {teethData.length ?
                        teethData.map(i =>
                            <div key={Math.uuid()} style={{ margin: "10px" }}>
                                <span style={{ marginLeft: "45% " }}>
                                    <b>{i.horseName}</b>
                                </span>
                                <table className="TeethTable">
                                    <tbody>
                                        <tr>
                                            <th>{window.lang.teethSubDate}</th>
                                            <th>{window.lang.teethNextDate}</th>
                                        </tr>
                                        <tr>
                                            <td>{i.firstDate}</td>
                                            <td>{i.secondDate}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {i.daysLeft >= 0 ?
                                                    <b>{window.lang.daysLeft + ': ' + i.daysLeft}</b>
                                                    :
                                                    <b>
                                                        <span style={{ color: "red" }}>
                                                            {window.lang.daysLeft + ': ' + i.daysLeft}
                                                        </span>
                                                    </b>
                                                }
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                        :
                        <></>
                    }
                </div>
            </div >
        )
    }
}

export default class TeethAllPopup {
    showTeethAllPopup() {
        ReactDOM.render(
            <TeethWindow />,
            document.getElementById('allTeethPopup')
        )
    }
}