import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './ReminderSelectWindow.css';

export default class ReminderSelect extends Component {
    constructor() {
        super();

        this.state = {
            reminderList: [
                { id: 0, name: window.lang.reminder_No },
                { id: 1, name: window.lang.reminder_SameDay },
                { id: 2, name: window.lang.reminder_1_dayBefore },
                { id: 3, name: window.lang.reminder_2_dayBefore },
                { id: 4, name: window.lang.reminder_3_dayBefore },
                { id: 5, name: window.lang.reminder_4_dayBefore },
                { id: 6, name: window.lang.reminder_5_dayBefore },
                { id: 7, name: window.lang.reminder_6_dayBefore },
                { id: 8, name: window.lang.reminder_7_dayBefore }
            ]
        };
    }

    onItemClick = (item) => {
        this.props.onSelect(item.id);
    }

    render() {
        const { reminderList } = this.state;
        return (
            <div>
                <ul data-role="listview" id="reminderSelect" className="reminder-select-ul-react ui-listview">
                    {reminderList.map((currentElement, index) => (
                        <li
                            key={currentElement.id}
                            className={(index == 0 ? "ui-li-static ui-body-inherit ui-first-child" :
                                    (index == reminderList.length - 1 ? "ui-li-static ui-body-inherit ui-last-child"
                                        : "ui-li-static ui-body-inherit"))
                                + (this.props.selectedId == currentElement.id ? " reminder-ul-selected" : "")}
                            onClick={() => this.onItemClick(currentElement)}
                        >
                            {currentElement.name}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}