import React, { Component } from "react";
import ReactDOM from "react-dom";
import ToggleView from "../../Controls/ToggleView/ToggleView";
import LogPriceReport from "./LogPriceReport";
import "./PriceReport.css";

class PriceReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      horseTypes: [],
      horsePriceReportData: [],
      addData: {
        Id: "",
        UserId: window.myid,
        HorseTypeId: 1,
        Price: "",
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    window.db.getHorseTypePricesData(window.myid, (data) => {
      window.db.getHorseTypes(window.myid, (types) => {
        let typesMap = types.map((t) => ({ value: t.ID, label: t.Name }));
        let hTypes = [];
        typesMap.filter((item) => {
          let curr = hTypes.find((i) => i.label === item.label);
          if (!curr) {
            hTypes.push(item);
            return true;
          } else {
            curr.value += "," + item.value;
            return false;
          }
        });
        let horseTypes = [
          { value: 1, label: window.lang.showAllHorseTypes },
          ...hTypes,
        ];

        this.setState({
          horseTypes: horseTypes,
          horsePriceReportData: data,
        });
      });
    });
  };

  onItemDataChange = (name, value, id, updateServer) => {
    let horsePriceReportData = this.state.horsePriceReportData;
    let item = horsePriceReportData.find((i) => i.Id == id);
    item[name] = value;
    item.updateRequired = true;
    if (updateServer) {
      window.DataGate.updateHorseTypePriceData(item, () => {
        this.setState({
          horsePriceReportData: horsePriceReportData,
        });
      });
    } else {
      this.setState({
        horsePriceReportData: horsePriceReportData,
      });
    }
  };

  updateItemOnServer = (id) => {
    const item = this.state.horsePriceReportData.find((i) => i.Id == id);
    if (item.updateRequired) {
      window.DataGate.updateHorseTypePriceData(item, () => {
        item.updateRequired = false;
        this.setState({
          horsePriceReportData: this.state.horsePriceReportData,
        });
      });
    }
  };

  onDeleteClick = (itemIndex) => {
    window.DataGate.deleteHorseTypePriceData(
      this.state.horsePriceReportData[itemIndex],
      () => {
        this.loadData();
      }
    );
  };

  onAddDataHorseTypeChange = (e) => {
    this.setState({
      addData: {
        ...this.state.addData,
        HorseTypeId: e.target.value,
      },
    });
  };

  onAddDataPriceChange = (e) => {
    this.setState({
      addData: {
        ...this.state.addData,
        Price: e.target.value,
      },
    });
  };

  onAddClick = () => {
    if (
      this.state.horsePriceReportData.some(
        (i) => i.HorseTypeId == this.state.addData.HorseTypeId
      )
    ) {
      window.$("#itemExist").text("Selected Horse Type already exist");
    } else {
      window.$("#itemExist").text("");
      window.Spinner.show();
      window.DataGate.insertHorseTypePriceData(this.state.addData, () => {
        window.Spinner.hide();
        this.loadData();
        this.setState({
          addData: {
            Id: "",
            UserId: window.myid,
            HorseTypeId: 1,
            Price: "",
          },
        });
      });
    }
  };

  onLogButtonClick = () => {
    window.showPage("#logPriceReportPage");
    let logPriceReport = new LogPriceReport();
    logPriceReport.showLogPriceReport();
  };

  onBackClick = () => {
    window.showPage("#settingsPage");
    window.$("#settingsPage").css("min-height", "95%");
  };

  render() {
    const { horsePriceReportData, horseTypes, addData } = this.state;
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onBackClick}
          />
          <span className="npTitle">Price Report</span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onBackClick}
          />
        </div>
        <div className="ppContent">
          {/*<table>
                        <tbody>
                            <tr style={{ textAlign: 'center' }}>
                                <td style={{ width: '200px', fontWeight: 'bold' }}>
                                    Use price report
                                </td>
                                <td>
                                    <ToggleView checked={0} onClick={() => { }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>*/}
          <button
            className="ui-btn ui-shadow ui-corner-all"
            style={{ width: "auto" }}
            onClick={this.onLogButtonClick}
          >
            {" "}
            Log
          </button>
          <table className="horseTypeTable">
            <tbody>
              <tr>
                <th>Horse Type</th>
                <th>Price each months</th>
                <th>Add/Delete</th>
              </tr>
              {horsePriceReportData.length ? (
                horsePriceReportData.map((i, itemIndex) => (
                  <tr key={i.Id}>
                    <td>
                      <select
                        key={i.HorseTypeId}
                        value={i.HorseTypeId}
                        onChange={(e) => {
                          this.onItemDataChange(
                            "HorseTypeId",
                            e.target.value,
                            i.Id,
                            true
                          );
                        }}
                      >
                        {horseTypes.length ? (
                          horseTypes.map((j) => (
                            <option key={j.value} value={j.value}>
                              {j.label}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>
                    </td>
                    <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                      <input
                        type="text"
                        value={i.Price}
                        onChange={(e) => {
                          this.onItemDataChange(
                            "Price",
                            e.target.value,
                            i.Id,
                            false
                          );
                        }}
                        onBlur={() => {
                          this.updateItemOnServer(i.Id);
                        }}
                      />
                    </td>
                    <td>
                      <button
                        className="ui-btn ui-shadow ui-corner-all"
                        onClick={() => this.onDeleteClick(itemIndex)}
                      >
                        {window.lang.deleteL}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
              <tr>
                <td>
                  <span id="itemExist" style={{ color: "red" }}></span>
                </td>
              </tr>
              <tr>
                <td>
                  <select
                    key={Math.uuid()}
                    value={addData.HorseTypeId}
                    onChange={this.onAddDataHorseTypeChange}
                  >
                    {horseTypes.length ? (
                      horseTypes.map((j) => (
                        <option key={j.value} value={j.value}>
                          {j.label}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </td>
                <td className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                  <input
                    type="text"
                    value={addData.Price}
                    onChange={this.onAddDataPriceChange}
                  />
                </td>
                <td>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    onClick={this.onAddClick}
                  >
                    {window.lang.add}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default class PriceReport {
  showPriceReport() {
    ReactDOM.render(
      <PriceReportPage />,
      document.getElementById("showPriceReportReact")
    );
  }
}
