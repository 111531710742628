export default class TrainingCellMap {
    static ORDER_BY_ALPHABETIC = 1;
    static ORDER_BY_TIME_ALPHABETIC = 2;
    static ORDER_BY_TIME_TOP_COMPACT = 3;
    static ORDER_BY_TIME_TOP = 4;
    static ORDER_BY_ALPHABETIC_COMPACT = 5;

    constructor() {
        this.map = {};
        this.maxColumnCompactMap = {};
        this.orderBy = this.ORDER_BY_ALPHABETIC;
    }

    refreshMap(dates, trainingArray) {
        this.map = {};
        this.maxColumnCompactMap = {};

        this.myTrainingTypes = window.DataStorage.trainTypes.filter(t => t.owner == window.myid);
        this.myTrainingTypes = this.myTrainingTypes.sort(function (t1, t2) {
            return t1.orderC - t2.orderC;
        });

        this.orderBy = parseInt(window.GlobalData.getItem(window.GlobalData.ORDER_BY));
        switch (this.orderBy) {
            case TrainingCellMap.ORDER_BY_ALPHABETIC:
            case TrainingCellMap.ORDER_BY_ALPHABETIC_COMPACT:
                {


                    for (let dateIndex = 0; dateIndex < dates.length; dateIndex++) {

                        //build columns for each day
                        let columns = [];
                        let taskArray = trainingArray.filter(t => t.starttime == dates[dateIndex]);
                        for (let i = 0; i < taskArray.length; i++) {
                            let task = taskArray[i];
                            let column = columns.find(c => c.shortname == task.shortname);
                            if (column == null) {
                                column = {
                                    shortname: task.shortname,
                                    typeIds: [task.type]
                                };
                                columns.push(column);
                            } else {
                                if (column.typeIds.indexOf(task.type) == -1) {
                                    column.typeIds.push(task.type);
                                }
                            }
                        }

                        //sort columns
                        columns = columns.sort(this.orderComparatorByTrainingType);

                        this.map[dates[dateIndex]] = {
                            date: dates[dateIndex],
                            columns: columns
                        };
                    }
                }
                break;

            case TrainingCellMap.ORDER_BY_TIME_TOP:
            case TrainingCellMap.ORDER_BY_TIME_ALPHABETIC:
            case TrainingCellMap.ORDER_BY_TIME_TOP_COMPACT:
                {
                    for (let dateIndex = 0; dateIndex < dates.length; dateIndex++) {

                        //build columns for each day
                        let columns = [];
                        let columnsByType = [];
                        let taskArray = trainingArray.filter(t => t.starttime == dates[dateIndex]);
                        for (let i = 0; i < taskArray.length; i++) {
                            let task = taskArray[i];
                            if (task.TimeOfDay != null && task.TimeOfDay != "") {
                                let column = columns.find(c => c.TimeOfDay == task.TimeOfDay);
                                if (column == null) {
                                    columns.push({
                                        TimeOfDay: task.TimeOfDay,
                                        shortname: task.shortname,
                                        isTimeColumn: true
                                    });
                                } else {
                                }
                            } else {
                                let column = columnsByType.find(c => c.shortname == task.shortname);
                                if (column == null) {
                                    columnsByType.push({
                                        shortname: task.shortname,
                                        isTimeColumn: false
                                    });
                                }
                            }
                        }

                        //sort columns
                        columns = columns.sort(function (c1, c2) {
                            return (c1.TimeOfDay > c2.TimeOfDay) ? 1 : -1;
                        });

                        columnsByType = columnsByType.sort(this.orderComparatorByTrainingType);

                        //add columns without time
                        columns = [...columns, ...columnsByType];

                        this.map[dates[dateIndex]] = {
                            date: dates[dateIndex],
                            columns: columns
                        };
                    }
                }
                break;
        }


        if (this.orderBy == TrainingCellMap.ORDER_BY_ALPHABETIC_COMPACT) {
            for (let dateIndex = 0; dateIndex < dates.length; dateIndex++) { //calc columns for each day
                let taskArray = trainingArray.filter(t => t.starttime == dates[dateIndex]);
                let byHorseCols = {};
                let trainerCols = {};
                for (let i = 0; i < taskArray.length; i++) {
                    let task = taskArray[i];
                    if (byHorseCols[task.horseid] == null) {
                        byHorseCols[task.horseid] = { snames: [task.shortname] };
                    } else {
                        if (byHorseCols[task.horseid].snames.indexOf(task.shortname) == -1) {
                            byHorseCols[task.horseid].snames.push(task.shortname);
                        }
                    }
                }

                if (window.training.mode == window.TrainingManager.riderStatic || window.training.mode == window.TrainingManager.riderStaticAndEquipment) {
                    for (let i in byHorseCols) {
                        let horse = window.DataStorage.getAllHorses().find(j => j.id == i);
                        if (horse) {
                            if (trainerCols[horse.trainer]) {
                                if (trainerCols[horse.trainer].columns < byHorseCols[i].snames.length)
                                    trainerCols[horse.trainer].columns = byHorseCols[i].snames.length;
                            }
                            else {
                                trainerCols[horse.trainer] = { columns: byHorseCols[i].snames.length };
                            }
                        }
                    }
                    this.maxColumnCompactMap[dates[dateIndex]] = trainerCols;
                } else {
                    let maxColumnCount = Math.max(...Object.values(byHorseCols).map(c => c.snames.length), 1);
                    this.maxColumnCompactMap[dates[dateIndex]] = maxColumnCount;
                }
            }
        }

        if (this.orderBy == TrainingCellMap.ORDER_BY_TIME_TOP_COMPACT) {
            for (let dateIndex = 0; dateIndex < dates.length; dateIndex++) { //calc columns for each day
                let taskArray = trainingArray.filter(t => t.starttime == dates[dateIndex]);
                let byHorseCols = {};
                let trainerCols = {};
                for (let i = 0; i < taskArray.length; i++) {
                    let task = taskArray[i];
                    let colName = (task.TimeOfDay != null && task.TimeOfDay != "") ? task.TimeOfDay : task.shortname;
                    if (byHorseCols[task.horseid] == null) {
                        byHorseCols[task.horseid] = [colName];
                    } else {
                        if (byHorseCols[task.horseid].indexOf(colName) == -1) {
                            byHorseCols[task.horseid].push(colName);
                        }
                    }
                }

                if (window.training.mode == window.TrainingManager.riderStatic || window.training.mode == window.TrainingManager.riderStaticAndEquipment) {
                    for (let i in byHorseCols) {
                        let horse = window.DataStorage.getAllHorses().find(j => j.id == i);
                        if (horse) {
                            if (trainerCols[horse.trainer]) {
                                if (trainerCols[horse.trainer].columns < byHorseCols[i].length)
                                    trainerCols[horse.trainer].columns = byHorseCols[i].length;
                            }
                            else {
                                trainerCols[horse.trainer] = { columns: byHorseCols[i].length };
                            }
                        }
                    }
                    this.maxColumnCompactMap[dates[dateIndex]] = trainerCols;
                } else {
                    let maxColumnCount = Math.max(...Object.values(byHorseCols).map(c => c.length), 1);
                    this.maxColumnCompactMap[dates[dateIndex]] = maxColumnCount;
                }
            }
        }
    }

    orderComparatorByTrainingType = (c1, c2) => {
        let t1 = this.myTrainingTypes.find(t => t.shortname == c1.shortname);
        let t2 = this.myTrainingTypes.find(t => t.shortname == c2.shortname);
        if (t1 != null && t2 != null) {
            return (t1.orderC > t2.orderC) ? 1 : -1;
        }
        return (c1.shortname > c2.shortname) ? 1 : -1;
    }

    sortHorsesBySelectedDate = (horseArr, orderDate, getTrainingsForDay) => {
        switch (this.orderBy) {
            case TrainingCellMap.ORDER_BY_ALPHABETIC:
            case TrainingCellMap.ORDER_BY_ALPHABETIC_COMPACT:
                horseArr = horseArr.sort((h1, h2) => {
                    let trainingsH1 = getTrainingsForDay(h1, orderDate);
                    let trainingsH2 = getTrainingsForDay(h2, orderDate);
                    let trainingColums = this.map[orderDate].columns;
                    for (let ci = 0; ci < trainingColums.length; ci++) {
                        let ct1 = trainingsH1.find(t => t.shortname == trainingColums[ci].shortname);
                        let ct2 = trainingsH2.find(t => t.shortname == trainingColums[ci].shortname);
                        if (ct1 != null && ct2 == null)
                            return -1;
                        if (ct1 == null && ct2 != null)
                            return 1;
                    }
                    return 0;
                });
                break;

            case TrainingCellMap.ORDER_BY_TIME_TOP:
            case TrainingCellMap.ORDER_BY_TIME_ALPHABETIC:
            case TrainingCellMap.ORDER_BY_TIME_TOP_COMPACT:
                horseArr = horseArr.sort((h1, h2) => {
                    let trainingsH1 = getTrainingsForDay(h1, orderDate);
                    let trainingsH2 = getTrainingsForDay(h2, orderDate);
                    let trainingColums = this.map[orderDate].columns;
                    for (let ci = 0; ci < trainingColums.length; ci++) {
                        if (trainingColums[ci].isTimeColumn) {
                            let ct1 = trainingsH1.find(t => t.TimeOfDay == trainingColums[ci].TimeOfDay);
                            let ct2 = trainingsH2.find(t => t.TimeOfDay == trainingColums[ci].TimeOfDay);
                            if (ct1 != null && ct2 == null)
                                return -1;
                            if (ct1 == null && ct2 != null)
                                return 1;

                            //if both have training, then order by type
                            if (ct1 != null && ct2 != null) {
                                let myttype1 = this.myTrainingTypes.find(type => type.id == ct1.type);
                                let myttype2 = this.myTrainingTypes.find(type => type.id == ct2.type);
                                if (myttype1 != null && myttype2 != null)
                                    return myttype1.OrderC - myttype2.OrderC;
                            }
                        } else {
                            let ct1 = trainingsH1.find(t => t.shortname == trainingColums[ci].shortname);
                            let ct2 = trainingsH2.find(t => t.shortname == trainingColums[ci].shortname);
                            if (ct1 != null && ct2 == null)
                                return -1;
                            if (ct1 == null && ct2 != null)
                                return 1;
                        }
                    }
                    return trainingsH2.length - trainingsH1.length;
                });
                break;
        }

        return horseArr;
    }
}