import React, { Component } from "react";
import ReactDOM from "react-dom";
import ToggleView from "../Controls/ToggleView/ToggleView";
import "./SharePage.css";

class Share extends Component {
  constructor(props) {
    super(props);

    this.state = {
      riderId: "",
      currentEmails: "",
      shareEmails: [],
      autoShare: "",
      myHorsesArr: [],
      role: "",
      originalHorseList: [],
    };
    this.sharedUsersData = [];
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      console.log("DADASD");
      this.loadData();
    }
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.Spinner.show();
    window.db.getAllUsersExceptId(window.myid, (data) => {
      window.db.getLocalSettingsById(window.myid, (dataSettings) => {
        console.log("__________________________--");
        console.log(dataSettings);
        this.sharedUsersData = data;

        let autoShare = 0;
        let shareEmails = [];
        let riderId = "";
        let currentRiderRole = "";
        let currentEmail = "";
        let myHorses = window.DataStorage.myHorseArray;
        let myHorsesArr = [];
        let sharedHorsesArr = [];

        for (var i = 0; i < myHorses.length; i++) {
          myHorsesArr.push({ shared: 0, horseData: myHorses[i] });
        }

        for (var i = 0; i < data.length; i++) {
          shareEmails.push(data[i].email);
        }
        if (this.props.riderId) {
          riderId = this.props.riderId;
          window.Managers.shareAllHorsesUserId = riderId;
          if (dataSettings.length != 0) {
            if (
              dataSettings[0]["ShareAllUsersId"] != null &&
              dataSettings[0]["ShareAllUsersId"] != ""
            ) {
              window.Managers.shareAllHorsesUsersId = JSON.parse(
                dataSettings[0]["ShareAllUsersId"]
              );
              if (
                window.Managers.shareAllHorsesUsersId.indexOf(
                  window.Managers.shareAllHorsesUserId
                ) != -1
              ) {
                autoShare = 1;
              } else {
                autoShare = 0;
              }
            }
          }
          window.db.getMyAndSharedHorses(riderId, (myHorses, shareData) => {
            sharedHorsesArr = shareData.map((i) => i.id);
            sharedHorsesArr.filter((item) => {
              let curr = myHorsesArr.filter((i) => item == i.horseData.id);
              if (!curr) {
                return true;
              } else {
                if (curr[0]) curr[0].shared = 1;
                return false;
              }
            });

            this.setState({
              myHorsesArr: myHorsesArr,
              originalHorseList: myHorsesArr.map((x) => x.shared),
            });
          });
        } else if (this.props.horseId) {
          let horse = myHorsesArr.find(
            (h) => h.horseData.id == this.props.horseId
          );
          if (horse != null) {
            horse.shared = 1;
          }
        }
        if (this.props.email) currentEmail = this.props.email + ";";
        if (this.props.role) currentRiderRole = this.props.role;

        this.setState(
          {
            riderId: riderId,
            currentEmails: currentEmail,
            role: currentRiderRole,
            shareEmails: shareEmails,
            autoShare: autoShare,
            myHorsesArr: myHorsesArr,
            originalHorseList: myHorsesArr.map((x) => x.shared),
          },
          window.Spinner.hide()
        );
      });
    });
  };

  onEmailChange = (e) => {
    this.setState({
      currentEmails: e.target.value,
    });
  };

  onEmailInputClick = () => {
    window.$(".emailsList").css("display", "contents");
  };

  onEmailClick = (e) => {
    let oldEmails = this.state.currentEmails;
    let newEmails = oldEmails + e.currentTarget.dataset.email + ";";
    this.setState({
      currentEmails: newEmails,
    });

    const user = this.sharedUsersData.find(
      (u) => u.email == e.currentTarget.dataset.email
    );
    if (user) {
      window.Managers.shareAllHorsesUserId = user.id;
    }
  };

  onShareOtherHorseClick = () => {
    if (window.$(".collapsible-content").css("display") === "block")
      window.$(".collapsible-content").css("display", "none");
    else window.$(".collapsible-content").css("display", "block");
  };

  onAutomaticallyShareAllHorsesClick = () => {
    var set = this.state.autoShare;
    if (window.Managers.shareAllHorsesUserId != undefined) {
      window.db.getLocalSettingsById(window.myid, (dataSettings) => {
        if (dataSettings.length != 0) {
          if (
            dataSettings[0]["ShareAllUsersId"] != null &&
            dataSettings[0]["ShareAllUsersId"] != ""
          ) {
            window.Managers.shareAllHorsesUsersId = JSON.parse(
              dataSettings[0]["ShareAllUsersId"]
            );
          } else {
            window.Managers.shareAllHorsesUsersId = [];
          }
        } else {
          window.Managers.shareAllHorsesUsersId = [];
        }
        if (set == "0") {
          if (
            window.Managers.shareAllHorsesUsersId.indexOf(
              window.Managers.shareAllHorsesUserId
            ) == -1
          ) {
            window.Managers.shareAllHorsesUsersId.push(
              window.Managers.shareAllHorsesUserId
            );
            window.db.updateLocalSettingsShareAllHorses(
              JSON.stringify(window.Managers.shareAllHorsesUsersId)
            );
          }
          let myHorsesArr = this.state.myHorsesArr;
          for (var i in myHorsesArr) myHorsesArr[i].shared = 1;
          this.setState({
            autoShare: 1,
            myHorsesArr: myHorsesArr,
          });
        } else {
          window.Managers.shareAllHorsesUsersId.splice(
            window.Managers.shareAllHorsesUsersId.indexOf(
              window.Managers.shareAllHorsesUserId
            ),
            1
          );
          window.db.updateLocalSettingsShareAllHorses(
            JSON.stringify(window.Managers.shareAllHorsesUsersId)
          );
          this.setState({
            autoShare: 0,
          });
        }
      });
    }
  };

  onSelectAllClick = () => {
    let myHorsesArr = this.state.myHorsesArr;
    for (var i in myHorsesArr) myHorsesArr[i].shared = 1;
    this.setState({
      myHorsesArr: myHorsesArr,
    });
  };

  onHorseClick = (horseId) => {
    let myHorsesArrAux = [...this.state.myHorsesArr];
    let currentIndex = myHorsesArrAux.findIndex(
      (i) => i.horseData.id == horseId
    );
    myHorsesArrAux[currentIndex].shared = !myHorsesArrAux[currentIndex].shared;
    this.setState(
      {
        myHorsesArr: myHorsesArrAux,
      },
      () => {}
    );
  };

  onCancelClick = () => {
    window.showPage("#sharingPage");
    window.managers.extention.showSharingPage();
  };

  onOkClick = () => {
    window.Spinner.show();

    let selectedForShareHorsesArr = this.state.myHorsesArr.filter(
      (i, index) => i.shared == 1 && this.state.originalHorseList[index] == 0
    );
    let horseIds = "";
    let notSelectedForShareHorsesArr = this.state.myHorsesArr.filter(
      (i, index) => i.shared == 0 && this.state.originalHorseList[index] == 1
    );

    if (
      notSelectedForShareHorsesArr.length == 0 &&
      selectedForShareHorsesArr.length == 0
    ) {
      window.Spinner.hide();
      setTimeout(() => {
        window.showPage("#sharingPage");
        window.managers.extention.showSharingPage();
      }, 500);
      return;
    }

    let notSelectesHorseIds = "";

    for (var i in selectedForShareHorsesArr) {
      horseIds += selectedForShareHorsesArr[i].horseData.id + ";";
    }
    for (var i in notSelectedForShareHorsesArr) {
      notSelectesHorseIds += notSelectedForShareHorsesArr[i].horseData.id + ";";
    }
    window.DataGate.shareHorse(
      window.myid,
      this.state.currentEmails,
      horseIds,
      notSelectesHorseIds,
      "w",
      this.state.role != "None" ? this.state.role : undefined,
      (code, data) => {
        if (code == window.DataGate.CODE_OK) {
          var dataObj = JSON.parse(data);
          if (dataObj["NotValidEmails"].length == 0) {
            window.Spinner.hide();
            //var text = window.lang.horseShared.replace('{0}', dataObj["ValidEmails"].join(","));
            //window.navigator.notification.alert(text, null, 'Equestrians App');
            setTimeout(() => {
              window.showPage("#sharingPage");
              window.managers.extention.showSharingPage();
            }, 500);
          } else {
            window.Spinner.hide();
            var text = window.lang.horseNotShared.replace(
              "{0}",
              dataObj["NotValidEmails"].join(",")
            );
            window.navigator.notification.alert(text, null, "Equestrians App");
          }
        } else {
          window.Spinner.hide();
          window.navigator.notification.alert(
            window.lang.horseNotShared,
            null,
            "Equestrians App"
          );
        }
      }
    );
  };

  render() {
    const { currentEmails, shareEmails, autoShare, myHorsesArr } = this.state;
    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            id="sharingBackButton"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onCancelClick}
          />
          <span className="ppTitle">{window.lang.share}</span>
          <img
            id="sharingOkButton"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onOkClick}
          />
        </div>
        <div className="ppContent">
          <div id="shareEmailLabel">{window.lang.shareWith}</div>
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              id="shareEmail"
              value={currentEmails}
              onChange={this.onEmailChange}
              onClick={this.onEmailInputClick}
            />
          </div>
          <div id="emailsList">
            <ul
              className="emailsList ui-listview ui-listview-inset ui-corner-all ui-shadow"
              style={{ display: "none" }}
            >
              {shareEmails.length ? (
                shareEmails.map((currentElement, index) =>
                  index == 0 ? (
                    <li
                      key={Math.uuid()}
                      className="ui-first-child"
                      data-email={currentElement}
                      onClick={this.onEmailClick}
                    >
                      <a className="ui-btn ui-btn-icon-right ui-icon-arrow-r">
                        {currentElement}
                      </a>
                    </li>
                  ) : index == shareEmails.length - 1 ? (
                    <li
                      key={Math.uuid()}
                      className="ui-last-child"
                      data-email={currentElement}
                      onClick={this.onEmailClick}
                    >
                      <a className="ui-btn ui-btn-icon-right ui-icon-arrow-r">
                        {currentElement}
                      </a>
                    </li>
                  ) : (
                    <li
                      key={Math.uuid()}
                      data-email={currentElement}
                      onClick={this.onEmailClick}
                    >
                      <a className="ui-btn ui-btn-icon-right ui-icon-arrow-r">
                        {currentElement}
                      </a>
                    </li>
                  )
                )
              ) : (
                <></>
              )}
            </ul>
            <br />
          </div>
          <div style={{ marginLeft: "20px" }}>
            <label id="autoShaereLabel" htmlFor="autoShaere">
              {window.lang.automaticallyShareAllHorses}
            </label>
            <ToggleView
              checked={autoShare}
              onClick={this.onAutomaticallyShareAllHorsesClick}
            />
          </div>
          <br />
          <div>
            <button
              type="button"
              className="collapsible"
              onClick={this.onShareOtherHorseClick}
            >
              {window.lang.shareOtherHorses}
            </button>
            <div className="collapsible-content">
              <button
                id="shSelectAllButton"
                className="ui-btn ui-shadow ui-corner-all"
                style={{ width: "initial" }}
                onClick={this.onSelectAllClick}
              >
                {window.lang.selectAll}
              </button>
              {myHorsesArr.length ? (
                <table>
                  <tbody>
                    {myHorsesArr.map((i) => (
                      <tr key={Math.uuid()}>
                        <td data-id={i.horseData.id} data-hn={i.horseData.name}>
                          {i.horseData.name}
                        </td>
                        <td>
                          <ToggleView
                            checked={i.shared}
                            onClick={() => {
                              // console.log(i);
                              this.onHorseClick(i.horseData.id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class SharePage {
  showSharePage = (riderId, email, role, horseId) => {
    window.showPage("#sharePage");
    ReactDOM.render(
      <Share riderId={riderId} email={email} role={role} horseId={horseId} />,
      document.getElementById("shareReact")
    );
  };
}
