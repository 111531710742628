import React, { Component } from "react";
import "./XrayView.css";
import MultimediaHelper from "./../../Multimedia/MultimediaHelper";
import { Document, Page, pdfjs } from "react-pdf";
import ZipViewer from "../../ZipViewer/ZipViewer";

export default class XrayView extends Component {
  static DropboxToken =
    "HqmkA9nKm0wAAAAAAADLWpj-stRJN_4JyfLxtRoZD-Rzpjyz9nnuI_CMbkRkOoSk";
  constructor(props) {
    super(props);
    this.state = {
      multiSelectMode: false,
      xrayData: [],
      dbxFullScreenItem: null,
      showFullScreenView: false,
      showFullScreenItemIndex: 0,
      showFullScreenLoading: false,
      dataIndex: 0,
      plusFileIndex: 0,
      showPdfDocument: null,
      currentPdfPage: 1,
      pdfNumberOfPages: 1,
      showZipViewer: null,
    };
    this.dropbox = new window.Dropbox.Dropbox({
      accessToken: XrayView.DropboxToken,
    });
    this.helper = new MultimediaHelper();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props != prevProps) {
      this.loadData();
    }
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.Spinner.show();
    window.db.getXrayDataForUser(window.myid, (data) => {
      if (data.length) {
        data = data.sort(function (h1, h2) {
          return h1.Name.toLowerCase() >= h2.Name.toLowerCase() ? 1 : -1;
        });
        this.setState(
          {
            xrayData: data,
          },
          window.Spinner.hide()
        );
        for (let i = 0; i < data.length; i++) {
          if (data[i].Id != null) {
            this.path = `xray-${data[i].Id}`;
            // this.helper.handleCreateContainer(this.path).then(() => {
            this.helper.listBlobsWithProperties(this.path).then((response) => {
              if (response.length) {
                data[i].plusFiles = response.map((el) => {
                  el.path_display = el.name;
                  el.fullUrl = el.url;
                  el.thumbUrl = el.url;
                  el.imageUrl = el.url;
                  el.type = MultimediaHelper.getTypeItem(el);
                  return el;
                });
                console.log(data[i]);
              }
              this.setState({
                xrayData: data,
              });
            });
          }
        }
      } else window.Spinner.hide();
    });
  };

  toggleExtraButtons = () => {
    this.setState({
      multiSelectMode: !this.state.multiSelectMode,
    });
  };

  selectFile = (index, itemIndex) => {
    let xrayData = this.state.xrayData;
    xrayData[index].plusFiles[itemIndex].isSelected =
      !xrayData[index].plusFiles[itemIndex].isSelected;
    this.setState({
      xrayData: xrayData,
    });
  };

  onDownloadClick = () => {
    let plusFilesItems = JSON.parse(
      JSON.stringify(this.state.xrayData.filter((i) => i.plusFiles))
    );
    let selectedPlusFilesItems = plusFilesItems
      .map((j) => j.plusFiles)
      .flat()
      .filter((i) => i.isSelected);
    this.setState({
      multiSelectMode: false,
    });

    for (let i in selectedPlusFilesItems) {
      this.dropbox
        .filesDownload({ path: selectedPlusFilesItems[i].path_display })
        .then((response) => {
          const url = window.URL.createObjectURL(response.fileBlob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            decodeURI(selectedPlusFilesItems[i].name)
          );
          document.body.appendChild(link);
          link.click();
        });
    }
  };

  showFullscreen = (index, itemIndex) => {
    let xrayData = this.state.xrayData;
    var item = xrayData[index].plusFiles[itemIndex];
    let response = item;
    try {
      let currentItem = response;
      let ext = currentItem.name.split(".").pop().toLowerCase();
      console.log(ext);
      if (
        ext == "png" ||
        ext == "jpeg" ||
        ext == "jpg" ||
        ext == "gif" ||
        ext == "bmp"
      ) {
        console.log("DA");
        item.type = 1;
        item.fullUrl = response.url;

        this.setState({
          showFullScreenView: true,
          showFullScreenLoading: false,
          vetDataItemIndex: index,
          showFullScreenItemIndex: itemIndex,
          dbxFullScreenItem: item,
        });
      } else if (ext == "mp4" || ext == "mov" || ext == "wmv" || ext == "avi") {
        item.type = 2;
        item.fullUrl = response.url;
        this.setState({
          showFullScreenView: true,
          showFullScreenLoading: false,
          vetDataItemIndex: index,
          showFullScreenItemIndex: itemIndex,
          dbxFullScreenItem: item,
        });
      } else if (ext == "pdf") {
        this.setState({
          showPdfDocument: response.url,
          currentPdfPage: 1,
          pdfNumberOfPages: 1,
          dbxFullScreenItem: item,
        });
      } else if (ext == "zip") {
        this.setState({
          showZipViewer: response.url,
        });
      } else {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = response.url;
        a.target = "_blank";
        //a.download = 'Report.pdf';
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  onPdfDocumentLoadSuccess = (args) => {
    this.setState({
      pdfNumberOfPages: args.numPages,
    });
  };

  onCurrentPdfPageChange = (offset) => {
    const currentPdfPage = this.state.currentPdfPage + offset;
    if (currentPdfPage <= 0 || currentPdfPage > this.state.pdfNumberOfPages)
      return;

    this.setState({
      currentPdfPage: currentPdfPage,
    });
  };

  hidePdfDocument = () => {
    this.setState({
      showPdfDocument: null,
    });
  };

  hideZipViewer = () => {
    this.setState({
      showZipViewer: null,
    });
  };

  renderFullscreenItem = () => {
    const item = this.state.dbxFullScreenItem;
    switch (item.type) {
      case 1:
        return (
          <img loading="lazy" src={item.fullUrl} className="hgFullScreenItem" />
        );
      case 2:
        return (
          <video controls="controls" className="hgFullScreenItem">
            <source src={item.fullUrl} type="video/mp4" />
          </video>
        );
    }
    return <div></div>;
  };

  hideFullscreen = () => {
    this.setState({
      showFullScreenView: false,
    });
  };

  render() {
    const { xrayData } = this.state;

    return (
      <div id="xrayViewDiv">
        <table className="XrayTable">
          <thead>
            <tr style={{ borderTopStyle: "hidden" }}>
              <td style={{ borderLeftStyle: "hidden" }}></td>
              <td style={{ borderLeftStyle: "hidden" }}></td>
              <td style={{ borderLeftStyle: "hidden" }}></td>
              <td style={{ borderLeftStyle: "hidden" }}></td>
              <td style={{ borderLeftStyle: "hidden" }}></td>
              <td
                style={{
                  textAlign: "left",
                  borderLeftStyle: "hidden",
                  borderRightStyle: "hidden",
                }}
              >
                {!this.state.multiSelectMode ? (
                  <div
                    style={{ cursor: "pointer", marginTop: "20px" }}
                    onClick={this.toggleExtraButtons}
                  >
                    {window.lang.select}
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      loading="lazy"
                      className="hgHeaderIcon"
                      src="img/download.png"
                      onClick={this.onDownloadClick}
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={this.toggleExtraButtons}
                    >
                      {window.lang.cancel2}
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{window.lang.horse}</th>
              <th>Reg. nr</th>
              <th>{window.lang.bornIn}</th>
              <th>{window.lang.date}</th>
              <th>{window.lang.remark}</th>
              <th>X-ray</th>
            </tr>
            {xrayData.length ? (
              xrayData.map((i, index) => (
                <tr key={Math.uuid()}>
                  <td>{i.Name}</td>
                  <td>{i.RFBid}</td>
                  <td>{i.BirthYear}</td>
                  <td>{i.starttime}</td>
                  <td>{i.Remark}</td>
                  <td>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {i.plusFiles && (
                        <table>
                          <tbody>
                            {i.plusFiles.map((file, fileIndex) => (
                              <tr key={fileIndex}>
                                <td style={{ border: "none" }}>
                                  {this.state.multiSelectMode && (
                                    <input
                                      type="checkbox"
                                      checked={file.isSelected}
                                      onChange={() =>
                                        this.selectFile(index, fileIndex)
                                      }
                                    />
                                  )}
                                </td>
                                <td
                                  style={{ border: "none", textAlign: "left" }}
                                >
                                  {decodeURI(file.name)}
                                  <img
                                    loading="lazy"
                                    src="img/view_file.png"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginLeft: "10px",
                                    }}
                                    onClick={() =>
                                      this.showFullscreen(index, fileIndex)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
        {this.state.showFullScreenView && (
          <div className="hgFullScreenView">
            {!this.state.showFullScreenLoading && this.renderFullscreenItem()}
            {window.multimediaMobileHelper.isActive && (
              <img
                className="hgFullScreenShare"
                src="img/share.png"
                onClick={this.onShareFullscreenItem}
              />
            )}
            <img
              className="hgFullScreenClose"
              src="img/icon_canceln.png"
              onClick={this.hideFullscreen}
            />
          </div>
        )}
        {this.state.showPdfDocument && (
          <div className="hgFullScreenView">
            <div className="pdfDocument" style={{ width: this.pdfPageWidth }}>
              <Document
                file={this.state.showPdfDocument}
                onLoadSuccess={this.onPdfDocumentLoadSuccess}
              >
                <Page
                  pageNumber={this.state.currentPdfPage}
                  renderTextLayer={false}
                  width={this.pdfPageWidth}
                />
              </Document>
            </div>
            <div className="pdfPages">
              <img
                className="pdfArrowIcon"
                src="img/icon_left.svg"
                onClick={() => this.onCurrentPdfPageChange(-1)}
              />
              <div>
                {this.state.currentPdfPage}/{this.state.pdfNumberOfPages}
              </div>
              <img
                className="pdfArrowIcon"
                src="img/icon_right.svg"
                onClick={() => this.onCurrentPdfPageChange(1)}
              />
            </div>
            {window.multimediaMobileHelper.isActive && (
              <img
                className="hgFullScreenShare"
                src="img/share.png"
                onClick={this.onShareFullscreenItem}
              />
            )}
            <img
              className="hgFullScreenClose"
              src="img/icon_canceln.png"
              onClick={this.hidePdfDocument}
            />
          </div>
        )}
        {this.state.showZipViewer && (
          <div className="hgFullScreenView">
            <ZipViewer
              fileUrl={this.state.showZipViewer}
              style={{ marginTop: "50px" }}
            />
            <img
              className="hgFullScreenClose"
              src="img/icon_canceln.png"
              onClick={this.hideZipViewer}
            />
          </div>
        )}
      </div>
    );
  }
}
