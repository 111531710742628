import React, { Component } from "react";
import ToggleView from "../Controls/ToggleView/ToggleView";
import DataGateActions from "../DataGateActions";
import CreatableSelect from "react-select/creatable";
import "./HorseViewPage.css";

class HorseEditPage extends Component {
  constructor(props) {
    super(props);

    this.userOwnerId = props.userOwnerId ? props.userOwnerId : window.myid;

    if (props.horseId != -1) {
      this.horse = window.DataStorage.getAllHorses().find(
        (h) => h.id == props.horseId
      );
      this.userOwnerId = this.horse.userid;
      this.state = {
        hasChanges: false,
        name: this.horse.name,
        imageUrl:
          this.horse.LogoFile != null
            ? window.DataGate.baseurl + this.horse.LogoFile
            : "img/horselogo.png",
        bornIn: this.horse.BirthYear,
        regNr: this.horse.rfbid,
        arriveDate: this.parseDateStartDay(this.horse.CreateDate).formatDesh(),
        departureDate: this.parseDateStartDay(
          this.horse.DepartureDate
        ).formatDesh(),
        height: this.horse.Height,
        stableBoxId:
          window.inUseStables == "Y" ? this.horse.BoxName : this.horse.StableId,
        gender: this.horse.Gender,
        horseType: this.horse.HorseType != null ? this.horse.HorseType : "",
        colorId: this.horse.ColorId,
        trainer: this.horse.trainer != null ? this.horse.trainer : "",
        istraining: this.horse.istraining,
        isvisible:
          window.Training.hiddenHorses.indexOf(props.horseId) == -1 ? "1" : "0",
        chipid: this.horse.Chipid != null ? this.horse.Chipid : "",
        trainersArr: [],
        labels: [],
        selectedLabels: [],
      };
    } else {
      this.state = {
        hasChanges: false,
        name: "",
        imageUrl: "img/horselogo.png",
        bornIn: "",
        regNr: "",
        arriveDate: new Date().formatDesh(),
        departureDate: "", // new Date().formatDesh(),
        height: "",
        stableBoxId: "",
        gender: "0",
        horseType: "",
        colorId: "020b69ff-6210-41a9-82b0-122091bf88a0",
        trainer: "",
        istraining: "1",
        isvisible: "1",
        chipid: "",
        trainersArr: [
          { key: "", value: window.lang.none },
          { key: window.myid, value: window.lang.me },
        ],
        labels: [],
        selectedLabels: [],
      };
    }

    this.isMobile = window.isMobileDevice();
  }

  parseDateStartDay(dateStr) {
    if (dateStr == null || dateStr == "") return new Date("1900-01-01");

    let arr = dateStr.split("-");
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }

  componentWillMount() {
    const GlobalData = window.GlobalData;
    this.isShowBornIn = GlobalData.getItem(GlobalData.SHOW_BORNIN) == 1;
    this.isShowRegNr = GlobalData.getItem(GlobalData.SHOW_REGNR) == 1;
    this.isShowChipid = GlobalData.getItem(GlobalData.SHOW_CHIPID) == 1;
    this.isShowArriveDate = GlobalData.getItem(GlobalData.SHOW_ARRIVEDATE) == 1;
    this.isShowSendSalesData =
      GlobalData.getItem(GlobalData.SHOW_SEND_SALES_DATA) == 1;
    this.isShowStable = GlobalData.getItem(GlobalData.SHOW_STABLE) == 1;
    this.isShowGender = GlobalData.getItem(GlobalData.SHOW_GENDER) == 1;
    this.isShowType = GlobalData.getItem(GlobalData.SHOW_TYPE) == 1;
    this.isShowHeight = GlobalData.getItem(GlobalData.SHOW_HEIGHT) == 1;
    this.isShowColor = GlobalData.getItem(GlobalData.SHOW_COLOR) == 1;
    this.isShowHorseFollowsRider =
      GlobalData.getItem(GlobalData.SHOW_HORSE_FOLLOWS_RIDER) == 1;
  }

  componentDidMount() {
    window.$("#addHorsePageContent").trigger("create");
    if (
      window.DataStorage.boxesAndStables == null ||
      window.DataStorage.boxesAndStables.length == 0
    ) {
      window.Spinner.show();
      window.DataStorage.fetchHorsesData(() => {
        window.Spinner.hide();
        this.setState({});
      });
    }
    if (this.props.horseId != -1) {
      this.loadTrainers();
      this.loadLabels();
    }
  }

  loadTrainers = () => {
    window.db.getShareWithHorseTrainers(this.props.horseId, (trainers) => {
      let trainerArr = [
        { key: "", value: window.lang.none },
        { key: window.myid, value: window.lang.me },
      ];
      trainers.filter((item) => {
        let curr = trainerArr.find((i) => item.sharewithid == i.key);
        if (!curr) {
          trainerArr.push({ key: item.sharewithid, value: item.uname });
          return true;
        } else {
          return false;
        }
      });
      let horse = window.DataStorage.getAllHorses().find(
        (h) => h.id == this.props.horseId
      );
      if (horse && horse.userid != window.myid) {
        let horseOwner = window.DataStorage.allUsers.find(
          (i) => i.Id == horse.userid
        );
        if (horseOwner)
          trainerArr.push({ key: horseOwner.Id, value: horseOwner.Name });
      }
      this.setState({
        trainersArr: trainerArr,
      });
    });
  };

  loadLabels = () => {
    if (this.horse.userid == window.myid) {
      let horseLabelsArr = [];
      let labelsArr = [];
      if (this.horse.LabelIds) {
        let horseLabelIdArr = JSON.parse(this.horse.LabelIds);
        for (let i in horseLabelIdArr) {
          if (window.DataStorage.horseLabels.length) {
            let element = window.DataStorage.horseLabels.find(
              (j) => j.Id == horseLabelIdArr[i]
            );
            horseLabelsArr.push({ value: element.Id, label: element.Label });
          }
        }
      }
      if (window.DataStorage.horseLabels)
        labelsArr = [
          ...window.DataStorage.horseLabels.map((t) => ({
            value: t.Id,
            label: t.Label,
          })),
        ];

      this.setState({
        labels: labelsArr,
        selectedLabels: horseLabelsArr,
      });
    } else {
      window.DataGate.fetchHorseLabels(this.horse.userid, (code, result) => {
        const userLabels = JSON.parse(result);
        let horseLabelsArr = [];
        let labelsArr = [];
        if (userLabels.length) {
          if (this.horse.LabelIds) {
            let horseLabelIdArr = JSON.parse(this.horse.LabelIds);
            for (let i in horseLabelIdArr) {
              let element = userLabels.find((j) => j.Id == horseLabelIdArr[i]);
              horseLabelsArr.push({ value: element.Id, label: element.Label });
            }
          }
          labelsArr = [
            ...userLabels.map((t) => ({ value: t.Id, label: t.Label })),
          ];
        }

        this.setState({
          labels: labelsArr,
          selectedLabels: horseLabelsArr,
        });
      });
    }
  };

  onTextChange = (e) => {
    this.setState({
      hasChanges: true,
      [e.target.name]: e.target.value,
    });
  };

  onToggleClick = (name) => {
    this.setState({
      hasChanges: true,
      [name]: this.state[name] == "1" ? "0" : "1",
    });
  };

  onHorseImageSelect = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.setState({
        hasChanges: true,
        imageUrl: reader.result,
      });
    };
  };

  doSave = (callback) => {
    if (this.state.hasChanges) {
      if (this.state.name.length == 0) {
        navigator.notification.alert(
          window.lang.enterDataAndPushPutton,
          null,
          "Equestrians App"
        );
        return;
      }

      let horseId = this.props.horseId != -1 ? this.props.horseId : Math.uuid();
      let notes = this.props.horseId != -1 ? this.horse.notes : "";
      let userId =
        this.props.horseId != -1 ? this.horse.userid : this.userOwnerId;
      let defaultUserTypes =
        this.props.horseId != -1
          ? this.horse.defaultusertypes
          : this.userOwnerId;
      let vacprogramm = this.props.horseId != -1 ? this.horse.vacprogramm : 1;
      let selectedLabels = JSON.stringify(
        this.state.selectedLabels.map((i) => i.value)
      );
      let horseObj = {
        BirthYear: this.state.bornIn,
        BoxName: window.inUseStables == "Y" ? this.state.stableBoxId : "",
        StableId: window.inUseStables != "Y" ? this.state.stableBoxId : "",
        ColorId: this.state.colorId != "" ? this.state.colorId : null,
        CreateDate: new Date(this.state.arriveDate).formatDeshStartDay(),
        DefaultUserTypes: defaultUserTypes,
        DepartureDate: new Date(this.state.departureDate).formatDeshStartDay(),
        Gender: this.state.gender,
        Height: this.state.height,
        HorseType: this.state.horseType != "" ? this.state.horseType : "",
        Id: horseId,
        IsTraining: this.state.istraining,
        Name: this.state.name,
        Notes: notes,
        RFBid: this.state.regNr,
        SyncTime: -1,
        Trainer: this.state.trainer,
        U: "u",
        UserId: userId,
        VaccinationProgramm: vacprogramm,
        Chipid: this.state.chipid,
        LabelIds: selectedLabels,
      };

      let logo = null;
      if (this.state.imageUrl.indexOf("data:image") != -1) {
        logo = this.state.imageUrl.substring(
          this.state.imageUrl.indexOf("base64,") + 7
        );
      }

      DataGateActions.insertOrUpdateHorse(
        horseObj,
        logo,
        this.props.horseId == -1,
        () => {
          if (window.inUseStables == "Y") {
            let boxIdNew = this.state.stableBoxId;
            let boxIdOld = this.props.horseId == -1 ? null : this.horse.BoxName;
            if (boxIdNew != boxIdOld) {
              console.log(boxIdNew + " => " + boxIdOld);
              if (boxIdOld != null && boxIdOld != "") {
                window.db.updateBoxInUseById(boxIdOld, "N");
                window.DataStorage.boxesAndStables.find(
                  (i) => i.IDBox == boxIdOld
                ).InUse = "N";
              }
              if (boxIdNew != null && boxIdNew != "") {
                window.db.updateBoxInUseById(boxIdNew, "Y");
                window.DataStorage.boxesAndStables.find(
                  (i) => i.IDBox == boxIdNew
                ).InUse = "Y";
              }
              if (this.props.horseId != -1) {
                var dt = new Date();
                let data = {
                  horseId: this.props.horseId,
                  oldStableId: null,
                  newStableId: null,
                  oldBoxId: boxIdOld,
                  newBoxId: boxIdNew,
                  date: `${(dt.getMonth() + 1).toString().padStart(2, "0")}-${dt
                    .getDate()
                    .toString()
                    .padStart(2, "0")}-${dt
                    .getFullYear()
                    .toString()
                    .padStart(4, "0")} ${dt
                    .getHours()
                    .toString()
                    .padStart(2, "0")}:${dt
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}:${dt
                    .getSeconds()
                    .toString()
                    .padStart(2, "0")}`,
                };
                window.DataGate.AddStableBoxHistoryLog(data, () => {});
              }
            }
          } else {
            let stableIdNew = this.state.stableBoxId;
            let stableIdOld =
              this.props.horseId == -1 ? null : this.horse.StableId;
            if (stableIdNew != stableIdOld) {
              if (this.props.horseId != -1) {
                var dt = new Date();
                let data = {
                  horseId: this.props.horseId,
                  oldStableId: stableIdOld,
                  newStableId: stableIdNew,
                  oldBoxId: null,
                  newBoxId: null,
                  date: `${(dt.getMonth() + 1).toString().padStart(2, "0")}-${dt
                    .getDate()
                    .toString()
                    .padStart(2, "0")}-${dt
                    .getFullYear()
                    .toString()
                    .padStart(4, "0")} ${dt
                    .getHours()
                    .toString()
                    .padStart(2, "0")}:${dt
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}:${dt
                    .getSeconds()
                    .toString()
                    .padStart(2, "0")}`,
                };
                window.DataGate.AddStableBoxHistoryLog(data, () => {});
              }
            }
          }

          callback(horseId);
        }
      );

      if (
        this.state.isvisible == 0 &&
        window.Training.hiddenHorses.indexOf(this.props.horseId) == -1
      ) {
        window.Training.hiddenHorses.push(this.props.horseId);
      } else if (
        this.state.isvisible == 1 &&
        window.Training.hiddenHorses.indexOf(this.props.horseId) != -1
      ) {
        window.Training.hiddenHorses.splice(
          window.Training.hiddenHorses.indexOf(this.props.horseId),
          1
        );
      }
      let hiddenHorses = JSON.stringify(window.Training.hiddenHorses);
      window.GlobalData.setItem(window.GlobalData.HIDDEN_HORSES, hiddenHorses);
    } else {
      callback(this.props.horseId);
    }
  };

  onSaveClick = () => {
    this.doSave((horseId) => {
      if (this.props.horseId == -1) {
        this.props.onEditBack(horseId);
      } else {
        this.props.onEditBack();
      }
    });
  };

  onButonClick = (callFunction) => {
    this.doSave((horseId) => {
      if (horseId != -1) {
        this.props.onEditBack(horseId);
        setTimeout(() => {
          callFunction(horseId);
        });
      }
    });
  };

  getStableList = () => {
    let boxes = window.DataStorage.boxesAndStables;

    if (window.inUseStables == "Y") {
      let userId = this.horse ? this.horse.userid : this.userOwnerId;
      let resultboxes = boxes.filter(
        (b) => b.IDBox != null && b.InUse != "Y" && b.UserId == userId
      );
      if (this.horse && this.horse.BoxName) {
        let selectedBox = boxes.find((b) => b.IDBox == this.horse.BoxName);
        if (selectedBox) {
          resultboxes.push(selectedBox);
        }
      }
      resultboxes = resultboxes
        .sort((x, y) => x.OrderBy - y.OrderBy)
        .map((b) => ({ key: b.IDBox, value: b.StableName + " " + b.BoxName }));
      return resultboxes;
    } else {
      let userId = this.horse ? this.horse.userid : this.userOwnerId;
      let stables = boxes
        .filter((b) => b.UserId == userId)
        .sort((a, b) => a.StableOrderBy - b.StableOrderBy)
        .map((b) => ({ key: b.IdStable, value: b.StableName }));

      let allStables = [];
      for (let i = 0; i < stables.length; i++) {
        if (allStables.find((s) => s.key == stables[i].key) == null) {
          allStables.push(stables[i]);
        }
      }
      return allStables;
    }
  };

  labelChange = (newValue, actionMeta) => {
    let labelsArr = JSON.parse(JSON.stringify(this.state.labels));
    let selectedLabelsArr = JSON.parse(
      JSON.stringify(this.state.selectedLabels)
    );
    switch (actionMeta.action) {
      case "create-option":
        let newId = Math.uuid();
        if (this.horse.userid == window.myid) {
          window.DataGate.createHorseLabels(
            newId,
            newValue[newValue.length - 1].label,
            window.myid,
            () => {}
          );
          window.DataStorage.horseLabels.push({
            Id: newId,
            Label: newValue[newValue.length - 1].label,
            UserId: window.myid,
          });
        } else {
          window.DataGate.createHorseLabels(
            newId,
            newValue[newValue.length - 1].label,
            this.horse.userid,
            () => {}
          );
        }
        labelsArr.push({
          value: newId,
          label: newValue[newValue.length - 1].label,
        });
        selectedLabelsArr.push({
          value: newId,
          label: newValue[newValue.length - 1].label,
        });
        window.TopMenu.Self.sidebarExtention.refreshSideMenu();
        break;
      case "select-option":
        selectedLabelsArr.push({
          value: newValue[newValue.length - 1].value,
          label: newValue[newValue.length - 1].label,
        });
        break;
      case "remove-value":
        selectedLabelsArr = selectedLabelsArr.filter(
          (i) => i.value != actionMeta.removedValue.value
        );
        break;
      case "clear":
        selectedLabelsArr = [];
        break;
      default:
        break;
    }
    this.setState({
      hasChanges: true,
      labels: labelsArr,
      selectedLabels: selectedLabelsArr,
    });
  };

  render() {
    const {
      name,
      bornIn,
      regNr,
      imageUrl,
      arriveDate,
      departureDate,
      height,
      stableBoxId,
      gender,
      horseType,
      colorId,
      trainer,
      istraining,
      isvisible,
      chipid,
      trainersArr,
      labels,
      selectedLabels,
    } = this.state;

    const stableArr = [
      { key: "", value: window.lang.none },
      ...this.getStableList(),
    ];
    const genderArr = window.DataStorage.getHorseGenderArr().map((g, i) => ({
      key: i,
      value: g,
    }));

    let horseTypeArr = window.DataStorage.horseTypes
      .filter((t) => t.OwnerC == this.userOwnerId)
      .map((t) => ({ key: t.ID, value: t.Name }));
    horseTypeArr = [{ key: "", value: window.lang.none }, ...horseTypeArr];

    let colorArr = window.DataStorage.horseColors
      .filter((t) => t.UserID == this.userOwnerId)
      .map((t) => ({ key: t.Id, value: t.ColorName }));
    colorArr = [
      { key: "020b69ff-6210-41a9-82b0-122091bf88a0", value: window.lang.none },
      ...colorArr,
    ];
    return (
      <div className="pageContent pageScrollable">
        <div className="pageHeader ui-header">
          <img
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={() => this.props.onEditBack()}
          />
          <img
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <div
          className="hvpContent"
          style={this.isMobile ? { marginBottom: "50px" } : {}}
        >
          <div style={{ display: "flex" }}>
            <div className="hvpFloatHalf">
              <div>{window.lang.name}</div>
              <input value={name} name="name" onChange={this.onTextChange} />
              {this.isShowBornIn && (
                <>
                  <div>{window.lang.bornIn}</div>
                  <input
                    value={bornIn}
                    name="bornIn"
                    onChange={this.onTextChange}
                  />
                </>
              )}
            </div>
            <div className="hvpFloatHalf">
              <input
                id="hvpHorseImage"
                data-role="none"
                style={{ display: "none" }}
                type="file"
                onChange={this.onHorseImageSelect}
              />
              <label htmlFor="hvpHorseImage">
                <div
                  className="hvpImage"
                  style={{ backgroundImage: `url('${imageUrl}')` }}
                ></div>
              </label>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {this.isShowRegNr && (
              <div className="hvpFloatHalf">
                <div>Reg. nr</div>
                <input
                  value={regNr}
                  name="regNr"
                  onChange={this.onTextChange}
                />
              </div>
            )}
            {this.isShowChipid && (
              <div className="hvpFloatHalf">
                <div>Chipid</div>
                <input
                  value={chipid}
                  name="chipid"
                  onChange={this.onTextChange}
                />
              </div>
            )}
            {this.isShowArriveDate && (
              <div className="hvpFloatHalf">
                <div>{window.lang.arrivDate}</div>
                {arriveDate == "1900-01-01" ? (
                  <input
                    type="date"
                    name="arriveDate"
                    onChange={this.onTextChange}
                  />
                ) : (
                  <input
                    type="date"
                    value={arriveDate}
                    name="arriveDate"
                    onChange={this.onTextChange}
                  />
                )}
              </div>
            )}
            {this.isShowArriveDate && (
              <div className="hvpFloatHalf">
                <div>{window.lang.departure}</div>
                {departureDate == "1900-01-01" ? (
                  <input
                    type="date"
                    name="departureDate"
                    onChange={this.onTextChange}
                  />
                ) : (
                  <input
                    type="date"
                    value={departureDate}
                    name="departureDate"
                    onChange={this.onTextChange}
                  />
                )}
              </div>
            )}
            {this.isShowHeight && (
              <div className="hvpFloatHalf">
                <div>{window.lang.height}</div>
                <input
                  value={height}
                  name="height"
                  onChange={this.onTextChange}
                />
              </div>
            )}
            <div className="hvpFloatHalf" style={{ padding: "3px" }}>
              <div>Labels</div>
              <CreatableSelect
                className="creatableSelect"
                isMulti
                onChange={this.labelChange}
                options={labels}
                value={selectedLabels}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {this.isShowStable && (
              <div className="hvpFloatQuard">
                <div>{window.lang.stable}</div>
                <select
                  value={stableBoxId}
                  name="stableBoxId"
                  onChange={this.onTextChange}
                  data-role="none"
                >
                  {stableArr.map((stableItem) => (
                    <option key={stableItem.key} value={stableItem.key}>
                      {stableItem.value}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {this.isShowGender && (
              <div className="hvpFloatQuard">
                <div>{window.lang.gender}</div>
                <select
                  value={gender}
                  name="gender"
                  onChange={this.onTextChange}
                  data-role="none"
                >
                  {genderArr.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {this.isShowType && (
              <div className="hvpFloatQuard">
                <div>{window.lang.type}</div>
                <select
                  value={horseType}
                  name="horseType"
                  onChange={this.onTextChange}
                  data-role="none"
                >
                  {horseTypeArr.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {this.isShowColor && (
              <div className="hvpFloatQuard">
                <div>{window.lang.color}</div>
                <select
                  value={colorId}
                  name="colorId"
                  onChange={this.onTextChange}
                  data-role="none"
                >
                  {colorArr.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {this.isShowHorseFollowsRider && (
              <div className="hvpFloatQuard">
                <div>{window.lang.horseFollowsRider}</div>
                <select
                  value={trainer}
                  name="trainer"
                  onChange={this.onTextChange}
                  data-role="none"
                >
                  {trainersArr.map((item) => (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {this.isShowInTraining && (
              <div className="hvpFloatQuard">
                <div>{window.lang.showInTraining}</div>
                <ToggleView
                  onClick={() => this.onToggleClick("istraining")}
                  checked={istraining == "1"}
                />
              </div>
            )}
            {this.isShowIsVisible && (
              <div className="hvpFloatQuard">
                <div>{window.lang.isVisible}</div>
                <ToggleView
                  onClick={() => this.onToggleClick("isvisible")}
                  checked={isvisible == "1"}
                />
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openNotes)}
            >
              <img loading="lazy" src="img/horse_notes.png" />
              <span>{window.lang.notes}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openPedigree)}
            >
              <img loading="lazy" src="img/horse_pedegree.png" />
              <span>{window.lang.pedigree}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openResults)}
            >
              <img loading="lazy" src="img/horse_results.png" />
              <span>{window.lang.result}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openVaccinations)}
            >
              <img loading="lazy" src="img/horse_vaccination.png" />
              <span>{window.lang.vaccination}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() =>
                this.onButonClick(this.props.openHerpesVaccinations)
              }
            >
              <img loading="lazy" src="img/horse_vaccination.png" />
              <span>{window.lang.herpesVaccination}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openEquipment)}
            >
              <img loading="lazy" src="img/horse_equipment.png" />
              <span>{window.lang.equipment}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openFarrier)}
            >
              <img loading="lazy" src="img/horse_farrier.jpg" />
              <span>{window.lang.shoes}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openMultimedia)}
            >
              <img loading="lazy" src="img/horse_multimedia.png" />
              <span>Multimedia</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openVeterinarian)}
            >
              <img loading="lazy" src="img/horse_veterinarian.png" />
              <span>{window.lang.veterinarian}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openReport)}
            >
              <img loading="lazy" src="img/horse_report.png" />
              <span>{window.lang.report}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openTeeth)}
            >
              <img loading="lazy" src="img/horse_teeth.png" />
              <span>{window.lang.teeth}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openOwnerInfo)}
            >
              <img loading="lazy" src="img/horse_owner.png" />
              <span>{window.lang.owner}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openPrice)}
            >
              <img loading="lazy" src="img/horse_price.ico" />
              <span>{window.lang.price}</span>
            </button>
            <button
              className="hvpEditButton"
              onClick={() => this.onButonClick(this.props.openLog)}
            >
              <img loading="lazy" />
              <span>Log page</span>
            </button>
            {this.props.horseId != -1 && this.horse.userid == window.myid && (
              <button
                className="hvpEditButton"
                onClick={() => this.onButonClick(this.props.openShare)}
              >
                <img loading="lazy" src="img/settings_share.jpg" />
                <span>{window.lang.share}</span>
              </button>
            )}
            {this.props.horseId != -1 &&
              this.horse.userid == window.myid &&
              (this.horse.u == "u" ? (
                <button
                  className="hvpEditButton"
                  onClick={() => this.onButonClick(this.props.openDelete)}
                >
                  <img loading="lazy" src="img/horse_delete.png" />
                  <span>{window.lang.deleteHorse}</span>
                </button>
              ) : (
                <>
                  <button
                    className="hvpEditButton"
                    onClick={() => this.onButonClick(this.props.openUnDelete)}
                  >
                    <img loading="lazy" src="img/add.png" />
                    <span>{window.lang.undeleteHorse}</span>
                  </button>
                  <button
                    className="hvpEditButton"
                    onClick={() =>
                      this.onButonClick(this.props.openDeleteCompletly)
                    }
                  >
                    <img loading="lazy" src="img/horse_delete.png" />
                    <span>{window.lang.deleteHorse}</span>
                  </button>
                </>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default HorseEditPage;
