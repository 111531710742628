import React, { useState, use, useEffect } from "react";
import ReactDOM from "react-dom";
import "./WalkerManager.css";
import Modal from "../Modal/Modal";
function WalkerManager() {
  const [walkerAreas, setWalkerAreas] = useState([]);

  // MODALS
  const [showCreateWalkerAreaModal, setShowCreateWalkerAreaModal] =
    useState(false);

  const [showCreateWalkerModal, setShowCreateWalkerModal] = useState(false);

  const [showEditWalkerModal, setShowEditWalkerModal] = useState(false);
  const [showEditTimePeriodModal, setShowEditTimePeriodModal] = useState(false);

  const [showCreateTimeModal, setShowCreateTimeModal] = useState(false);

  const [showDeleteWalkerModal, setShowDeleteWalkerModal] = useState(false);
  const [showDeleteWalkerAreaModal, setShowDeleteWalkerAreaModal] =
    useState(false);

  const [showDeleteTimePeriodModal, setShowDeleteTimePeriodModal] =
    useState(false);

  // #SECTION Data creation
  const [currentWorkingWalkerAreaId, setCurrentWorkingWalkerAreaId] =
    useState("");

  const [currentWorkingWalkerId, setCurrentWorkingWalkerId] = useState("");
  const [currentWorkingTimePeriodId, setCurrentWorkingTimePeriodId] =
    useState("");

  const [newWalkerAreaName, setNewWalkerAreaName] = useState("");
  const [newWalkerName, setNewWalkerName] = useState("");
  const [newWalkerNumberOfHorses, setNewWalkerNumberOfHorses] = useState(1);
  const [newTimePeriod, setNewTimePeriod] = useState("");
  const [enforceAllFields, setEnforceAllFields] = useState(
    window.DataStorage.getUserSetting(2) == 1
  );

  const [isLoading, setIsLoading] = useState(false);
  //   reset the value of the fields inside the model
  useEffect(() => {
    setNewWalkerAreaName("");
    setNewWalkerName("");
    setNewTimePeriod("");
    setNewWalkerNumberOfHorses(1);
  }, [showCreateWalkerAreaModal, showCreateWalkerModal, showCreateTimeModal]);

  const fetchData = () => {
    window.DataGate.getWalkerAreas((code, data) => {
      if (data) setWalkerAreas(data);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const move_walker = (walkers, index, walker_area_index, higher) => {
    if (higher && index == 0) return;

    if (!higher && index == walkers.length - 1) return;

    let secondIndex = higher ? index - 1 : index + 1;
    let firstFieldId = walkers[index].id;
    let secondFieldId = walkers[secondIndex].id;

    window.DataGate.moveField(
      {
        firstFieldId,
        secondFieldId,
      },
      (status, data) => {}
    );
    let aux = [...walkers];

    let x = aux[index];
    aux[index] = walkers[secondIndex];
    aux[secondIndex] = x;

    let aux2 = [...walkerAreas];
    aux2[walker_area_index].walkers = aux;
    setWalkerAreas(aux2);
  };
  //   #ENDSECTION
  const generateFieldAreaFieldTables = (walker_area, walker_area_index) => {
    if (walker_area.walkers.length == 0 && !walker_area.is_editable) return;
    const firstColumnColSpan = walker_area.is_editable ? 1 : 2;
    return (
      <table className="field-area-section--table">
        <thead>
          <tr>
            <th colSpan={firstColumnColSpan}>
              <span>{window.lang.walker}</span>
            </th>
            <th className="field-area-section--table--header-text">
              <span>{window.lang.numberOfHorses}</span>
            </th>
            {walker_area.is_editable && <th></th>}
          </tr>
        </thead>
        <tbody>
          {walker_area.walkers.map((walker, walkerIndex) => (
            <tr key={walker_area.id + "-walker-" + walker.id}>
              <td
                colSpan={firstColumnColSpan}
                className="field-area-section--table--field-name"
              >
                <div className="field-area-section--table--field-name--inner">
                  <div
                    onClick={() =>
                      move_walker(
                        walker_area.walkers,
                        walkerIndex,
                        walker_area_index,
                        true
                      )
                    }
                  >
                    <svg
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                      />
                    </svg>
                  </div>

                  <div
                    onClick={() =>
                      move_walker(
                        walker_area.walkers,
                        walkerIndex,
                        walker_area_index,
                        false
                      )
                    }
                  >
                    <svg
                      width="25px"
                      style={{ transform: "rotate(180deg)" }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                      />
                    </svg>
                  </div>
                  <span>{walker.name}</span>
                </div>
              </td>
              <td className="field-area-section--table--field-horses">
                {walker.maxNumberHorses}
              </td>
              {walker_area.is_editable && (
                <td className="field-area-section--table--field-button">
                  <div
                    onClick={() => {
                      setCurrentWorkingWalkerAreaId(walker_area.id);
                      setCurrentWorkingWalkerId(walker.id);
                      setShowDeleteWalkerModal(true);
                    }}
                  >
                    <img
                      loading="lazy"
                      src="img/trashcan.png"
                      className="tcDelButton"
                    />
                  </div>
                </td>
              )}
              {walker_area.is_editable && (
                <td className="field-area-section--table--field-button">
                  <div
                    onClick={() => {
                      setCurrentWorkingWalkerAreaId(walker_area.id);
                      setCurrentWorkingWalkerId(walker.id);
                      setNewWalkerName(walker.name);
                      setNewWalkerNumberOfHorses(walker.maxNumberHorses);
                      setShowEditWalkerModal(true);
                    }}
                  >
                    <img
                      loading="lazy"
                      src="img/edit_icon.png"
                      className="tcDelButton"
                    />
                  </div>
                </td>
              )}
            </tr>
          ))}

          {walker_area.is_editable && (
            <tr>
              <td className="field-area-section--table--field-name">
                <button
                  className="ui-btn field-btn"
                  onClick={() => {
                    setCurrentWorkingWalkerAreaId(walker_area.id);
                    setShowCreateWalkerModal(true);
                  }}
                >
                  Add Walker
                </button>
              </td>
              <td className="field-area-section--table--field-button"></td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const generateFieldAreaTimePeriodTables = (walker_area) => {
    if (walker_area.timePeriods.length == 0 && !walker_area.is_editable) return;
    const firstColumnColSpan = walker_area.is_editable ? 1 : 2;
    return (
      <table className="field-area-section--table">
        <thead>
          <tr>
            <th colSpan={firstColumnColSpan}>
              <span>{window.lang.timePeriod}</span>
            </th>
            {walker_area.is_editable && <th></th>}
          </tr>
        </thead>
        <tbody>
          {walker_area.timePeriods.map((time_period) => (
            <tr key={walker_area.id + "-time-" + time_period.id}>
              <td
                colSpan={firstColumnColSpan}
                className="field-area-section--table--field-name"
              >
                <span>{time_period.period}</span>
              </td>
              {walker_area.is_editable && (
                <td className="field-area-section--table--field-button">
                  <div
                    onClick={() => {
                      setCurrentWorkingWalkerAreaId(walker_area.id);
                      setCurrentWorkingTimePeriodId(time_period.id);
                      setShowDeleteTimePeriodModal(true);
                    }}
                  >
                    <img
                      loading="lazy"
                      src="img/trashcan.png"
                      className="tcDelButton"
                    />
                  </div>
                </td>
              )}
              {walker_area.is_editable && (
                <td className="field-area-section--table--field-button">
                  <div
                    onClick={() => {
                      setCurrentWorkingWalkerAreaId(walker_area.id);
                      setCurrentWorkingTimePeriodId(time_period.id);
                      setNewTimePeriod(time_period.period);
                      setShowEditTimePeriodModal(true);
                    }}
                  >
                    <img
                      loading="lazy"
                      src="img/edit_icon.png"
                      className="tcDelButton"
                    />
                  </div>
                </td>
              )}
            </tr>
          ))}

          {walker_area.is_editable && (
            <tr>
              <td className="field-area-section--table--field-name">
                <button
                  className="ui-btn field-btn"
                  onClick={() => {
                    setCurrentWorkingWalkerAreaId(walker_area.id);
                    setShowCreateTimeModal(true);
                  }}
                >
                  Add Time Period
                </button>
              </td>
              <td className="field-area-section--table--field-button"></td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderCreateWalkerDefaulModalBody = (ev_dispatcher, ev_value) => {
    return (
      <>
        <input
          type="text"
          value={ev_value}
          onChange={(e) => ev_dispatcher(e.currentTarget.value)}
          placeholder={window.lang.name}
          className="field-manager--modal-text-input"
        />
      </>
    );
  };

  const renderCreateWalkerModalBody = (
    ev_dispatcher,
    ev_value,
    second_ev_dispatcher,
    second_ev_value
  ) => {
    return (
      <div className="field-manager--modal--holder">
        <span style={{ marginTop: "-10px" }}>{window.lang.name}</span>
        <input
          type="text"
          value={ev_value}
          onChange={(e) => ev_dispatcher(e.currentTarget.value)}
          placeholder={window.lang.name}
          className="field-manager--modal-text-input"
        />

        <span style={{ marginTop: "15px" }}>{window.lang.numberOfHorses}</span>
        <input
          type="number"
          value={second_ev_value}
          onChange={(e) => second_ev_dispatcher(e.currentTarget.value)}
          placeholder={window.lang.numberOfHorses}
          className="field-manager--modal-text-input"
        />
      </div>
    );
  };

  const renderDeleteModalBody = () => {
    return <span>{window.lang.wantDelete}</span>;
  };

  const createWalkerArea = () => {
    if (newWalkerAreaName == "") {
      alert("Please select a valid name for the walker area");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.addWalkerArea(
      {
        name: newWalkerAreaName,
      },
      (code, data) => {
        setWalkerAreas(data);
        window.Spinner.hide();
      }
    );

    setShowCreateWalkerAreaModal(false);
  };
  const createWalker = () => {
    if (newWalkerName == "") {
      alert("Please select a valid name for the walker");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.addWalker(
      {
        name: newWalkerName,
        numberOfHorses: newWalkerNumberOfHorses,
        walkerAreaId: currentWorkingWalkerAreaId,
      },
      (code, data) => {
        setWalkerAreas(data);
        window.Spinner.hide();
      }
    );

    setShowCreateWalkerModal(false);
  };
  const editWalker = () => {
    if (newWalkerName == "") {
      alert("Please select a valid name for the walker");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.editWalker(
      {
        name: newWalkerName,
        numberOfHorses: newWalkerNumberOfHorses,
        walkerId: currentWorkingWalkerId,
      },
      (code, data) => {
        setWalkerAreas(data);
        window.Spinner.hide();
      }
    );

    setShowEditWalkerModal(false);
  };

  const createTimePeriod = () => {
    if (newTimePeriod == "") {
      alert("Please select a valid time period text");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.addWalkerTimePeriod(
      {
        period: newTimePeriod,
        walkerAreaId: currentWorkingWalkerAreaId,
      },
      (code, data) => {
        setWalkerAreas(data);
        window.Spinner.hide();
      }
    );

    setShowCreateTimeModal(false);
  };

  const editWalkerTimePeriod = () => {
    if (newTimePeriod == "") {
      alert("Please select a valid time period text");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.editWalkerTimePeriod(
      {
        period: newTimePeriod,
        timePeriodId: currentWorkingTimePeriodId,
      },
      (code, data) => {
        setWalkerAreas(data);
        window.Spinner.hide();
      }
    );

    setShowEditTimePeriodModal(false);
  };

  const deleteWalkerAreaItem = () => {
    window.Spinner.show();

    // delete field

    if (showDeleteWalkerModal) {
      window.DataGate.deleteWalkerFromAreaWalker(
        {
          id: currentWorkingWalkerId,
        },
        (code, data) => {
          setWalkerAreas(data);
          window.Spinner.hide();
        }
      );
      setShowDeleteWalkerModal(false);
      return;
    }

    // delete time period
    if (showDeleteTimePeriodModal) {
      window.DataGate.deleteTimePeriodFromAreaWalker(
        {
          id: currentWorkingTimePeriodId,
        },
        (code, data) => {
          setWalkerAreas(data);
          window.Spinner.hide();
        }
      );
      setShowDeleteTimePeriodModal(false);
      return;
    }

    // delete field area
    window.DataGate.deleteWalkerArea(
      {
        id: currentWorkingWalkerAreaId,
      },
      (code, data) => {
        setWalkerAreas(data);
        window.Spinner.hide();
      }
    );
    setShowDeleteWalkerAreaModal(false);
  };

  const renderDeleteTitle = () => {
    if (showDeleteWalkerModal) return "Delete walker";
    if (showDeleteTimePeriodModal) return "Delete time period";

    return "Delete walker area";
  };

  const move_task = (index, higher) => {
    window.Spinner.show();

    if (isLoading) return;
    setIsLoading(true);
    let items = [...walkerAreas];
    if (index == 0 && higher) return;
    if (index == items.length - 1 && !higher) return;

    let newIndex = higher ? index - 1 : index + 1;
    let aux = items[index];
    items[index] = items[newIndex];
    items[newIndex] = aux;
    const ids = items.map((task) => task.id);
    window.DataGate.reorderWalkerAreas(ids, () => {
      setWalkerAreas(items);
      setIsLoading(false);
      window.Spinner.hide();
    });
  };

  const changeEnforceAllFieldsSetting = (e) => {
    setEnforceAllFields(e.currentTarget.checked);
    window.DataStorage.setUserSetting(
      window.myid,
      2,
      e.currentTarget.checked ? 1 : 0
    );
  };
  return (
    <>
      <div className="field-manager--scroll-area">
        <div className="field-manager--holder">
          {walkerAreas &&
            walkerAreas.map((walker_area, walker_area_index) => (
              <section key={walker_area.id} className="field-area-section">
                <div className="field-area-section--title-holder">
                  <div onClick={() => move_task(walker_area_index, true)}>
                    <svg
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                      />
                    </svg>
                  </div>

                  <div onClick={() => move_task(walker_area_index, false)}>
                    <svg
                      width="25px"
                      style={{ transform: "rotate(180deg)" }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                      />
                    </svg>
                  </div>
                  <h3>
                    {window.lang.walkerArea}:{" "}
                    <span className="field-area-section--title">
                      {walker_area.name}
                    </span>
                  </h3>
                  <img
                    src="img/trashcan.png"
                    onClick={() => {
                      setCurrentWorkingWalkerAreaId(walker_area.id);
                      setShowDeleteWalkerAreaModal(true);
                    }}
                    className="tcDelButton--field-area"
                  />

                  {walker_area_index == 0 && (
                    <div className="field-area-section--enforce-fields">
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={enforceAllFields}
                        onChange={changeEnforceAllFieldsSetting}
                      />
                      <span>
                        When selecting walker as task then Walker area, Walker
                        name and Time period should always be selected
                      </span>
                    </div>
                  )}
                </div>

                <div className="field-area-section--data-holder">
                  {generateFieldAreaFieldTables(walker_area, walker_area_index)}
                  {generateFieldAreaTimePeriodTables(walker_area)}
                </div>
              </section>
            ))}
        </div>
        <button
          className="ui-btn field-area-btn"
          onClick={() => setShowCreateWalkerAreaModal(true)}
        >
          Add Walker Area
        </button>
      </div>

      {showCreateWalkerAreaModal && (
        <Modal
          title="Create a walker area"
          body={renderCreateWalkerDefaulModalBody(
            setNewWalkerAreaName,
            newWalkerAreaName
          )}
          button1_text={window.lang.add}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowCreateWalkerAreaModal(false)}
          button1_callback={createWalkerArea}
        />
      )}

      {showEditWalkerModal && (
        <Modal
          title="Edit Walker"
          body={renderCreateWalkerModalBody(
            setNewWalkerName,
            newWalkerName,
            setNewWalkerNumberOfHorses,
            newWalkerNumberOfHorses
          )}
          button1_text={window.lang.edit}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowEditWalkerModal(false)}
          button1_callback={editWalker}
        />
      )}
      {showCreateWalkerModal && (
        <Modal
          title="Create a walker"
          body={renderCreateWalkerModalBody(
            setNewWalkerName,
            newWalkerName,
            setNewWalkerNumberOfHorses,
            newWalkerNumberOfHorses
          )}
          button1_text={window.lang.add}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowCreateWalkerModal(false)}
          button1_callback={createWalker}
        />
      )}

      {showCreateTimeModal && (
        <Modal
          title="Create a time period"
          body={renderCreateWalkerDefaulModalBody(
            setNewTimePeriod,
            newTimePeriod
          )}
          button1_text={window.lang.add}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowCreateTimeModal(false)}
          button1_callback={createTimePeriod}
        />
      )}

      {showEditTimePeriodModal && (
        <Modal
          title="Edit time period"
          body={renderCreateWalkerDefaulModalBody(
            setNewTimePeriod,
            newTimePeriod
          )}
          button1_text={window.lang.edit}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowEditTimePeriodModal(false)}
          button1_callback={editWalkerTimePeriod}
        />
      )}

      {(showDeleteWalkerModal ||
        showDeleteTimePeriodModal ||
        showDeleteWalkerAreaModal) && (
        <Modal
          title={renderDeleteTitle()}
          body={renderDeleteModalBody()}
          button1_text={window.lang.deleteL}
          button2_text={window.lang.cancel2}
          button2_callback={() => {
            setShowDeleteWalkerModal(false);
            setShowDeleteTimePeriodModal(false);
            setShowDeleteWalkerAreaModal(false);
          }}
          button1_callback={deleteWalkerAreaItem}
        />
      )}
    </>
  );
}

export default class WalkerManagerHolder {
  createWalkerManager() {
    ReactDOM.render(
      <WalkerManager />,
      document.getElementById("walkerManagementPageReact")
    );
  }
}
