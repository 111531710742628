import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./AutoCreateTaskPage.css";

class AutoCreateTaskCompoment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userTimeZone: 0,
      timeZoneArray: [],
      weekDays: [],
      horseTypes: [],
      riderArray: [],
      trainingTypes: [],
      addData: {
        CreateWeekDay: 1,
        CreateTime: "09:00",
        CreateToDay: 1,
        HorseTypes: "",
        Rider: "",
        TrainingTypes: "",
      },
      createTaskArray: [],
    };
  }

  onBackClick = () => {
    window.onBack();
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    let weekDays = window.lang.daysNames.map((d, i) => ({
      value: i,
      label: d,
    }));
    let s = weekDays.shift();
    weekDays.push(s);

    let hoseTypes = [
      {
        ID: "",
        Name: window.lang.selectAll,
        OwnerC: window.myid,
      },
      ...window.DataStorage.horseTypes.filter((t) => t.OwnerC == window.myid),
    ];

    let trainingTypes = window.DataStorage.trainTypes.filter(
      (t) => t.owner == window.myid
    );

    this.setState({
      weekDays: weekDays,
      horseTypes: hoseTypes,
      trainingTypes: trainingTypes,
      addData: {
        ...this.state.addData,
        TrainingTypes: trainingTypes[0].id,
      },
    });

    window.db.getAllUsersExceptId(window.myid, (dataU) => {
      const riders = [
        { value: "", label: "Horse follows rider" },
        { value: window.myid, label: window.lang.me },
        ...dataU.map((t) => ({ value: t.id, label: t.name })),
      ];

      this.setState({
        riderArray: riders,
      });
    });

    window.$.getJSON("js/timeZone.json", (json) => {
      var timezone = -(new Date().getTimezoneOffset() / 60);
      if (timezone != null) {
        this.setState({
          userTimeZone: timezone,
          timeZoneArray: json,
        });
      }
    });

    this.loadTaskData();
  };

  loadTaskData = () => {
    window.Spinner.show();
    window.db.getAutoCreateJobs(window.myid, (data) => {
      window.Spinner.hide();
      this.setState({
        createTaskArray: data,
      });
    });
  };

  onTimeZoneChange = (e) => {
    this.setState({
      userTimeZone: e.target.value,
    });
    window.db.setUserTimeZone(e.target.value, () => {});
  };

  onAddDataChange = (name, value) => {
    this.setState({
      addData: {
        ...this.state.addData,
        [name]: value,
      },
    });
  };

  onAddClick = () => {
    let data = {
      ...this.state.addData,
      UserId: window.myid,
    };
    window.Spinner.show();
    window.db.insertAutoCreateJob(data, () => {
      window.Spinner.hide();
      this.loadTaskData();
    });
  };

  onItemDataChange = (name, value, itemIndex) => {
    let createTaskArray = [...this.state.createTaskArray];
    createTaskArray[itemIndex] = {
      ...createTaskArray[itemIndex],
      [name]: value,
    };
    this.setState({
      createTaskArray: createTaskArray,
    });
    window.db.updateAutoCreateJob(createTaskArray[itemIndex], () => {});
  };

  onDeleteClick = (itemIndex) => {
    window.db.deleteAutoCreateJob(
      this.state.createTaskArray[itemIndex].Id,
      () => {}
    );
    this.setState({
      createTaskArray: this.state.createTaskArray.filter(
        (o, i) => i != itemIndex
      ),
    });
  };

  render() {
    const {
      userTimeZone,
      timeZoneArray,
      weekDays,
      horseTypes,
      riderArray,
      trainingTypes,
      addData,
      createTaskArray,
    } = this.state;

    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={this.onBackClick}
          />
          <span className="ctTitle"></span>
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onBackClick}
          />
        </div>
        <div className="ctContent">
          <select value={userTimeZone} onChange={this.onTimeZoneChange}>
            {timeZoneArray.map((item) => (
              <option key={item.value} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
          <table className="ctTable">
            <tbody>
              <tr>
                <th>Time to make jobs</th>
                <th>Time to insert jobs</th>
                <th>Time of day</th>
                <th>Horse types</th>
                <th>Riders</th>
                <th>Training types</th>
                <th>Add/Delete</th>
              </tr>
              {createTaskArray.map((item, itemIndex) => (
                <tr key={item.Id}>
                  <td>
                    <select
                      value={item.CreateWeekDay}
                      onChange={(e) =>
                        this.onItemDataChange(
                          "CreateWeekDay",
                          e.target.value,
                          itemIndex
                        )
                      }
                    >
                      {weekDays.map((day) => (
                        <option key={day.value} value={day.value}>
                          {day.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={item.CreateToDay}
                      onChange={(e) =>
                        this.onItemDataChange(
                          "CreateToDay",
                          e.target.value,
                          itemIndex
                        )
                      }
                    >
                      {weekDays.map((day) => (
                        <option key={day.value} value={day.value}>
                          {day.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      value={item.CreateTime}
                      type="time"
                      style={{ padding: "10px" }}
                      onChange={(e) =>
                        this.onItemDataChange(
                          "CreateTime",
                          e.target.value,
                          itemIndex
                        )
                      }
                    />
                  </td>
                  <td>
                    <select
                      value={item.HorseTypes}
                      onChange={(e) =>
                        this.onItemDataChange(
                          "HorseTypes",
                          e.target.value,
                          itemIndex
                        )
                      }
                    >
                      {horseTypes.map((item) => (
                        <option key={item.ID} value={item.ID}>
                          {item.Name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={item.Rider}
                      onChange={(e) =>
                        this.onItemDataChange(
                          "Rider",
                          e.target.value,
                          itemIndex
                        )
                      }
                    >
                      {riderArray.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={item.TrainingTypes}
                      onChange={(e) =>
                        this.onItemDataChange(
                          "TrainingTypes",
                          e.target.value,
                          itemIndex
                        )
                      }
                    >
                      {trainingTypes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      className="ui-btn ui-shadow ui-corner-all"
                      onClick={() => this.onDeleteClick(itemIndex)}
                    >
                      {window.lang.deleteL}
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <select
                    value={addData.CreateWeekDay}
                    onChange={(e) =>
                      this.onAddDataChange("CreateWeekDay", e.target.value)
                    }
                  >
                    {weekDays.map((day) => (
                      <option key={day.value} value={day.value}>
                        {day.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    value={addData.CreateToDay}
                    onChange={(e) =>
                      this.onAddDataChange("CreateToDay", e.target.value)
                    }
                  >
                    {weekDays.map((day) => (
                      <option key={day.value} value={day.value}>
                        {day.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    value={addData.CreateTime}
                    type="time"
                    style={{ padding: "10px" }}
                    onChange={(e) =>
                      this.onAddDataChange("CreateTime", e.target.value)
                    }
                  />
                </td>
                <td>
                  <select
                    value={addData.HorseTypes}
                    onChange={(e) =>
                      this.onAddDataChange("HorseTypes", e.target.value)
                    }
                  >
                    {horseTypes.map((item) => (
                      <option key={item.ID} value={item.ID}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    value={addData.Rider}
                    onChange={(e) =>
                      this.onAddDataChange("Rider", e.target.value)
                    }
                  >
                    {riderArray.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    value={addData.TrainingTypes}
                    onChange={(e) =>
                      this.onAddDataChange("TrainingTypes", e.target.value)
                    }
                  >
                    {trainingTypes.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    onClick={this.onAddClick}
                  >
                    {window.lang.add}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default class AutoCreateTaskPage {
  show() {
    window.showPage("#autoCreateTaskPage");
    ReactDOM.render(
      <AutoCreateTaskCompoment />,
      document.getElementById("autoCreateTaskReact")
    );
  }
}
