import React, { Component } from "react";
import HorseGallery from "../.././Multimedia/HorseGallery";

class PedigreePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      father: "",
      grfather_father: "",
      grfather_grfather_f: "",
      grfather_grfather_m: "",
      grfather_grmather_f: "",
      grfather_grmather_m: "",
      grfather_mather: "",
      grmather_father: "",
      grmather_grfather_f: "",
      grmather_grfather_m: "",
      grmather_grmather_f: "",
      grmather_grmather_m: "",
      grmather_mather: "",
      mather: "",
      hasPadigree: false,
    };

    window.TopMenu.Self.setVisible(false);
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.horseId != prevProps.horseId) {
      this.loadData();
    }
    window.TopMenu.Self.setVisible(false);
    window.$("#addHorsePageContent").trigger("create");
  }

  componentDidMount() {
    window.$("#addHorsePageContent").trigger("create");
  }

  loadData() {
    window.Spinner.show();
    window.db.getPedigreeByHorse(this.props.horseId, (pedigreeArr) => {
      if (pedigreeArr.length != 0) {
        const data = JSON.parse(pedigreeArr[0]["PedigreeJsonText"]);
        this.setState({
          ...data,
          hasPadigree: true,
        });
      } else {
        this.setState({
          hasPadigree: false,
        });
      }
      window.Spinner.hide();
    });
  }

  onValueChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSaveClick = () => {
    let data = { ...this.state };
    delete data.hasPadigree;
    console.log(data);
    const strData = JSON.stringify(data).replace("'", "''");
    window.Spinner.show();
    if (!this.state.hasPadigree) {
      window.db.insertPedigreeByHorse(this.props.horseId, strData, () => {
        window.Spinner.hide();
        window.Managers.extention.showHorsePage(this.props.horseId);
      });
    } else {
      window.db.setPedigreeByHorse(this.props.horseId, strData, () => {
        window.Spinner.hide();
        window.Managers.extention.showHorsePage(this.props.horseId);
      });
    }
  };

  render() {
    const { horseId } = this.props;
    const horse = window.DataStorage.getAllHorses().find(
      (h) => h.id == horseId
    );

    return (
      <div className="pageScrollable">
        <div className="pageHeader ui-header" style={{ maxWidth: "100%" }}>
          <img
            loading="lazy"
            className="headerIconLeft"
            src="img/icon_cancelW.png"
            onClick={() => this.onSaveClick()}
          />
          <img
            loading="lazy"
            className="headerIconRignt"
            src="img/icon_okW.png"
            onClick={this.onSaveClick}
          />
        </div>
        <div style={{ marginTop: "45px", padding: "10px" }}>
          <div style={{ fontSize: "2.5em", textAlign: "center" }}>
            {horse.name}
          </div>
          <table
            className="borderedTable"
            style={{ textAlign: "center", tableLayout: "fixed", width: "100%" }}
          >
            <tbody>
              <tr>
                <th>{window.lang.parents}</th>
                <th>{window.lang.grandparents}</th>
                <th>{window.lang.greatGrandparents}</th>
              </tr>
              <tr>
                <td rowSpan="4" style={{ padding: "10px" }}>
                  <div style={{ textAlign: "left" }}>
                    {window.lang.stallion}
                  </div>
                  <input
                    type="text"
                    value={this.state.mather}
                    name="mather"
                    onChange={this.onValueChange}
                  />
                </td>
                <td rowSpan="2" style={{ padding: "10px" }}>
                  <div style={{ textAlign: "left" }}>
                    {window.lang.stallion}
                  </div>
                  <input
                    value={this.state.grmather_mather}
                    name="grmather_mather"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
                <td style={{ padding: "10px 10px 0 10px" }}>
                  <div style={{ textAlign: "left" }}>
                    {window.lang.stallion}
                  </div>
                  <input
                    value={this.state.grmather_grmather_m}
                    name="grmather_grmather_m"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px 10px 0 10px" }}>
                  <div style={{ textAlign: "left" }}>{window.lang.mare}</div>
                  <input
                    value={this.state.grfather_grmather_m}
                    name="grfather_grmather_m"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="2" style={{ padding: "10px" }}>
                  <div style={{ textAlign: "left" }}>{window.lang.mare}</div>
                  <input
                    value={this.state.grfather_mather}
                    name="grfather_mather"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
                <td style={{ padding: "10px 10px 0 10px" }}>
                  <div style={{ textAlign: "left" }}>
                    {window.lang.stallion}
                  </div>
                  <input
                    value={this.state.grmather_grfather_m}
                    name="grmather_grfather_m"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px 10px 0 10px" }}>
                  <div style={{ textAlign: "left" }}>Mare</div>
                  <input
                    value={this.state.grfather_grfather_m}
                    name="grfather_grfather_m"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="borderedTable"
            style={{ textAlign: "center", tableLayout: "fixed", width: "100%" }}
          >
            <tbody>
              <tr>
                <td rowSpan="4" style={{ padding: "10px" }}>
                  <div style={{ textAlign: "left" }}>{window.lang.mare}</div>
                  <input
                    value={this.state.father}
                    name="father"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
                <td rowSpan="2" style={{ padding: "10px" }}>
                  <div style={{ textAlign: "left" }}>
                    {window.lang.stallion}
                  </div>
                  <input
                    value={this.state.grmather_father}
                    name="grmather_father"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
                <td style={{ padding: "10px 10px 0 10px" }}>
                  <div style={{ textAlign: "left" }}>
                    {window.lang.stallion}
                  </div>
                  <input
                    value={this.state.grmather_grmather_f}
                    name="grmather_grmather_f"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px 10px 0 10px" }}>
                  <div style={{ textAlign: "left" }}>{window.lang.mare}</div>
                  <input
                    value={this.state.grfather_grmather_f}
                    name="grfather_grmather_f"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="2" style={{ padding: "10px" }}>
                  <div style={{ textAlign: "left" }}>{window.lang.mare}</div>
                  <input
                    value={this.state.grfather_father}
                    name="grfather_father"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
                <td style={{ padding: "10px 10px 0 10px" }}>
                  <div style={{ textAlign: "left" }}>
                    {window.lang.stallion}
                  </div>
                  <input
                    value={this.state.grmather_grfather_f}
                    name="grmather_grfather_f"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px 10px 0 10px" }}>
                  <div style={{ textAlign: "left" }}>{window.lang.mare}</div>
                  <input
                    value={this.state.grfather_grfather_f}
                    name="grfather_grfather_f"
                    onChange={this.onValueChange}
                    type="text"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <HorseGallery
          showUploadButton={true}
          type={"Pedigree"}
          horse={horse}
          title={"Pedigree"}
        />
      </div>
    );
  }
}
export default PedigreePage;
